import { RefreshOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useGetFeatureFlag } from 'contexts/FeatureFlagsContext';

const RefreshBrowser = () => {
  const isEnabled = useGetFeatureFlag('ff_client_refresh');

  if (!isEnabled || window.localStorage.getItem('Jan-2024-Force-Refresh')) {
    return null;
  }

  return (
    <Dialog open>
      <DialogTitle marginBottom={2}>Update available</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Refresh to use the latest version.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<RefreshOutlined />}
          onClick={() => {
            window.localStorage.setItem('Jan-2024-Force-Refresh', 'true');
            window.location.reload();
          }}
          variant='contained'
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefreshBrowser;
