import {
  ArrowForwardIos,
  Check,
  PlaylistAddOutlined,
  Search,
} from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Popper,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useCreateStartupListServiceMutation } from 'apollo/generated/sdkInnovationManager';
import {
  useFuzzySearchListTitleMutation,
  useGetCurrentStartupListByIdLazyQuery,
  useGetProjectCategoriesForAddToListLazyQuery,
  useGetProjectCategoriesForAddToListQuery,
  useGetProjectCategoryIdsByListIdLazyQuery,
  useGetStartupListsForAddStartupLazyQuery,
} from 'apollo/generated/sdkShared';
import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import { BaseSnackbarActionNavigateToList } from 'components/base/BaseSnackbarActions';
import { useCreateNewCategory } from 'components/dashboard/startupList/useCreateNewCategory';
import { useInView } from 'framer-motion';
import useAddStartupToList, {
  AddStartupToListArgs,
} from 'hooks/useAddStartupToList';
import useAuth from 'hooks/useAuth';
import useMatchRoutePattern from 'hooks/useMatchRoutePattern';
import { debounce, uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { ANALYTICS_EVENT, captureAnalyticsEvent } from 'plugins/Analytics';
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

export type SelectedStartupsForAddToList = Array<
  AddStartupToListArgs['selectedStartup'] & { id: number }
>;

type OnCreateType = (
  newListId: number,
  newListCategoryId: number,
  newListTitle: string,
  isLandscape?: boolean,
) => void;

const CreateNewCategoryButton = ({
  startupListId,
  selectedStartups,
  categories,
  onCreate,
}: {
  startupListId: number;
  selectedStartups: SelectedStartupsForAddToList;
  categories: {
    id: number;
    title: string;
  }[];
  onCreate: OnCreateType;
}) => {
  const { handleCreateNewCategory } = useCreateNewCategory({
    startupListId,
  });

  const handleCreate = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const title = `New Category from ${selectedStartups.length === 1 ? selectedStartups[0].name : `${selectedStartups.length} companies`} `;

      const newListCategoryId = await handleCreateNewCategory({
        title,
        rank: categories.length,
      });

      if (!newListCategoryId) return;

      onCreate(startupListId, newListCategoryId, title, true);
    },
    [
      categories.length,
      handleCreateNewCategory,
      onCreate,
      selectedStartups,
      startupListId,
    ],
  );

  return (
    <BaseAddButtonForAutocomplete
      onClick={handleCreate}
      text='New Category'
      sxProps={{ borderRadius: '0 !important' }}
    />
  );
};

const CreateNewListButton = ({
  selectedStartups,
  onCreate,
}: {
  selectedStartups: SelectedStartupsForAddToList;
  onCreate: OnCreateType;
}) => {
  const { user } = useAuth();
  const [getCategories] = useGetProjectCategoriesForAddToListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [createStartupList, { loading }] =
    useCreateStartupListServiceMutation();
  const handleCreate = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      const title =
        selectedStartups.length === 1
          ? `New list from ${selectedStartups?.[0]?.name}`
          : `New list by ${user?.displayName}`;
      const newList = await createStartupList({
        variables: { object: { title: title, source: 'self_created' } },
      });

      const newListId = newList.data?.create_startup_list?.startup_list?.id;

      if (!newListId) return;

      const { data } = await getCategories({
        variables: { startupListId: newListId },
      });

      const newListCategoryId = data?.project_categories?.[0]?.id;

      if (!newListCategoryId) return;

      onCreate(newListId, newListCategoryId, title);
    },
    [
      createStartupList,
      getCategories,
      onCreate,
      selectedStartups,
      user?.displayName,
    ],
  );

  return (
    <BaseAddButtonForAutocomplete
      onClick={handleCreate}
      text='New List'
      disabled={loading}
      sxProps={{ borderRadius: '0 !important' }}
    />
  );
};

const RenderOption = ({
  htmlProps,
  option,
  selectedStartups,
  onCreate,
}: {
  htmlProps: HTMLAttributes<HTMLLIElement>;
  option: Option;
  selectedStartups: SelectedStartupsForAddToList;
  currentProjectsStartupListId?: number;
  onCreate: OnCreateType;
}) => {
  // @ts-expect-error: data-option-index is not a valid prop
  // eslint-disable-next-line react/prop-types
  const index = htmlProps?.['data-option-index'];
  const navigate = useNavigate();
  const [optimisticSelected, setOptimisticSelected] = useState(false);
  const { palette, spacing, typography, shadows, zIndex } = useTheme();
  const [hovered, setHovered] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    setHovered(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setHovered(false);
    }, 200);
    setTimeoutId(id);
  };

  const { data, loading } = useGetProjectCategoriesForAddToListQuery({
    variables: { startupListId: option.id },
    fetchPolicy: 'cache-and-network',
    skip: !isInView,
  });
  const categoriesInfo = useMemo(() => {
    const startupIdsInList =
      data?.project_categories.reduce<number[]>((acc, category) => {
        return [
          ...acc,
          ...category.categorized_suppliers.map(
            supplier => supplier.startup.id,
          ),
        ];
      }, []) || [];

    return {
      isStartupAlreadyInList: selectedStartups.every(startup =>
        startupIdsInList.includes(startup.id),
      ),
      isLandscape: (data?.project_categories || []).length > 1,
      categories: data?.project_categories || [],
    };
  }, [data, selectedStartups]);

  const { isStartupAlreadyInList, isLandscape } = categoriesInfo || {};

  return (
    <>
      {index === 0 && (
        <CreateNewListButton
          onCreate={onCreate}
          selectedStartups={selectedStartups}
        />
      )}
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        component='li'
        ref={ref}
        sx={{
          height: spacing(5),
          color:
            isStartupAlreadyInList || loading ? palette.grey[500] : 'inherit',
          cursor:
            isStartupAlreadyInList || loading
              ? 'not-allowed !important'
              : 'pointer',
          '&:hover': {
            '& > .open-list-button': { display: 'block' },
          },
        }}
        {...htmlProps}
        onClick={e => {
          e.stopPropagation();

          if (isStartupAlreadyInList) {
            e.stopPropagation();
            return;
          }

          htmlProps?.onClick && htmlProps.onClick(e);
          !isLandscape && !optimisticSelected && setOptimisticSelected(true);
        }}
      >
        <Typography fontSize={typography.fontSize} noWrap>
          {option.title}
        </Typography>
        {loading && <CircularProgress size={16} sx={{ marginLeft: 1 }} />}
        {(isStartupAlreadyInList || optimisticSelected) && (
          <Tooltip title='Already in list'>
            <Check sx={{ marginLeft: 0.5 }} fontSize='small' color='primary' />
          </Tooltip>
        )}
        <Stack
          direction='row'
          sx={{ marginLeft: 'auto', display: 'none' }}
          className='open-list-button'
        >
          <Button
            sx={{ marginLeft: 'auto' }}
            size='small'
            onClick={e => {
              e.stopPropagation();
              navigate(PATH_ROOT.lists.details(option.id), {
                state: {
                  backToPage: `${window.location.pathname}${window.location.hash}`,
                },
              });
            }}
          >
            Open
          </Button>
        </Stack>

        {isLandscape && (
          <ArrowForwardIos
            sx={{ marginLeft: 'auto' }}
            color='disabled'
            fontSize='small'
          />
        )}
        <Popper
          open={hovered && !!isLandscape}
          anchorEl={ref.current}
          placement={'right-start'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{ zIndex: zIndex.tooltip }}
        >
          <Box
            sx={{
              borderRadius: spacing(1),
              width: '300px',
              background: palette.common.white,
              boxShadow: shadows[10],
            }}
          >
            <CreateNewCategoryButton
              categories={categoriesInfo.categories}
              onCreate={onCreate}
              startupListId={option.id}
              selectedStartups={selectedStartups}
            />
            {categoriesInfo.categories.map(category => (
              <MenuItem
                key={category.id}
                sx={{
                  height: spacing(5),
                  '&:hover': {
                    '& > .open-category-button': { display: 'block' },
                  },
                }}
                onClick={e => {
                  e.stopPropagation();
                  onCreate(option.id, category.id, category.title, true);
                }}
              >
                <Typography fontSize={typography.fontSize} noWrap>
                  {category.title}
                </Typography>
                <Stack
                  direction='row'
                  sx={{ marginLeft: 'auto', display: 'none' }}
                  className='open-category-button'
                >
                  <Button
                    sx={{ marginLeft: 'auto' }}
                    size='small'
                    onClick={e => {
                      e.stopPropagation();
                      navigate(
                        PATH_ROOT.categoryDetails(
                          option.id,
                          category.id,
                          false,
                        ),
                        {
                          state: {
                            backToPage: `${window.location.pathname}${window.location.hash}`,
                          },
                        },
                      );
                    }}
                  >
                    Open
                  </Button>
                </Stack>
              </MenuItem>
            ))}
          </Box>
        </Popper>
      </Box>
    </>
  );
};

type Option = {
  id: number;
  title: string;
};

const width = 300;

const textFieldSx: SxProps<Theme> = ({ palette }) => ({
  height: '100%',
  background: palette.grey[200],
  fontSize: '14px',
  minWidth: width,
  color: 'grey.800',
  marginTop: 0,
  border: '5px solid #fff !important',
  '& input': { marginTop: 0 },
});

export const BaseAddStartupToListMenu = ({
  selectedStartups,
  onCreate,
  shouldAddMultiple,
  analyticsSource,
}: {
  selectedStartups: SelectedStartupsForAddToList;
  onCreate?: () => void;
  shouldAddMultiple?: boolean;
  analyticsSource: ANALYTICS_EVENT['Startup added to list']['source'];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const handleClose = () => setIsOpen(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { addStartupToList } = useAddStartupToList();
  const { enqueueSnackbar } = useSnackbar();
  const [getLists, { loading: getListsLoading }] =
    useGetStartupListsForAddStartupLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const [fuzzySearchByTitle] = useFuzzySearchListTitleMutation();
  const [fuzzyLoading, setFuzzyLoading] = useState(false);
  const [getCurrentListById, { loading: currentListByIdLoading }] =
    useGetCurrentStartupListByIdLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const matchRoute = useMatchRoutePattern<'startupListId'>();
  const [called, setCalled] = useState(false);
  const [getCategoryIds] = useGetProjectCategoryIdsByListIdLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const loading = useMemo(
    () => getListsLoading || currentListByIdLoading,
    [currentListByIdLoading, getListsLoading],
  );

  useEffect(() => {
    if (!isOpen) return;

    (async () => {
      const { data } = await getLists({
        variables: { limit: 20, offset: 0 },
        fetchPolicy: 'cache-and-network',
      });

      if (data) {
        setOptions(previousData =>
          uniqBy([...previousData, ...data.startup_lists], 'id'),
        );
      }
    })();
  }, [getLists, isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    if (!matchRoute?.startupListId) return;
    if (called) return;
    const currentListInOptions = options.find(
      option => option.id === Number(matchRoute.startupListId),
    );

    if (!!currentListInOptions) {
      // Put current list at the top
      setOptions(previousData =>
        uniqBy(
          [
            currentListInOptions,
            ...previousData.filter(
              option => option.id !== Number(matchRoute.startupListId),
            ),
          ],
          'id',
        ),
      );

      return;
    }

    (async () => {
      const { data } = await getCurrentListById({
        variables: { startupListId: Number(matchRoute.startupListId) },
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
          setCalled(true);
        },
      });

      if (data) {
        setOptions(previousData =>
          uniqBy([...data.startup_lists, ...previousData], 'id'),
        );
      }
    })();
  }, [
    called,
    getCurrentListById,
    getLists,
    isOpen,
    matchRoute?.startupListId,
    options,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFetchData = useCallback(
    debounce(async value => {
      if (!value || value.length <= 1) {
        setFuzzyLoading(false);
        return;
      }

      const { data } = await fuzzySearchByTitle({
        variables: { listTitle: value },
      });

      const fuzzyFind =
        data?.search_lists_title_fuzzy
          .map(s => s?.startup_list)
          .filter(s => s !== null && s !== undefined) || [];

      if (data) {
        setOptions(previousData =>
          uniqBy([...previousData, ...fuzzyFind], 'id'),
        );
      }

      setFuzzyLoading(false);
    }, 500),
    [],
  );

  const handleAddStartupToList = async ({
    selectedListId,
    selectedListTitle,
    selectedCategoryId,
    isLandscape,
  }: {
    selectedListId: number;
    selectedListTitle: string;
    selectedCategoryId: number;
    isLandscape?: boolean;
  }) => {
    try {
      for (const selectedStartup of selectedStartups) {
        await addStartupToList({
          selectedStartup,
          listId: selectedListId,
          categoryId: selectedCategoryId,
        });
      }

      enqueueSnackbar(
        <Typography>
          Startup added to <b>{selectedListTitle}</b>
        </Typography>,
        {
          variant: 'success',
          action: (
            <BaseSnackbarActionNavigateToList
              listId={selectedListId}
              categoryId={isLandscape ? selectedCategoryId : undefined}
            />
          ),
          autoHideDuration: 5000,
        },
      );

      captureAnalyticsEvent('Startup added to list', {
        listId: selectedListId,
        listTitle: selectedListTitle,
        categoryId: selectedCategoryId,
        source: analyticsSource,
        startupIds: selectedStartups.map(startup => startup.id),
      });

      onCreate && onCreate();
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Error adding startup. Please try again.`, {
        variant: 'error',
      });
    }
  };

  const height = useMemo(() => options.length * 18 + 12, [options.length]);

  return (
    <>
      {shouldAddMultiple ? (
        <Button
          sx={{ marginX: 1 }}
          ref={anchorEl}
          onClick={e => {
            e.stopPropagation();
            setIsOpen(prevState => !prevState);
          }}
        >
          Add {selectedStartups.length} to list
        </Button>
      ) : (
        <Tooltip title='Add to list'>
          <IconButton
            ref={anchorEl}
            sx={{
              padding: 0.25,
              color: isOpen ? 'primary.main' : 'text.secondary',
            }}
            onClick={e => {
              e.stopPropagation();
              setIsOpen(prevState => !prevState);
            }}
          >
            <PlaylistAddOutlined fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={isOpen}
          anchorEl={anchorEl.current}
          placement={'bottom-start'}
          sx={({ zIndex, shadows, spacing }) => ({
            zIndex: zIndex.tooltip,
            backgroundColor: 'white',
            height,
            maxHeight: '372px',
            padding: 0,
            boxShadow: shadows[10],
            borderRadius: 0,
            '&.base-Popper-root': { borderRadius: spacing(1) },
            '& .MuiAutocomplete-popper': {
              background: 'white',
            },
            '& .MuiAutocomplete-listbox': {
              boxShadow: 'none !important',
              width: '100%',
              padding: 0,
            },
            '& .MuiAutocomplete-paper': {
              boxShadow: 'none !important',
              width: '100%',
            },
            '& .MuiAutocomplete-root': {
              borderRadius: spacing(1),
              overflow: 'hidden',
            },
            '.MuiInputBase-root': {
              borderRadius: spacing(1),
            },
          })}
        >
          <Autocomplete
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setIsOpen(false);
              }
            }}
            disablePortal
            open={isOpen}
            loading={loading || fuzzyLoading}
            disableListWrap
            loadingText='Loading...'
            clearOnEscape
            autoHighlight
            popupIcon={null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={options}
            sx={{ width: '100%' }}
            getOptionLabel={option => option.title}
            noOptionsText={loading ? 'Loading...' : 'No lists found'}
            onChange={async (event, newValue) => {
              event.stopPropagation();

              if (newValue) {
                const { data } = await getCategoryIds({
                  variables: { startupListId: newValue.id },
                });

                if (!data || data.project_categories.length > 1) return;

                handleAddStartupToList({
                  selectedListId: newValue.id,
                  selectedListTitle: newValue.title,
                  selectedCategoryId: data.project_categories?.[0]?.id,
                });
              }
            }}
            componentsProps={{
              paper: { sx: { width: 300, whiteSpace: 'nowrap' } },
            }}
            renderOption={(htmlProps, option) => (
              <RenderOption
                key={`add-startups-to-list-${option.id}`}
                selectedStartups={selectedStartups}
                htmlProps={htmlProps}
                option={option}
                onCreate={(
                  newListId,
                  newListCategoryId,
                  newListTitle,
                  isLandscape,
                ) => {
                  handleAddStartupToList({
                    selectedListId: newListId,
                    selectedListTitle: newListTitle,
                    selectedCategoryId: newListCategoryId,
                    isLandscape,
                  });
                }}
              />
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                autoFocus
                value={inputValue}
                sx={{ width: '100%' }}
                onClick={e => e.stopPropagation()}
                InputProps={{
                  sx: textFieldSx,
                  ...params.InputProps,
                  disableUnderline: true,
                  startAdornment: (
                    <Search color='disabled' sx={{ marginX: 1 }} />
                  ),
                }}
                onChange={e => {
                  if (!fuzzyLoading) {
                    setFuzzyLoading(true);
                  }
                  setInputValue(e.target.value);
                  throttledFetchData(e.target.value);
                }}
                variant='standard'
                placeholder='Find a list'
              />
            )}
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
};
