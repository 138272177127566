// material
import { Container, Slide, Stack, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// components
import { QRCodeStartupPitchFormButton } from 'components/QRCodeStartupPitchFormButton';
import AccountPopover from './AccountPopover';
import { GoTo } from './GoTo/GoTo';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 3,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: `${theme.palette.background.default}`,
}));

// ----------------------------------------------------------------------

export default function NavbarContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Container
      maxWidth='xl'
      sx={{
        display: 'flex',
        alignItems: 'center',
        spacing: { xs: 0.5, sm: 1.5 },
      }}
    >
      <Slide direction='down' in={true} mountOnEnter unmountOnExit timeout={0}>
        <SearchbarStyle>
          <GoTo />
          <Stack marginLeft='auto' direction='row' spacing={1} marginRight={2}>
            <QRCodeStartupPitchFormButton isMobile={isMobile} />

            <NotificationsPopover />
          </Stack>
          <AccountPopover />
        </SearchbarStyle>
      </Slide>
    </Container>
  );
}
