import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popper,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  EnumTableEntityVisibilityEnum,
  EnumTableProjectStagesEnum,
  useDeleteProjectStartupListMutation,
  useGetProjectsAndLeadsMinimalQuery,
  useGetProjectStartupListQuery,
  useInsertProjectStartupListMutation,
  useUpdateStartupListVisibilityMutation,
} from 'apollo/generated/sdkShared';
import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import { BaseSnackbarActionNavigateToProject } from 'components/base/BaseSnackbarActions';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';
import { useCreateLeadForList } from './useCreateLeadForList';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';

type StartupList = {
  id: number;
  title: string;
  projectId?: number;
  visibility: EnumTableEntityVisibilityEnum;
};

type Props = {
  startupList: StartupList;
};

const elementWidth = '300px';

const buttonSx: SxProps = {
  color: 'grey.600',
  fontWeight: 400,
  paddingY: 0.4,
  paddingX: 0,
  maxWidth: '170px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  justifyContent: 'flex-start',
  '& .MuiButton-icon': { marginLeft: 'auto' },
  '& .MuiTypography-root': { fontSize: '14px' },
};

const textFieldSx: SxProps<Theme> = ({ palette, spacing }) => ({
  height: '100%',
  background: palette.grey[200],
  paddingRight: `${spacing(3)} !important`,
  fontSize: '14px',
  minWidth: '210px',
  color: 'grey.800',
  marginTop: 0,
  '& input': {
    marginTop: 0,
    width: 'fit-content !important',
  },
});

export const ListVisibilityModal = ({
  onHide,
  onSubmit,
  listId,
  listTitle,
}: {
  onHide: () => void;
  onSubmit: () => void;
  listTitle: string;
  listId: number;
}) => {
  const { name } = useCurrentOrganizationFromContext();
  const [updateVisibility] = useUpdateStartupListVisibilityMutation();

  return (
    <Dialog open maxWidth='sm' onClose={onHide}>
      <DialogTitle sx={{ marginBottom: 5 }}>Permissions</DialogTitle>
      <DialogContent>
        <Typography>
          The list <b>&apos;{listTitle}&apos;</b> has limited sharing
          permissions. In order to add it to this project, it will be changed to
          <b> &apos;Everyone at {name}&apos;</b>. Do you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await updateVisibility({
              variables: {
                id: listId,
                payload: { visibility: 'organization' },
              },
            });
            onSubmit();
          }}
          variant='contained'
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateNewLead = ({
  selectedList,
  onCreate,
  currentProjectsStartupListId,
}: {
  currentProjectsStartupListId?: number;
  selectedList: StartupList;
  onCreate: () => void;
}) => {
  const [deleteProjectStartupList] = useDeleteProjectStartupListMutation();
  const { handleCreateLead } = useCreateLeadForList({
    startupListId: selectedList.id,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleCreate = async () => {
    if (currentProjectsStartupListId) {
      const { errors } = await deleteProjectStartupList({
        variables: { id: currentProjectsStartupListId },
      });

      if (errors) {
        enqueueSnackbar('Error unlinking list from lead', {
          variant: 'error',
        });
        return;
      }
    }

    const response = await handleCreateLead();

    if (!response?.projectId || !response?.stage) {
      return;
    }

    captureAnalyticsEvent('Project Lead Created', {
      projectId: response.projectId,
      source: 'Link List to Lead Button',
    });

    enqueueSnackbar('Saved', {
      variant: 'success',
      action: (
        <BaseSnackbarActionNavigateToProject
          projectLeadId={response.projectId}
          stage={response.stage}
        />
      ),
    });

    onCreate();
  };

  return (
    <BaseAddButtonForAutocomplete
      onClick={e => {
        e.stopPropagation();
        handleCreate();
      }}
      text='New Lead'
    />
  );
};

const RenderInput = (params: AutocompleteRenderInputParams) => {
  return (
    <TextField
      {...params}
      autoFocus
      sx={{ width: '100%' }}
      InputProps={{
        sx: textFieldSx,
        ...params.InputProps,
      }}
      variant='standard'
      placeholder='Search or create new lead'
    />
  );
};

const RenderOption = ({
  htmlProps,
  option,
  startupList,
  onCreateNewLead,
  isSelected,
  currentProjectsStartupListId,
  onUnlink,
}: {
  htmlProps: HTMLAttributes<HTMLLIElement>;
  option: {
    __typename?: 'projects' | undefined;
    id: number;
    title: string;
    stage: EnumTableProjectStagesEnum;
  };
  currentProjectsStartupListId?: number;
  startupList: StartupList;
  onCreateNewLead: () => void;
  onUnlink: () => void;
  isSelected: boolean;
}) => {
  // @ts-expect-error: data-option-index is not a valid prop
  // eslint-disable-next-line react/prop-types
  const index = htmlProps?.['data-option-index'];
  const navigate = useNavigate();
  const path = buildProjectDetailsPath({ id: option.id, stage: option.stage });

  const [hovered, setHovered] = useState(false);

  return (
    <>
      {index === 0 && (
        <CreateNewLead
          selectedList={startupList}
          currentProjectsStartupListId={currentProjectsStartupListId}
          onCreate={onCreateNewLead}
        />
      )}
      <Box
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        component='li'
        sx={({ spacing }) => ({ height: spacing(5) })}
        {...htmlProps}
      >
        <Typography fontSize={14} noWrap>
          {option.title}
        </Typography>
        {(hovered || isSelected) && (
          <Stack direction='row' sx={{ marginLeft: 'auto' }}>
            {isSelected && (
              <Button
                size='small'
                onClick={e => {
                  e.stopPropagation();
                  onUnlink();
                }}
              >
                Unlink
              </Button>
            )}
            <Button
              sx={{ marginLeft: 'auto' }}
              size='small'
              onClick={e => {
                e.stopPropagation();
                navigate(path, {
                  state: {
                    backToPage: `${window.location.pathname}${window.location.hash}`,
                  },
                });
              }}
            >
              Open
            </Button>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default function LinkListToLead({ startupList }: Props) {
  const { normalizedDefaultFilter: leadsFilter } = useServerFiltersContext(
    'projectLeadsFilters',
  );
  const { normalizedDefaultFilter: projectsFilter } =
    useServerFiltersContext('projectPoCFilters');
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const {
    data,
    refetch,
    loading: projectStartupListLoading,
    previousData,
  } = useGetProjectStartupListQuery({
    variables: { startupListId: startupList.id },
    fetchPolicy: 'cache-and-network',
  });
  const [isListVisibilityModalOpen, setListVisibilityModalOpen] =
    useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [insert] = useInsertProjectStartupListMutation();
  const [deleteProjectStartupList, { loading: deleteLoading }] =
    useDeleteProjectStartupListMutation();
  const {
    data: projectLeadsData,
    loading: projectLeadsLoading,
    error,
  } = useGetProjectsAndLeadsMinimalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: { _or: [leadsFilter, projectsFilter] },
    },
  });

  const loading =
    projectStartupListLoading || projectLeadsLoading || deleteLoading;

  const value = useMemo(
    () => data?.projects_startup_lists?.[0]?.project,
    [data?.projects_startup_lists],
  );
  // Sort the options: selected first
  const options = useMemo(
    () =>
      projectLeadsData?.projects
        .slice()
        .sort(a => (a.id === value?.id ? -1 : 1)) || [],
    [projectLeadsData?.projects, value?.id],
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleAddStartupToLead = async ({
    selectedLeadId,
    selectedLeadStage,
    shouldUnlink,
  }: {
    selectedLeadId?: number;
    selectedLeadStage?: EnumTableProjectStagesEnum;
    shouldUnlink?: boolean;
  }) => {
    try {
      const projectStartupListId = data?.projects_startup_lists?.[0]?.id;

      if (value && projectStartupListId) {
        const { errors } = await deleteProjectStartupList({
          variables: { id: projectStartupListId },
        });

        if (errors) {
          enqueueSnackbar('Error unlinking list from lead', {
            variant: 'error',
          });
          return;
        }
      }

      if (shouldUnlink) {
        enqueueSnackbar('Unlinked', { variant: 'success' });
        await refetch();

        return;
      }

      if (!selectedLeadId || !selectedLeadStage) return;

      const { errors } = await insert({
        variables: {
          object: {
            startup_list_id: startupList.id,
            project_id: selectedLeadId,
          },
        },
      });

      if (!errors) {
        enqueueSnackbar('Saved', {
          variant: 'success',
          action: (
            <BaseSnackbarActionNavigateToProject
              projectLeadId={selectedLeadId}
              stage={selectedLeadStage}
            />
          ),
        });
        captureAnalyticsEvent('Lead/Project Linked to List', {
          projectId: selectedLeadId,
          source: 'Link List to Lead Button',
        });
        await refetch();
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Error trying to link list with lead`, {
        variant: 'error',
      });
    }
  };

  if (loading && !previousData) return <Skeleton sx={{ minWidth: '170px' }} />;
  if (error) return <Typography color='error'>Error</Typography>;

  return (
    <>
      <Button
        sx={buttonSx}
        endIcon={!isOpen ? <ExpandMore /> : <ExpandLess />}
        ref={anchorEl}
        data-testid='link-list-to-lead-button'
        onClick={e => {
          e.stopPropagation();
          if (!isOpen && startupList.visibility !== 'organization') {
            setListVisibilityModalOpen(true);
            return;
          }

          setIsOpen(prevState => !prevState);
        }}
      >
        <Tooltip
          title={value?.title ? `Linked lead/project: ${value.title}` : ''}
        >
          <Typography noWrap>{value?.title || 'Link lead/project'}</Typography>
        </Tooltip>
      </Button>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={isOpen}
          anchorEl={anchorEl.current}
          placement={'bottom-start'}
          sx={({ zIndex }) => ({ zIndex: zIndex.tooltip })}
        >
          <Autocomplete
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setIsOpen(false);
              }
            }}
            disablePortal
            open={isOpen}
            disableListWrap
            disableCloseOnSelect
            clearOnEscape
            autoHighlight
            popupIcon={null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={options}
            sx={{ minWidth: elementWidth, marginTop: 1 }}
            getOptionLabel={option => option.title}
            value={value}
            noOptionsText={<Typography>No options</Typography>}
            onChange={(_, newValue) => {
              if (newValue) {
                handleAddStartupToLead({
                  selectedLeadId: newValue?.id,
                  selectedLeadStage: newValue?.stage,
                });
              }
            }}
            data-testid='link-list-to-lead-autocomplete'
            componentsProps={{
              paper: { sx: { width: elementWidth, whiteSpace: 'nowrap' } },
            }}
            renderOption={(htmlProps, option) => (
              <RenderOption
                key={`option-link-list-to-lead-${option.id}`}
                htmlProps={htmlProps}
                startupList={startupList}
                option={option}
                isSelected={option.id === value?.id}
                currentProjectsStartupListId={
                  data?.projects_startup_lists?.[0]?.id
                }
                onCreateNewLead={async () => {
                  await refetch();
                  setIsOpen(false);
                }}
                onUnlink={async () => {
                  await handleAddStartupToLead({ shouldUnlink: true });
                }}
              />
            )}
            renderInput={RenderInput}
          />
        </Popper>
      </ClickAwayListener>
      {isListVisibilityModalOpen && (
        <ListVisibilityModal
          listId={startupList.id}
          listTitle={startupList.title}
          onHide={() => setListVisibilityModalOpen(false)}
          onSubmit={() => {
            setListVisibilityModalOpen(false);

            setIsOpen(true);
          }}
        />
      )}
    </>
  );
}
