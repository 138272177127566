import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useGetMatrixSettingsQuery } from 'apollo/generated/sdkInnovationManager';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import { ReferenceCustomersColumn } from 'components/dashboard/CategoryReferenceCustomers';
import { ReactionsCell } from 'components/startupComparisonTable/shared/ReactionsCell';
import { useMemo } from 'react';
import getReferencedCustomersAndSuppliers from 'utils/getReferencedCustomersAndSuppliers';
import {
  REACTIONS_HEADER_WIDTH,
  ReactionsColumnHeader,
} from '../ReactionsColumnHeader';
import { MainColumnHeader } from './MainColumnHeader';
import { MemoizedNameColumn } from './NameColumn';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
} from './StartupsTableContext';
import { SharedCategoryForStartupComparison } from './types';
import { StartupCorporateCustomer } from './useGetColumns';

export type StartupButtonActionHelpers = {
  favouriteStartups: {
    domain: string;
    categorizedSupplierId: number;
  }[];
  addToList: ({ startup }: { startup: unknown }) => void;
  loadingAddStartupToList: boolean;
};

export const CUSTOM_DIMENSION_HEADER_FIELD_PREFIX = 'c';
export const CUSTOMERS_COLUMN_MIN_WIDTH = 168;

export const useGetMainColumns = ({
  isPublicPage,
  isDiscovery,
  selectedCategory,
  categorizedStartups,
  hasCustomers,
}: {
  isPublicPage: boolean;
  isDiscovery: boolean;
  selectedCategory: SharedCategoryForStartupComparison;
  categorizedStartups: CategorizedStartupForMatrix[];
  hasCustomers: boolean;
}) => {
  const { data } = useGetMatrixSettingsQuery({
    variables: { startupListId: selectedCategory.startup_list_id },
    skip: !selectedCategory.startup_list_id,
  });

  const matrixSettings = useMemo(
    () =>
      data?.list_matrix_settings[0] || {
        customers_column_enabled: true,
        description_column_enabled: true,
        reactions_column_enabled: false,
      },
    [data?.list_matrix_settings],
  );

  const mainColumns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Position, company',
          minWidth: 320,
          renderCell: (
            params: GridRenderCellParams<string, DecoratedStartup>,
          ) => {
            return <MemoizedNameColumn {...params.row} isLongList={false} />;
          },
          disableColumnMenu: true,
          sortable: false,
          hideable: false,
          disableReorder: true,
        },
        matrixSettings.reactions_column_enabled
          ? {
              field: 'voters',
              headerName: 'Reactions',
              minWidth: REACTIONS_HEADER_WIDTH,
              renderHeader: () => {
                return (
                  <ReactionsColumnHeader
                    isReadonly={isPublicPage || isDiscovery}
                  />
                );
              },
              renderCell: (
                params: GridRenderCellParams<string, DecoratedStartup>,
              ) => (
                <ReactionsCell
                  {...params.row}
                  id={+params.id}
                  isDiscovery={isDiscovery}
                  isPublicPage={isPublicPage}
                />
              ),
              disableColumnMenu: true,
              cellClassName: 'voting-commenting',
              sortable: false,
              hideable: false,
            }
          : null,
        hasCustomers && matrixSettings.customers_column_enabled
          ? {
              field: 'startup_corporate_customers',
              headerName: 'Customers',
              minWidth: CUSTOMERS_COLUMN_MIN_WIDTH,
              align: 'center',
              renderHeader: () => (
                <MainColumnHeader
                  field='startup_corporate_customers'
                  label='Customers'
                  isReadonly={isPublicPage || isDiscovery}
                />
              ),
              renderCell: (
                params: GridRenderCellParams<
                  StartupCorporateCustomer[],
                  DecoratedStartup
                >,
              ) => {
                const { referenceCustomersWithSuppliers } =
                  getReferencedCustomersAndSuppliers({
                    startups: categorizedStartups.map(cs => cs.startup),
                    columnProvidedReferencedCustomers: params.value?.map(
                      c => c.corporate_customer,
                    ),
                  });

                if (!params.value?.length) return '-';

                return (
                  <>
                    <ReferenceCustomersColumn
                      referenceCustomerList={referenceCustomersWithSuppliers}
                      allowHoverDropDown={false}
                    />
                  </>
                );
              },
              disableColumnMenu: true,
              sortable: false,
              hideable: true,
            }
          : null,
        matrixSettings.description_column_enabled
          ? {
              field: 'short_description',
              headerName: 'Description',
              minWidth: 350,
              maxWidth: 750,
              flex: 1,
              renderHeader: () => (
                <MainColumnHeader
                  field='short_description'
                  label='Description'
                  isReadonly={isPublicPage || isDiscovery}
                />
              ),
              renderCell: (
                params: GridRenderCellParams<string, DecoratedStartup>,
              ) => {
                const value = params.value || '-';

                return (
                  <ExpandedGridCell
                    value={value}
                    multiLineElipsis={true}
                    numberOfLines={3}
                  />
                );
              },
              disableColumnMenu: true,
              sortable: false,
              disableReorder: true,
              hideable: true,
            }
          : null,
      ] as GridColumns<DecoratedStartup>,
    [
      hasCustomers,
      isDiscovery,
      isPublicPage,
      matrixSettings,
      categorizedStartups,
    ],
  );

  return {
    mainColumns: mainColumns.filter(Boolean),
  };
};
