import { TextField } from '@mui/material';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { FieldProps } from 'pages/request-form/types';
import { FormLabelWithTooltip } from './ProblemField';

type AdditionalInformationFieldSchemaValues = Pick<
  SourcingOrderState,
  'additional_info'
>;

export function AdditionalInformationField({
  getFieldProps,
  touched,
  errors,
}: FieldProps<
  Pick<AdditionalInformationFieldSchemaValues, 'additional_info'>
>) {
  return (
    <>
      <FormLabelWithTooltip
        label='Additional information'
        tooltipText='Please provide any additional information that might be relevant for the solution.'
      />
      <TextField
        multiline
        rows={4}
        fullWidth
        data-test='additionalInformation--field'
        {...getFieldProps('additional_info')}
        error={Boolean(touched.additional_info && errors.additional_info)}
        helperText={touched.additional_info && errors.additional_info}
      />
    </>
  );
}
