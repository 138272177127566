import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { KanbanColumnType } from 'components/projectKanban/KanbanBoard';
import { PROJECTS_STAGE_MAPPING } from 'utils/projectStageEnum';

export const E2E_ADOPTION_COLUMNS: KanbanColumnType<EnumTableProjectStagesEnum>[] =
  [
    {
      id: 'adoption',
      name: PROJECTS_STAGE_MAPPING['adoption'],
    },
    {
      id: 'adopted',
      name: PROJECTS_STAGE_MAPPING['adopted'],
    },
    {
      id: 'scaling',
      name: PROJECTS_STAGE_MAPPING['scaling'],
    },
    {
      id: 'scaling_completed',
      name: PROJECTS_STAGE_MAPPING['scaling_completed'],
    },
    {
      id: 'not_adopted',
      name: PROJECTS_STAGE_MAPPING['not_adopted'],
    },
  ];

export const E2E_PILOT_COLUMNS: KanbanColumnType<EnumTableProjectStagesEnum>[] =
  [
    {
      id: 'assessment',
      name: PROJECTS_STAGE_MAPPING['assessment'],
    },
    {
      id: 'purchasing',
      name: PROJECTS_STAGE_MAPPING['purchasing'],
    },
    {
      id: 'testing',
      name: PROJECTS_STAGE_MAPPING['testing'],
    },
  ];

export const E2E_COLUMNS: KanbanColumnType<EnumTableProjectStagesEnum>[] = [
  {
    id: 'scoping',
    name: PROJECTS_STAGE_MAPPING['scoping'],
  },
  {
    id: 'sourcing',
    name: PROJECTS_STAGE_MAPPING['sourcing'],
  },
  ...E2E_PILOT_COLUMNS,
  {
    id: 'test_success',
    name: PROJECTS_STAGE_MAPPING['test_success'],
  },
  {
    id: 'test_fail',
    name: PROJECTS_STAGE_MAPPING['test_fail'],
  },
  {
    id: 'discarded',
    name: PROJECTS_STAGE_MAPPING['discarded'],
  },
  {
    id: 'on_hold',
    name: PROJECTS_STAGE_MAPPING['on_hold'],
  },
  ...E2E_ADOPTION_COLUMNS,
];

// Those are the distinct values exported from the DB
export const ALL_INDUSTRIES = [
  '3D Printing',
  '3D Technology',
  'A/B Testing',
  'Accounting',
  'Ad Exchange',
  'Ad Network',
  'Ad Retargeting',
  'Ad Server',
  'Ad Targeting',
  'Adult',
  'Advanced Materials',
  'Adventure Travel',
  'Advertising',
  'Advertising Platforms',
  'Advice',
  'Aerospace',
  'Affiliate Marketing',
  'Agriculture',
  'AgTech',
  'Air Transportation',
  'Alternative Medicine',
  'Alumni',
  'American Football',
  'Amusement Park and Arcade',
  'Analytics',
  'Android',
  'Angel Investment',
  'Animal Feed',
  'Animation',
  'App Discovery',
  'Application Performance Management',
  'Application Specific Integrated Circuit (ASIC)',
  'App Marketing',
  'Apps',
  'Aquaculture',
  'Architecture',
  'Archiving Service',
  'Art',
  'Artificial Intelligence',
  'Asset Management',
  'Assisted Living',
  'Assistive Technology',
  'Association',
  'Auctions',
  'Audio',
  'Audiobooks',
  'Augmented Reality',
  'Auto Insurance',
  'Automotive',
  'Autonomous Vehicles',
  'B2B',
  'B2C',
  'Baby',
  'Bakery',
  'Banking',
  'Baseball',
  'Basketball',
  'Battery',
  'Beauty',
  'Big Data',
  'Billing',
  'Biofuel',
  'Bioinformatics',
  'Biomass Energy',
  'Biometrics',
  'Biopharma',
  'Biotechnology',
  'Bitcoin',
  'Blockchain',
  'Blogging Platforms',
  'Boating',
  'Brand Marketing',
  'Brewing',
  'Broadcasting',
  'Browser Extensions',
  'Building Maintenance',
  'Building Material',
  'Business Development',
  'Business Information Systems',
  'Business Intelligence',
  'Business Travel',
  'CAD',
  'Call Center',
  'Cannabis',
  'Career Planning',
  'Car Sharing',
  'Casino',
  'Casual Games',
  'Catering',
  'Cause Marketing',
  'Celebrity',
  'Charity',
  'Charter Schools',
  'Chemical',
  'Chemical Engineering',
  'Child Care',
  'Children',
  'CivicTech',
  'Civil Engineering',
  'Classifieds',
  'Clean Energy',
  'CleanTech',
  'Clinical Trials',
  'Cloud Computing',
  'Cloud Data Services',
  'Cloud Infrastructure',
  'Cloud Management',
  'Cloud Security',
  'Cloud Storage',
  'CMS',
  'Coffee',
  'Collaboration',
  'Collaborative Consumption',
  'Collectibles',
  'Collection Agency',
  'College Recruiting',
  'Comics',
  'Commercial',
  'Commercial Insurance',
  'Commercial Lending',
  'Commercial Real Estate',
  'Communication Hardware',
  'Communications Infrastructure',
  'Communities',
  'Compliance',
  'Computer',
  'Computer Vision',
  'Concerts',
  'Confectionery',
  'Console Games',
  'Construction',
  'Consulting',
  'Consumer',
  'Consumer Applications',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Lending',
  'Consumer Research',
  'Consumer Reviews',
  'Consumer Software',
  'Contact Management',
  'Content',
  'Content Creators',
  'Content Delivery Network',
  'Content Discovery',
  'Content Marketing',
  'Content Syndication',
  'Contests',
  'Continuing Education',
  'Cooking',
  'Corporate Training',
  'Corrections Facilities',
  'Cosmetics',
  'Cosmetic Surgery',
  'Coupons',
  'Courier Service',
  'Coworking',
  'Craft Beer',
  'Creative Agency',
  'Credit',
  'Credit Bureau',
  'Credit Cards',
  'Cricket',
  'CRM',
  'Crowdfunding',
  'Crowdsourcing',
  'Cryptocurrency',
  'Customer Service',
  'Cyber Security',
  'Cycling',
  'Darknet',
  'Database',
  'Data Center',
  'Data Center Automation',
  'Data Integration',
  'Data Mining',
  'Data Storage',
  'Data Visualization',
  'Dating',
  'Debit Cards',
  'Debt Collections',
  'Delivery',
  'Delivery Service',
  'Dental',
  'Desktop Apps',
  'Developer APIs',
  'Developer Platform',
  'Developer Tools',
  'Diabetes',
  'Dietary Supplements',
  'Digital Entertainment',
  'Digital Marketing',
  'Digital Media',
  'Digital Signage',
  'Direct Marketing',
  'Direct Sales',
  'Distillery',
  'Diving',
  'DIY',
  'Document Management',
  'Document Preparation',
  'Domain Registrar',
  'DRM',
  'Drone Management',
  'Drones',
  'DSP',
  'EBooks',
  'E-Commerce',
  'E-Commerce Platforms',
  'Ediscovery',
  'EdTech',
  'Education',
  'Edutainment',
  'Elder Care',
  'Elderly',
  'E-Learning',
  'Electrical Distribution',
  'Electric Vehicle',
  'Electronic Design Automation (EDA)',
  'Electronic Health Record (EHR)',
  'Electronics',
  'Email',
  'Email Marketing',
  'Embedded Software',
  'Embedded Systems',
  'Emergency Medicine',
  'Emerging Markets',
  'Employee Benefits',
  'Employment',
  'Energy',
  'Energy Efficiency',
  'Energy Management',
  'Energy Storage',
  'Enterprise',
  'Enterprise Applications',
  'Enterprise Resource Planning (ERP)',
  'Enterprise Software',
  'Environmental Consulting',
  'Environmental Engineering',
  'Equestrian',
  'E-Signature',
  'eSports',
  'Ethereum',
  'Event Management',
  'Event Promotion',
  'Events',
  'Extermination Service',
  'Eyewear',
  'Facebook',
  'Facial Recognition',
  'Facilities Support Services',
  'Facility Management',
  'Family',
  'Fantasy Sports',
  'Farmers Market',
  'Farming',
  'Fashion',
  'Fast-Moving Consumer Goods',
  'Ferry Service',
  'Fertility',
  'Field-Programmable Gate Array (FPGA)',
  'Field Support',
  'File Sharing',
  'Film',
  'Film Distribution',
  'Film Production',
  'Finance',
  'Financial Exchanges',
  'Financial Services',
  'FinTech',
  'First Aid',
  'Fitness',
  'Flash Sale',
  'Flash Storage',
  'Fleet Management',
  'Flowers',
  'Food and Beverage',
  'Food Delivery',
  'Food Processing',
  'Food Trucks',
  'Forestry',
  'Fossil Fuels',
  'Foundries',
  'Franchise',
  'Fraud Detection',
  'Freelance',
  'Freemium',
  'Freight Service',
  'Fruit',
  'Fuel',
  'Fuel Cell',
  'Funding Platform',
  'Funerals',
  'Furniture',
  'Gambling',
  'Gamification',
  'Gaming',
  'Generation Y',
  'Generation Z',
  'Genetics',
  'Geospatial',
  'Gift',
  'Gift Card',
  'Gift Exchange',
  'Gift Registry',
  'Golf',
  'Google',
  'Google Glass',
  'Government',
  'GovTech',
  'GPS',
  'GPU',
  'Graphic Design',
  'Green Building',
  'Green Consumer Goods',
  'GreenTech',
  'Grocery',
  'Group Buying',
  'Guides',
  'Handmade',
  'Hardware',
  'Health Care',
  'Health Diagnostics',
  'Health Insurance',
  'Hedge Funds',
  'Higher Education',
  'Hockey',
  'Home and Garden',
  'Home Decor',
  'Home Health Care',
  'Home Improvement',
  'Homeland Security',
  'Homeless Shelter',
  'Home Renovation',
  'Home Services',
  'Horticulture',
  'Hospital',
  'Hospitality',
  'Hotel',
  'Housekeeping Service',
  'Human Computer Interaction',
  'Humanitarian',
  'Human Resources',
  'Hunting',
  'Hydroponics',
  'IaaS',
  'Identity Management',
  'Image Recognition',
  'Impact Investing',
  'Incubators',
  'Independent Music',
  'Indoor Positioning',
  'Industrial',
  'Industrial Automation',
  'Industrial Design',
  'Industrial Engineering',
  'Industrial Manufacturing',
  'In-Flight Entertainment',
  'Information and Communications Technology (ICT)',
  'Information Services',
  'Information Technology',
  'Infrastructure',
  'Innovation Management',
  'Insurance',
  'InsurTech',
  'Intellectual Property',
  'Intelligent Systems',
  'Interior Design',
  'Internet',
  'Internet of Things',
  'Internet Radio',
  'Intrusion Detection',
  'iOS',
  'ISP',
  'IT Infrastructure',
  'IT Management',
  'Janitorial Service',
  'Jewelry',
  'Journalism',
  'Knowledge Management',
  'Landscaping',
  'Language Learning',
  'Laser',
  'Last Mile Transportation',
  'Laundry and Dry-cleaning',
  'Law Enforcement',
  'Lead Generation',
  'Lead Management',
  'Leasing',
  'Legal',
  'Legal Tech',
  'Leisure',
  'Lending',
  'LGBT',
  'Life Insurance',
  'Life Science',
  'Lifestyle',
  'Lighting',
  'Limousine Service',
  'Lingerie',
  'Linux',
  'Livestock',
  'Local',
  'Local Advertising',
  'Local Business',
  'Local Shopping',
  'Location Based Services',
  'Logistics',
  'Loyalty Programs',
  'Machine Learning',
  'Machinery Manufacturing',
  'macOS',
  'Made to Order',
  'Management Consulting',
  'Management Information Systems',
  'Manufacturing',
  'Mapping Services',
  'Marine Technology',
  'Marine Transportation',
  'Marketing',
  'Marketing Automation',
  'Marketplace',
  'Market Research',
  'Mechanical Design',
  'Mechanical Engineering',
  'Media and Entertainment',
  'Medical',
  'Medical Device',
  'Meeting Software',
  "Men's",
  'Messaging',
  'mHealth',
  'Micro Lending',
  'Military',
  'Millennials',
  'Mineral',
  'Mining',
  'Mining Technology',
  'MMO Games',
  'Mobile',
  'Mobile Advertising',
  'Mobile Apps',
  'Mobile Devices',
  'Mobile Payments',
  'MOOC',
  'Motion Capture',
  'Multi-level Marketing',
  'Museums and Historical Sites',
  'Music',
  'Musical Instruments',
  'Music Education',
  'Music Label',
  'Music Streaming',
  'Music Venues',
  'Nanotechnology',
  'National Security',
  'Natural Language Processing',
  'Natural Resources',
  'Navigation',
  'Network Hardware',
  'Network Security',
  'Neuroscience',
  'News',
  'NFC',
  'Nightclubs',
  'Nightlife',
  'Nintendo',
  'Non Profit',
  'Nuclear',
  'Nursing and Residential Care',
  'Nutraceutical',
  'Nutrition',
  'Office Administration',
  'Oil and Gas',
  'Online Auctions',
  'Online Forums',
  'Online Games',
  'Online Portals',
  'Open Source',
  'Operating Systems',
  'Optical Communication',
  'Organic',
  'Organic Food',
  'Outdoor Advertising',
  'Outdoors',
  'Outpatient Care',
  'Outsourcing',
  'PaaS',
  'Packaging Services',
  'Paper Manufacturing',
  'Parenting',
  'Parking',
  'Parks',
  'Payments',
  'PC Games',
  'Peer to Peer',
  'Penetration Testing',
  'Performing Arts',
  'Personal Branding',
  'Personal Development',
  'Personal Finance',
  'Personal Health',
  'Personalization',
  'Pet',
  'Pharmaceutical',
  'Photo Editing',
  'Photography',
  'Photo Sharing',
  'Physical Security',
  'Plastics and Rubber Manufacturing',
  'Playstation',
  'Podcast',
  'Point of Sale',
  'Politics',
  'Pollution Control',
  'Ports and Harbors',
  'Power Grid',
  'Precious Metals',
  'Prediction Markets',
  'Predictive Analytics',
  'Presentations',
  'Presentation Software',
  'Price Comparison',
  'Primary Education',
  'Printing',
  'Privacy',
  'Private Cloud',
  'Private Social Networking',
  'Procurement',
  'Product Design',
  'Productivity Tools',
  'Product Management',
  'Product Research',
  'Product Search',
  'Professional Networking',
  'Professional Services',
  'Project Management',
  'Property Development',
  'Property Insurance',
  'Property Management',
  'Psychology',
  'Public Relations',
  'Public Safety',
  'Public Transportation',
  'Publishing',
  'Q&A',
  'QR Codes',
  'Quality Assurance',
  'Quantified Self',
  'Quantum Computing',
  'Racing',
  'Railroad',
  'Reading Apps',
  'Real Estate',
  'Real Estate Investment',
  'Real Time',
  'Recipes',
  'Recreation',
  'Recreational Vehicles',
  'Recruiting',
  'Recycling',
  'Rehabilitation',
  'Religion',
  'Renewable Energy',
  'Rental',
  'Rental Property',
  'Reputation',
  'Reservations',
  'Residential',
  'Resorts',
  'Restaurants',
  'Retail',
  'Retail Technology',
  'Retirement',
  'RFID',
  'Ride Sharing',
  'RISC',
  'Risk Management',
  'Robotics',
  'Roku',
  'Rugby',
  'SaaS',
  'Sailing',
  'Sales',
  'Sales Automation',
  'Same Day Delivery',
  'Satellite Communication',
  'Scheduling',
  'Seafood',
  'Search Engine',
  'Secondary Education',
  'Security',
  'Self-Storage',
  'SEM',
  'Semantic Search',
  'Semantic Web',
  'Semiconductor',
  'Sensor',
  'SEO',
  'Serious Games',
  'Service Industry',
  'Sex Industry',
  'Sex Tech',
  'Sharing Economy',
  'Shipping',
  'Shipping Broker',
  'Shoes',
  'Shopping',
  'Shopping Mall',
  'Simulation',
  'Skiing',
  'Skill Assessment',
  'Small and Medium Businesses',
  'Smart Building',
  'Smart Cities',
  'Smart Home',
  'SMS',
  'Snack Food',
  'SNS',
  'Soccer',
  'Social',
  'Social Assistance',
  'Social Bookmarking',
  'Social CRM',
  'Social Entrepreneurship',
  'Social Impact',
  'Social Media',
  'Social Media Advertising',
  'Social Media Management',
  'Social Media Marketing',
  'Social Network',
  'Social News',
  'Social Recruiting',
  'Social Shopping',
  'Software',
  'Software Engineering',
  'Solar',
  'Space Travel',
  'Spam Filtering',
  'Speech Recognition',
  'Sponsorship',
  'Sporting Goods',
  'Sports',
  'Staffing Agency',
  'STEM Education',
  'Stock Exchanges',
  'Subscription Service',
  'Supply Chain Management',
  'Surfing',
  'Sustainability',
  'Swimming',
  'Table Tennis',
  'Task Management',
  'Taxi Service',
  'Tea',
  'Technical Support',
  'Teenagers',
  'Telecommunications',
  'Tennis',
  'Test and Measurement',
  'Text Analytics',
  'Textbook',
  'Textiles',
  'Theatre',
  'Therapeutics',
  'Ticketing',
  'Timber',
  'Timeshare',
  'Tobacco',
  'Tourism',
  'Tour Operator',
  'Toys',
  'Trade Shows',
  'Trading Platform',
  'Training',
  'Transaction Processing',
  'Translation Service',
  'Transportation',
  'Travel',
  'Travel Accommodations',
  'Travel Agency',
  'Tutoring',
  'TV',
  'TV Production',
  'Twitter',
  'Ultimate Frisbee',
  'Underserved Children',
  'Unified Communications',
  'Universities',
  'Usability Testing',
  'UX Design',
  'Vacation Rental',
  'Vending and Concessions',
  'Venture Capital',
  'Vertical Search',
  'Veterinary',
  'Video',
  'Video Advertising',
  'Video Chat',
  'Video Conferencing',
  'Video Editing',
  'Video Games',
  'Video on Demand',
  'Video Streaming',
  'Virtual Assistant',
  'Virtual Currency',
  'Virtual Desktop',
  'Virtual Goods',
  'Virtualization',
  'Virtual Reality',
  'Virtual Workforce',
  'Virtual World',
  'Visual Search',
  'Vocational Education',
  'VoIP',
  'Volley Ball',
  'Warehousing',
  'Waste Management',
  'Water',
  'Water Purification',
  'Water Transportation',
  'Wealth Management',
  'Wearables',
  'Web Apps',
  'Web Browsers',
  'Web Design',
  'Web Development',
  'Web Hosting',
  'WebOS',
  'Wedding',
  'Wellness',
  'Wholesale',
  'Wind Energy',
  'Windows',
  'Windows Phone',
  'Wine And Spirits',
  'Winery',
  'Wired Telecommunications',
  'Wireless',
  "Women's",
  'Wood Processing',
  'Xbox',
  'Young Adults',
];

// Those are the distinct values exported from the DB
export const ALL_COUNTRIES = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, Democratic Republic of the',
  'Cook Islands',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  "Korea (Democratic People's Republic of)",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova, Republic of',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norfolk Island',
  'Northern Mariana Islands',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Réunion',
  'Russian Federation',
  'Rwanda',
  'Saint Barthélemy',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom of Great Britain and Northern Ireland',
  'United States Minor Outlying Islands',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
