import {
  useDeleteStartupListTagMutation,
  useDeleteStartupListsStartupListTagMutation,
  useFindStartupListTagsQuery,
  useInsertStartupListTagMutation,
  useInsertStartupListsStartupListTagMutation,
  useUpdateStartupListTagMutation,
} from 'apollo/generated/sdkShared';
import { Tag, ValueTag } from 'components/base/BaseMultiselect';
import { BaseTagsSelect } from 'components/base/BaseTagsSelect';
import { useGetStartupList } from 'hooks/useGetStartupList';
import { useState } from 'react';

export const StartupListTagsSelect = ({
  startupListId,
}: {
  startupListId: number;
}) => {
  const { data, loading } = useFindStartupListTagsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { startupList } = useGetStartupList({ startupListId });
  const [insertStartupListTags] = useInsertStartupListsStartupListTagMutation();
  const [deleteStartupListTags] = useDeleteStartupListsStartupListTagMutation();

  const [insertTag] = useInsertStartupListTagMutation();
  const [deleteTag] = useDeleteStartupListTagMutation();
  const [updateTag] = useUpdateStartupListTagMutation();

  const startupListTags =
    startupList?.startup_list_tags
      .map(t => ({
        id: t.id,
        name: t?.tag?.name || '',
      }))
      .sort((a, b) => a.id - b.id) || [];

  const [addedTagIds, setAddedTagIds] = useState<number[]>(
    startupListTags?.map(slt => slt.id),
  );

  const options: Tag[] = (data?.startup_list_tags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
  }));

  const value: ValueTag[] = (startupListTags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
    option_id: options.find(o => o.value === t.name)?.id,
  }));

  const onAdd = async (tag_name: string) => {
    const option = options.find(o => o.value === tag_name);
    if (!option) return;

    const res = await insertStartupListTags({
      variables: {
        startup_list_id: startupListId,
        tag_id: option.id as number,
      },
    });

    const startupListTag = res.data?.insert_startup_lists_startup_list_tags_one;
    startupListTag?.id && setAddedTagIds(prev => [...prev, startupListTag.id]);
  };

  const onInsert = async (name: string) => {
    const tag = await insertTag({
      variables: { name },
    });

    const newTag = tag.data?.insert_startup_list_tags_one;
    if (newTag?.id) {
      const res = await insertStartupListTags({
        variables: {
          startup_list_id: startupListId,
          tag_id: newTag.id,
        },
      });

      const startupListTag =
        res.data?.insert_startup_lists_startup_list_tags_one;

      startupListTag?.id &&
        setAddedTagIds(prev => [...prev, startupListTag.id]);
    }
  };

  const onRemove = async (tag: Tag) => {
    await deleteStartupListTags({
      variables: {
        id: tag.id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_startup_lists_startup_list_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'startup_lists_startup_list_tags',
            id: data.delete_startup_lists_startup_list_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onDelete = async (tag_id: number) => {
    await deleteTag({
      variables: {
        id: tag_id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_startup_list_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'startup_list_tags',
            id: data.delete_startup_list_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onUpdate = async (tag_id: number, name: string) => {
    await updateTag({
      variables: {
        id: tag_id,
        name: name,
      },
    });
  };

  return (
    <BaseTagsSelect
      loading={loading}
      options={options}
      value={value}
      onAdd={onAdd}
      onRemove={onRemove}
      // Tag management actions
      onInsert={onInsert}
      onDelete={onDelete}
      onUpdate={onUpdate}
      addedTagIds={addedTagIds}
      sx={{
        width: 'unset',
        maxWidth: '400px',
      }}
    />
  );
};
