import BuUserGuard from 'guards/BuUserGuard';
import { lazy } from 'react';
import { Loadable } from './Loadable';
import { PATH_NAME } from './paths';
import { Navigate } from 'react-router';

const OrganizationPortalAboutPage = Loadable(
  lazy(() => import('pages/organizationPortal/shared/AboutPage')),
);
const OrganizationPortalHomePage = Loadable(
  lazy(() => import('pages/organizationPortal/shared/HomePage')),
);
const StartupListResultPage = Loadable(
  lazy(() => import('pages/dashboard/StartupListResultPage')),
);
const SharedCategoryDetailsPage = Loadable(
  lazy(() => import('pages/shared/SharedCategoryDetailsPage')),
);
const PersonLeadFormPage = Loadable(
  lazy(() => import('pages/PersonLeadFormPage')),
);

const ScoutStartupsPage = Loadable(
  lazy(() => import('pages/dashboard/ScoutStartupsPage')),
);

const SharedProjectDetailsPage = Loadable(
  lazy(() => import('pages/shared/SharedProjectDetailsPage')),
);

const SHARED_ROUTES = [
  {
    path: ':teamSlug/' + PATH_NAME.portalAbout,
    element: (
      <BuUserGuard>
        <OrganizationPortalAboutPage />
      </BuUserGuard>
    ),
  },
  // Backwards compatibility route for about
  {
    path: PATH_NAME.portalAbout,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.portalAbout}`}
        replace
      />
    ),
  },
  {
    path: PATH_NAME.scout,
    element: (
      <BuUserGuard>
        <ScoutStartupsPage />
      </BuUserGuard>
    ),
  },
  {
    path: ':teamSlug/' + PATH_NAME.portalHome,
    element: (
      <BuUserGuard>
        <OrganizationPortalHomePage />
      </BuUserGuard>
    ),
  },
  // Backwards compatibility route for home
  {
    path: PATH_NAME.portalHome,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.portalHome}`}
        replace
      />
    ),
  },
  {
    path: `${PATH_NAME.lists}/:startupListPublicUUID/:shareSlug`,
    element: (
      <BuUserGuard>
        <StartupListResultPage />
      </BuUserGuard>
    ),
  },
  {
    path: `${PATH_NAME.lists}/:startupListPublicUUID/:shareSlug/${PATH_NAME.category}/:categoryId`,
    element: (
      <BuUserGuard>
        <SharedCategoryDetailsPage />
      </BuUserGuard>
    ),
    index: true,
  },
  {
    path: `${PATH_NAME.scope}/:projectPublicUUID`,
    element: (
      <BuUserGuard>
        <SharedProjectDetailsPage />
      </BuUserGuard>
    ),
    index: true,
  },
  // Public
  {
    path: ':teamSlug/' + PATH_NAME.briefUs,
    element: <PersonLeadFormPage />,
  },
  // Backwards compatibility route for brief us
  {
    path: PATH_NAME.briefUs,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.briefUs}`}
        replace
      />
    ),
  },
];

export { SHARED_ROUTES };
