import { Clear } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Stack,
  styled,
  SxProps,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { useGetFeatureFlag } from 'contexts/FeatureFlagsContext';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import {
  forwardRef,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

export const BANNER_HEIGHT = 50;
export const REQUESTS_IN_LEADS_STEP = 'request_in_leads';
export const COPY_REQUEST_FORM_STEP = 'copy_request_form';

type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

const BannerStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  zIndex: 1200,
  height: BANNER_HEIGHT,
}));

const CustomExplainerTooltip = styled(
  forwardRef<HTMLDivElement, TooltipProps>(
    function CustomExplainerTooltipComponent({ className, ...props }, ref) {
      return (
        <Tooltip
          {...props}
          classes={{ popper: className }}
          PopperProps={{ ref }}
        />
      );
    },
  ),
)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 220,
    border: '1px solid #dadde9',
    padding: theme.spacing(1),
    position: 'relative',
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.primary.main,
  },
}));

export const FeaturesUpdateBanner = () => {
  const navigate = useNavigate();
  const { content: bannerContent, updateReleaseTimestamp } =
    useGetUpdatesReleaseBanner();

  const {
    isFeaturesUpdatesBannerVisible,
    setIsFeaturesUpdatesBannerVisible,
    setFeaturesTourNextStep,
  } = useFeaturesUpdatesBanner();

  if (
    !isFeaturesUpdatesBannerVisible ||
    window.localStorage.getItem(updateReleaseTimestamp)
  ) {
    return null;
  }

  const handleDismiss = () => {
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
    setIsFeaturesUpdatesBannerVisible(false);
  };

  const handleTakeALook = () => {
    setFeaturesTourNextStep(REQUESTS_IN_LEADS_STEP);
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
    setIsFeaturesUpdatesBannerVisible(false);
    navigate(PATH_ROOT.projectLeads.list);
  };

  return (
    <BannerStyle>
      {bannerContent}
      <Stack direction='row' gap={1} alignItems='center'>
        <Button
          variant='outlined'
          sx={{
            paddingY: 0.5,
            fontSize: '0.75rem',
            color: 'white',
            border: '1px solid white',
            '&:hover': {
              border: '1px solid white',
            },
          }}
          onClick={handleTakeALook}
        >
          Take a look
        </Button>
        <Button
          sx={{
            color: 'white',
            minWidth: '0 !important',
          }}
          onClick={handleDismiss}
        >
          <Clear fontSize='medium' />
        </Button>
      </Stack>
    </BannerStyle>
  );
};

export const useGetUpdatesReleaseBanner = () => {
  const updateReleaseTimestamp = 'Aug-2024-Requested-Page-Moved';
  const isNewUpdateReleased = useGetFeatureFlag('ff_new_features_released');

  const content = useMemo(
    () => (
      <Stack direction='row' gap={1}>
        <Chip
          label='NEW'
          size='small'
          sx={{
            backgroundColor: 'white',
            color: 'primary.main',
            fontWeight: 800,
            borderRadius: '0.25rem',
          }}
        />
        <Typography variant='body2' fontWeight='800' color='white'>
          Incoming requests now appear in leads!{' '}
          <span style={{ fontWeight: 400 }}>
            The request form is now in the Organization portal.
          </span>
        </Typography>
      </Stack>
    ),
    [],
  );

  const isUpdateReleased =
    isNewUpdateReleased && !window.localStorage.getItem(updateReleaseTimestamp);

  return {
    content,
    updateReleaseTimestamp,
    isNewUpdateReleased: isUpdateReleased,
  };
};

const TooltipContent = ({
  textContent,
  buttonTitle,
  onClick,
}: {
  textContent: string | ReactNode;
  buttonTitle: 'Next' | 'Done';
  onClick: () => void;
}) => (
  <Stack>
    <Typography
      variant='body2'
      fontWeight={600}
      paddingLeft={1}
      fontSize='0.75rem'
      marginBottom='0.35rem'
    >
      {textContent}
    </Typography>
    <Button
      variant='outlined'
      color='primary'
      size='small'
      sx={{
        alignSelf: 'flex-end',
        color: 'white',
        border: '1px solid white',
        '&:hover': {
          border: '1px solid white',
        },
        marginTop: 1,
        marginRight: 1,
        marginBottom: 1,
        paddingY: 0.5,
        fontSize: '0.75rem',
      }}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
    >
      {buttonTitle}
    </Button>
  </Stack>
);

export const TourExplainerTooltip = ({
  textContent,
  children,
  open = false,
  onClick,
  buttonTitle = 'Next',
  placement = 'top',
  sx,
}: {
  textContent: string | ReactNode;
  children: ReactNode;
  open: boolean;
  onClick: () => void;
  buttonTitle: 'Next' | 'Done';
  placement?: TooltipPlacement;
  sx?: SxProps;
}) => {
  const { endFeaturesTour } = useFeaturesUpdatesBanner();
  const [isOpen, setIsOpen] = useState(open);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        endFeaturesTour();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [endFeaturesTour, isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <CustomExplainerTooltip
      ref={tooltipRef}
      placement={placement}
      open={isOpen}
      title={
        <TooltipContent
          textContent={textContent}
          onClick={onClick}
          buttonTitle={buttonTitle}
        ></TooltipContent>
      }
      arrow
      sx={sx}
    >
      <Box
        sx={{
          ...sx,
          height: '100%',
          maxHeight: '100%',
          overflowY: 'auto',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </CustomExplainerTooltip>
  );
};
