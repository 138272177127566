import { Circle, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Menu,
  MenuItem,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PROJECT_CATEGORIES,
  PROJECT_STATUSES,
  STATUS_COLOR_MAPPING,
} from 'components/dashboard/startupList/constants';
import useOverviewFormik, {
  StartupListOverviewFormState,
} from 'components/dashboard/startupList/details/useOverviewFormik';
import { useFormikContext } from 'formik';
import { capitalize, startCase } from 'lodash';
import { MouseEvent, useState } from 'react';

const projectStatusOptions = PROJECT_STATUSES.map(x => {
  return { label: capitalize(x), value: x };
});

export const ProjectStatusField = ({ sx }: { sx?: SxProps }) => {
  const { spacing, palette } = useTheme();
  const { setFieldValue, getFieldProps, values } =
    useFormikContext<StartupListOverviewFormState>();

  const { handleOverviewUpdate } = useOverviewFormik(setFieldValue);

  const handleChange = (value?: string) =>
    handleOverviewUpdate({ fieldName: 'health_status', fieldValue: value });

  return (
    <TextField
      sx={{
        '&: hover': {
          '& fieldset': { backgroundColor: 'grey.50012' },
        },
        '& fieldset': { border: 'none' },
        '& .MuiSelect-select': {
          width: 'auto',
          padding: `${spacing(0.15)} ${spacing(0.15)} !important`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        ...(sx ?? {}),
      }}
      select
      hiddenLabel
      {...getFieldProps('health_status')}
      value={values.health_status || ''}
      onChange={({ target }) => handleChange(target.value)}
      SelectProps={{
        displayEmpty: true,
        IconComponent: () => null,
        renderValue: value => {
          if (!value) {
            return (
              <Circle
                fontSize='medium'
                sx={{
                  color: palette.common.white,
                  border: theme => `0.5px solid ${theme.palette.grey['700']}`,
                  borderRadius: '50%',
                  height: '18px',
                  width: '18px',
                }}
              />
            );
          }
          const color =
            STATUS_COLOR_MAPPING[value as keyof typeof STATUS_COLOR_MAPPING];

          return <Circle fontSize='medium' sx={{ color }} />;
        },
      }}
      variant='outlined'
    >
      {projectStatusOptions.map(({ label, value }) => {
        return (
          <MenuItem key={label} value={value} sx={{ justifyContent: 'center' }}>
            <Circle
              fontSize='small'
              sx={{ color: STATUS_COLOR_MAPPING[value] }}
            />
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export const ProjectCategoryField = ({ sx }: { sx?: SxProps }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { setFieldValue, values } =
    useFormikContext<StartupListOverviewFormState>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { handleOverviewUpdate } = useOverviewFormik(setFieldValue);

  const handleChange = (value?: string) => {
    handleOverviewUpdate({ fieldName: 'project_category', fieldValue: value });
    handleClose();
  };

  return (
    <>
      <Button
        variant='text'
        color='inherit'
        aria-controls={open ? `project-category-${name}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        sx={{
          paddingX: 0,
          fontWeight: 400,
          display: 'flex',
          ...sx,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'left',
          padding: ({ spacing }) => `0 ${spacing(0.5)} !important`,
          '&: hover': {
            backgroundColor: 'grey.50012',
            padding: ({ spacing }) => `0 ${spacing(0.5)} !important`,
            borderRadius: '0.75',
          },
        }}
        onClick={handleClick}
        endIcon={
          open ? (
            <ExpandLess
              sx={{ marginLeft: -1, height: ({ spacing }) => spacing(3.5) }}
            />
          ) : (
            <ExpandMore
              sx={{ marginLeft: -1, height: ({ spacing }) => spacing(3.5) }}
            />
          )
        }
      >
        <Typography
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          fontSize={14}
          width='100%'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: '2.0999',
            color: values.project_category ? '#212b36' : 'grey.600',
            '& hover': { backgroundColor: 'grey.50012' },
          }}
        >
          {values.project_category
            ? startCase(values.project_category)
            : 'None'}
        </Typography>
      </Button>
      <Menu
        aria-labelledby={`project-category-${name}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          selected={!values.project_category}
          onClick={e => {
            e.stopPropagation();
          }}
          sx={{
            fontSize: '14px',
            lineHeight: '1.99',
            color: '#212b36',
            fontWeight: 500,
          }}
        >
          <Typography variant='body2'>None</Typography>
        </MenuItem>
        {PROJECT_CATEGORIES.map(projectCategory => (
          <MenuItem
            sx={{
              fontSize: '14px',
              lineHeight: '1.99',
              color: '#212b36',
              fontWeight: 500,
            }}
            selected={projectCategory === values.project_category}
            key={projectCategory}
            onClick={e => {
              e.stopPropagation();
              handleChange(projectCategory);
            }}
          >
            {startCase(projectCategory)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
