import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { StartupListFeatureBox } from 'components/dashboard/startupList/StartupListFeatureBox';
import { ErrorMessage, Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { Dispatch, SetStateAction, useState } from 'react';
import * as Yup from 'yup';
import { StartupListFeature } from '../../@types/startupList';
import { FieldProps } from '../../pages/request-form/types';
import { FormLabelWithTooltip } from './ProblemField';

const FEATURE_SCHEMA = Yup.object({
  must_have_features: Yup.array().of(Yup.string()).optional(),
  nice_to_have_features: Yup.array().of(Yup.string()).optional(),
});

export type StartupListFeaturesOptionType = {
  name: string;
  mustHave: boolean;
} | null;

type StartupsAndFeaturesFieldSchemaValue = Pick<
  SourcingOrderState,
  'nice_to_have_features' | 'must_have_features'
>;

export type FeatureTypes = keyof StartupsAndFeaturesFieldSchemaValue;

export function FeaturesField(
  props: FieldProps<StartupsAndFeaturesFieldSchemaValue> &
    StartupsAndFeaturesFieldSchemaValue,
) {
  const { errors, setFieldValue, must_have_features, nice_to_have_features } =
    props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFeature, setSelectedFeature] =
    useState<StartupListFeaturesOptionType>(null);

  const addFeature = (
    feature: StartupListFeature[number],
    type: FeatureTypes,
  ) => setFieldValue(type as FeatureTypes, [...props[type], feature]);

  return (
    <>
      {selectedFeature && (
        <FeaturesPopup
          open
          isEditMode={isEditMode}
          onHide={() => {
            setSelectedFeature(null);
            setIsEditMode(false);
          }}
          onSetValues={addFeature}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          setFeaturesFieldValue={setFieldValue}
          must_have_features={must_have_features}
          nice_to_have_features={nice_to_have_features}
        />
      )}
      <FormLabelWithTooltip
        label='Must-Have Features'
        tooltipText='What features should a startup offer? Are there any technical
          requirements or deployment preferences the startup should meet?'
        sx={{ marginBottom: 2 }}
      />
      <Stack direction='row' flexWrap='wrap' marginY={0.75} gap={1}>
        {must_have_features.map(feature => (
          <StartupListFeatureBox
            editModeEnabled
            key={feature}
            mustHave
            onDelete={() => {
              setFieldValue(
                'must_have_features',
                must_have_features.filter(f => f !== feature),
              );
            }}
            featureText={feature}
            onClick={() => {
              setIsEditMode(true);
              setSelectedFeature({ mustHave: true, name: feature });
            }}
          />
        ))}
        {nice_to_have_features.map(feature => (
          <StartupListFeatureBox
            editModeEnabled
            key={feature}
            mustHave={false}
            onDelete={() => {
              setFieldValue(
                'nice_to_have_features',
                nice_to_have_features.filter(f => f !== feature),
              );
            }}
            featureText={feature}
            onClick={() => {
              setIsEditMode(true);
              setSelectedFeature({ mustHave: false, name: feature });
            }}
          />
        ))}
      </Stack>
      <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
        <Button
          variant='contained'
          onClick={() => {
            setSelectedFeature({ mustHave: true, name: '' });
          }}
        >
          + Add a &quot;Must-Have&quot; feature
        </Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => {
            setSelectedFeature({ mustHave: false, name: '' });
          }}
        >
          + Add a &quot;Nice-To-Have&quot; feature
        </Button>
      </Stack>
      {errors.nice_to_have_features && (
        <FormHelperText error={true}>
          <ErrorMessage name='nice_to_have_features' />
        </FormHelperText>
      )}
      {errors.must_have_features && (
        <FormHelperText error={true}>
          <ErrorMessage name='must_have_features' />
        </FormHelperText>
      )}
    </>
  );
}

type FeaturesPopupProps<T> = {
  open: boolean;
  onHide: () => void;
  onSetValues: (
    features: StartupListFeature[number],
    type: FeatureTypes,
  ) => void;
  selectedFeature: StartupListFeaturesOptionType;
  setFeaturesFieldValue: FieldProps<T>['setFieldValue'];
  setSelectedFeature: Dispatch<SetStateAction<StartupListFeaturesOptionType>>;
  isEditMode: boolean;
  handleUpdate?: ({
    fieldName,
    fieldValue,
  }: {
    fieldName: 'must_have_features' | 'nice_to_have_features';
    fieldValue: StartupListFeature;
  }) => void;
};

export function FeaturesPopup(
  props: FeaturesPopupProps<StartupsAndFeaturesFieldSchemaValue> &
    StartupsAndFeaturesFieldSchemaValue,
) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    open,
    onHide,
    onSetValues,
    selectedFeature,
    setSelectedFeature,
    setFeaturesFieldValue,
    isEditMode,
    handleUpdate,
  } = props;
  const isMustHave = selectedFeature?.mustHave;
  const targetArray = isMustHave
    ? 'must_have_features'
    : 'nice_to_have_features';
  const formik = useFormik<{
    name: string;
    mustHave: boolean;
  }>({
    enableReinitialize: true,
    initialValues: {
      name: selectedFeature?.name || '',
      mustHave: selectedFeature ? selectedFeature?.mustHave : true,
    },
    validationSchema: FEATURE_SCHEMA,
    onSubmit: (values, { resetForm }) => {
      try {
        if (isEditMode) {
          const newFeatures = props[targetArray]?.map(x =>
            x === selectedFeature?.name ? values.name : x,
          );

          setFeaturesFieldValue(targetArray, newFeatures);

          if (handleUpdate) {
            handleUpdate({
              fieldName: targetArray,
              fieldValue: newFeatures,
            });
          }
        } else {
          onSetValues(values.name, targetArray);
        }

        resetForm();
        onHide();
        setSelectedFeature(null);
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>
            {`${isEditMode ? 'Edit' : 'Add'} a ${
              isMustHave ? '"Must-Have"' : '"Nice-To-Have"'
            } feature`}
          </DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <TextField
                autoFocus
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                label='Name your features, requirements, wanted technologies, startup locations, or maturity'
                data-testid='features-modal__feature'
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant='text'
              color='inherit'
              onClick={() => {
                setSelectedFeature(null);
                onHide();
              }}
            >
              Cancel
            </Button>
            <Button type='submit' variant='contained'>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
