import { LoadingButtonTypeMap } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  SxProps,
} from '@mui/material';
import { ReactNode } from 'react';

const DEFAULT_CONTENT_SX = {
  paddingBottom: 1,
};

export const BaseDialog = ({
  heading,
  onConfirm,
  onHide,
  confirmCTA = 'Confirm',
  cancelCTA = 'Cancel',
  maxWidth,
  children,
  contentSx = {},
  actionsSx = {},
  confirmColor = 'primary',
}: {
  heading: string | ReactNode;
  onConfirm: () => void;
  onHide: () => void;
  confirmCTA?: string | ReactNode;
  cancelCTA?: string | ReactNode;
  maxWidth?: 'md' | 'xs' | 'xl' | 'sm' | 'lg';
  children: ReactNode;
  contentSx?: SxProps;
  actionsSx?: SxProps;
  loading?: boolean;
  confirmColor?: LoadingButtonTypeMap['props']['color'];
  disableConfirmButton?: boolean;
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // FIXME: This is temporary fix to prevent SidePanel navigation to move between startups when a modal is opened on top of it.
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.stopPropagation();
    }
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth={maxWidth}
      onClose={onHide}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle sx={{ marginBottom: 3 }}>{heading}</DialogTitle>
      <DialogContent sx={{ ...DEFAULT_CONTENT_SX, ...contentSx }}>
        {children}
      </DialogContent>
      <DialogActions sx={actionsSx}>
        {cancelCTA && (
          <Button variant='text' color='inherit' onClick={onHide}>
            {cancelCTA}
          </Button>
        )}
        {confirmCTA && (
          <Button onClick={onConfirm} variant='contained' color={confirmColor}>
            {confirmCTA}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
