// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { createRoot } from 'react-dom/client';

// material
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
// components

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/Auth0Context';

//
import App from './App';
import reportWebVitals from './reportWebVitals';

// Install MUI pro license key
import { LicenseInfo } from '@mui/x-license-pro';
import { OrganizationUUIDProvider } from 'contexts/OrganizationUUIDContext';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE_KEY);

// ---------------------------------------------------------------------- \\
Sentry.init({
  // https://forum.sentry.io/t/dsn-private-public/6297
  // DSN does not need to be a secret
  dsn: 'https://98b3ef1ee3594a2ea3edf559fbff7cd2@o1058212.ingest.sentry.io/6045676',
  environment: import.meta.env.VITE_ENV,
  beforeBreadcrumb: excludeGraphQLFetch,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: import.meta.env.VITE_ENV === 'production' ? 0.1 : 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: import.meta.env.VITE_ENV === 'production' ? 1 : 0,
  integrations: [
    new Sentry.Replay(),
    new Integrations.BrowserTracing({
      tracingOrigins: [/.+\.localhost/, /.*\.glassdollar\.com/],
    }),
    new Sentry.Integrations.Breadcrumbs({
      // Prevent all logs from showing as originated from instruments.js https://github.com/getsentry/sentry-javascript/issues/2765
      console: import.meta.env.VITE_ENV !== 'development',
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.PROD ? 0.3 : 1.0,
});

if (import.meta.env.VITE_ENV === 'development') {
  // @ts-expect-error Monkey patching this to prevent annoying Warnings in development
  console.originalError = console.error;
  console.error = function (...params) {
    const message = params.shift();
    const consoleError = message.startsWith('Warning: ')
      ? console.warn
      : // @ts-expect-error Monkey patching this to prevent annoying Warnings in development
        console.originalError;
    consoleError(message, ...params);
  };
}

// ---------------------------------------------------------------------- \\

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CollapseDrawerProvider>
        <BrowserRouter>
          <OrganizationUUIDProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </OrganizationUUIDProvider>
        </BrowserRouter>
      </CollapseDrawerProvider>
    </LocalizationProvider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
