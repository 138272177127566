import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import {
  useGetProjectStageDataLazyQuery,
  useUpdateProjectStageMutation,
  useUpsertProjectStageMutation,
} from 'apollo/generated/sdkShared';
import { useSafeCallback } from 'components/base/useSafeCallback';
import { useGetRelatedStartupsLazy } from 'components/PoCs/hooks/useGetRelatedStartups';
import useAlternativeNavigate from 'hooks/useAlternativeNavigate';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { PATH_ROOT } from 'routes/paths';
import {
  canMoveToStage,
  DECORATED_STAGE_MAPPING,
} from 'utils/projectStageEnum';
import { commentSupportedStages } from '../ProjectStageChangeDialog';
import { captureAnalyticsEvent } from 'plugins/Analytics';

type Props = {
  projectId: number;
  fromStage: EnumTableProjectStagesEnum;
  toStage: EnumTableProjectStagesEnum;
};

export const useChangeProjectStage = (displaySnackbar = true) => {
  const { user } = useAuth();
  const { navigateTo } = useAlternativeNavigate();
  const [upsertProjectStage] = useUpsertProjectStageMutation();
  const [updateStage] = useUpdateProjectStageMutation();
  const getRelatedStartupsLazy = useGetRelatedStartupsLazy();
  const { enqueueSnackbar } = useSnackbar();
  const [getProjectStage] = useGetProjectStageDataLazyQuery();
  const verifyStageMove = async ({
    fromStage,
    toStage,
    projectId,
  }: {
    toStage: EnumTableProjectStagesEnum;
    projectId: number;
    fromStage: EnumTableProjectStagesEnum;
  }) => {
    const { selectedStartupForPoC } = await getRelatedStartupsLazy(projectId);

    const [canMove, message] = canMoveToStage(
      fromStage,
      toStage,
      selectedStartupForPoC,
    );
    if (!canMove) {
      enqueueSnackbar(message, { variant: 'warning' });
      return false;
    }

    return true;
  };

  const submit = useSafeCallback(
    async ({
      comment,
      toStage,
      projectId,
      fromStage,
    }: {
      comment?: string | null;
      toStage: EnumTableProjectStagesEnum;
      projectId: number;
      fromStage: EnumTableProjectStagesEnum;
    }) => {
      // Complete current stage
      await upsertProjectStage({
        variables: {
          object: {
            comment,
            project_id: projectId,
            stage: fromStage,
            user_id: user.id,
            completed_at: new Date().toISOString(),
          },
          onConflict: {
            constraint: 'project_stages_project_id_stage_key',
            update_columns: ['completed_at', 'user_id', 'comment'],
          },
        },
      });

      // Upsert new stage
      await upsertProjectStage({
        variables: {
          object: {
            project_id: projectId,
            stage: toStage,
            user_id: user.id,
          },
          onConflict: {
            constraint: 'project_stages_project_id_stage_key',
            update_columns: [],
          },
        },
      });

      // Update project stage
      await updateStage({
        variables: { id: projectId, nextStage: toStage },
        optimisticResponse: {
          update_projects_by_pk: {
            __typename: 'projects',
            id: projectId,
            stage: toStage,
          },
        },
      });

      if (displaySnackbar) {
        enqueueSnackbar(
          `Stage updated to ${DECORATED_STAGE_MAPPING[toStage]}`,
          {
            variant: 'success',
          },
        );
      }

      captureAnalyticsEvent('Project Stage Changed', {
        currentStage: fromStage,
        nextStage: toStage,
        projectId: projectId,
      });

      return true;
    },

    [
      displaySnackbar,
      enqueueSnackbar,
      updateStage,
      upsertProjectStage,
      user.id,
    ],
  );

  const handleChangeStage = async ({
    projectId,
    fromStage,
    toStage,
  }: Props) => {
    const canMove = await verifyStageMove({
      fromStage,
      toStage,
      projectId,
    });

    const { data } = await getProjectStage({
      variables: { projectId, stage: fromStage },
      fetchPolicy: 'cache-and-network',
    });

    if (!canMove) return;

    const currentComment = data?.project_stages[0]?.comment;

    const shouldTriggerModal =
      commentSupportedStages.includes(fromStage) && !currentComment;

    if (shouldTriggerModal) {
      navigateTo(
        PATH_ROOT.changeProjectStage.dialog(projectId, fromStage, toStage),
      );
    } else {
      return await submit({
        toStage,
        fromStage,
        projectId,
        comment: currentComment || null,
      });
    }
  };

  return { handleChangeStage, handleSubmitChangeStage: submit };
};
