import { useGetAllSharedListLinksQuery } from 'apollo/generated/sdkShared';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { useOrganizationPortalContext } from '../contexts/OrganizationPortalContext';
import { HomePageState } from '../useUpdateHomePageState';

type AGGREGATED_LINK_DATA = {
  id: number;
  listId: number;
  listTitle: string;
  visitsCount: number;
  uniqueVisitsCount: number;
};

// When changing this, a data migration is also required to update the current shared_project_links
// target_group_slug that match the current value to the new value
export const DEFAULT_LINK_TARGET_GROUP_SLUG = 'default';

export const usePortalAnalytics = () => {
  const { currentPortalConfig: config } = useOrganizationPortalContext();
  const allListIds = useMemo(() => {
    const homePageState = (config?.home_page_state.sections ||
      {}) as HomePageState['sections']['lists'];

    return Object.values(homePageState?.section_items || [])
      .flat(1)
      .filter(id => id > 0);
  }, [config]);

  const { data: sharedLinksData } = useGetAllSharedListLinksQuery({
    variables: {
      where: {
        startup_list_id: { _in: allListIds },
        target_group_slug: { _in: [DEFAULT_LINK_TARGET_GROUP_SLUG] },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const links = (
    sharedLinksData?.shared_project_links.map(link => {
      // FIXME: we have shared links with "organization_id" different than their "project.organization_id"
      // this has to be fixed on Data level, but this is the current workaround
      // https://glassdollar.slack.com/archives/C02566YJBGX/p1696422321059859
      if (!link.startup_list) return null;

      return {
        id: link.id,
        listId: link.startup_list_id,
        // !Warning: the "?." is required or it will cause a crash
        listTitle: link.startup_list?.title || '',
        visitsCount: link.visits_count,
        uniqueVisitsCount: link.unique_visits_count,
      };
    }) || []
  ).filter(Boolean) as AGGREGATED_LINK_DATA[];

  const viewsStatistics = sharedLinksData && {
    links,
    totalVisits: sum(links?.map(link => link.visitsCount) || []),
    uniqueVisits: sum(links?.map(link => link.uniqueVisitsCount) || []),
  };

  return {
    viewsStatistics,
  };
};
