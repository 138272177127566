import { captureException } from '@sentry/react';
import {
  Files,
  GetPersonNotesDocument,
  useCreatePersonNoteMutation,
  useDeleteFileMutation,
  useDeletePersonNoteMutation,
  useFilesWithSignedUrlsQuery,
  useGetPersonNotesQuery,
  useInsertPersonNotesFilesMutation,
} from 'apollo/generated/sdkShared';
import { FileError } from 'errors';
import useFiles from 'hooks/useFiles';
import { trim } from 'lodash';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo, useState } from 'react';
import { HandleNewNoteFunction, Note, NotesSection } from './NotesSection';

type UploadedFile = Pick<Files, 'id' | 'name'>;

export const PersonNotesSection = ({ personId }: { personId: number }) => {
  const { notes, loading } = usePersonNotes(personId);
  const NotesAndDocumentsRefetchQueries = [
    {
      query: GetPersonNotesDocument,
      variables: { personId },
    },
  ];
  const { uploadFile } = useFiles({ filePrefix: 'person_documents' });
  const { enqueueSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState<boolean>(false);
  const [insertPersonNotesFiles] = useInsertPersonNotesFilesMutation({
    refetchQueries: NotesAndDocumentsRefetchQueries,
  });

  const [createPersonNote, { loading: creatingNoteInProgress }] =
    useCreatePersonNoteMutation({
      refetchQueries: NotesAndDocumentsRefetchQueries,
    });

  const [deletePersonNote] = useDeletePersonNoteMutation({
    refetchQueries: NotesAndDocumentsRefetchQueries,
  });

  const [_deleteFile] = useDeleteFileMutation({
    refetchQueries: NotesAndDocumentsRefetchQueries,
  });

  const deleteNote = async (noteId: number) => {
    const noteToDelete = notes.find(n => n.id === noteId);

    if (!noteToDelete) return;

    try {
      const attachments = noteToDelete.attachments;

      if (Array.isArray(attachments)) {
        await Promise.all(
          attachments.map(async attachment => {
            const success = await deleteFile(attachment.file_id);
            if (!success) {
              console.error(
                `Failed to delete file with ID: ${attachment.file_id}`,
              );
            }
          }),
        );
      }

      await deletePersonNote({ variables: { noteId } });
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error deleting note', {
        variant: 'error',
      });
    }
  };

  const deleteFile = async (fileId: number) => {
    try {
      await _deleteFile({
        variables: {
          fileId: fileId,
        },
      });
      return true;
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error deleting note attachments', {
        variant: 'error',
      });
      return false;
    }
  };

  const handleCreatePersonNote = async (body: string) => {
    const personNotesResponse = await createPersonNote({
      variables: {
        body: body,
        personId,
      },
    });

    const noteId = personNotesResponse.data?.insert_person_notes_one?.id;
    captureAnalyticsEvent('Person Note Added', {
      personId,
    });
    return noteId!;
  };

  const handleNewNoteSubmit: HandleNewNoteFunction = async ({
    noteBody,
    selectedFiles,
  }) => {
    const normalizedNoteBody = trim(noteBody);
    if (!normalizedNoteBody) {
      return;
    }

    try {
      const createdNoteId = await handleCreatePersonNote(normalizedNoteBody);

      if (selectedFiles) {
        setUploading(true);
        const uploadedFiles: UploadedFile[] = [];

        for (let i = 0; i < selectedFiles.length; i++) {
          try {
            const uploadedFile = await uploadFile(selectedFiles[i], 50);
            uploadedFiles.push(uploadedFile);
          } catch (error) {
            if (error instanceof FileError) {
              enqueueSnackbar(error.message, {
                variant: 'error',
              });
            } else {
              captureException(error);
              enqueueSnackbar('Error uploading file', {
                variant: 'error',
              });
            }
          }
        }

        if (uploadedFiles.length > 0)
          await insertPersonNotesFiles({
            variables: {
              personNotesFiles: uploadedFiles.map(f => ({
                file_id: f.id,
                person_note_id: createdNoteId,
              })),
            },
          });

        setUploading(false);
      }
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Failed submitting the note', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      {/* <Typography variant='h5' fontWeight={500}>
        Notes
      </Typography> */}
      <NotesSection
        quilId={`person-notes-${personId}`}
        notes={notes}
        loading={loading}
        handleNewNote={handleNewNoteSubmit}
        uploading={uploading}
        creatingNoteInProgress={creatingNoteInProgress}
        deleteNote={deleteNote}
        isPersonNotes
      />
    </>
  );
};

const usePersonNotes = (personId: number) => {
  const { data: notesData, loading: notesLoading } = useGetPersonNotesQuery({
    variables: { personId },
  });

  console.log({ notesData });

  const fileIds = useMemo(
    () =>
      notesData?.person_notes.flatMap(pn =>
        pn.attachments.map(a => a.file_id),
      ) || [],
    [notesData?.person_notes],
  );

  const { data: filesWithSignedUrlsData, loading: loadingFilesWithSignedUrls } =
    useFilesWithSignedUrlsQuery({
      variables: {
        files_ids: fileIds!,
      },
      skip: fileIds.length === 0,
    });

  const loading = useMemo(() => {
    if (fileIds.length === 0) {
      // If there are no attachments, only care about notesLoading
      return notesLoading;
    } else {
      // If there are attachments, wait for both notesLoading and loadingFilesWithSignedUrls to be false
      return notesLoading || loadingFilesWithSignedUrls;
    }
  }, [notesLoading, loadingFilesWithSignedUrls, fileIds.length]);

  const filesWithSignedUrls = useMemo(
    () => filesWithSignedUrlsData?.files_with_signed_urls?.data || [],
    [filesWithSignedUrlsData?.files_with_signed_urls?.data],
  );

  const signedUrlByFileId = useMemo(() => {
    return filesWithSignedUrls.reduce<Record<number, string>>((acc, file) => {
      acc[file.id] = file.signed_url;
      return acc;
    }, {});
  }, [filesWithSignedUrls]);

  const notes: Note[] = useMemo(
    () =>
      !notesData
        ? []
        : notesData.person_notes.map(note => ({
            ...note,
            attachments: note.attachments.map(a => ({
              ...a,
              url: signedUrlByFileId[a.file_id],
              name: a.file.name,
            })),
            DEPRECATED_attachment_urls: [],
          })),
    [notesData, signedUrlByFileId],
  );

  return {
    notes,
    loading,
  };
};
