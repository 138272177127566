// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// components
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary, captureException, setUser } from '@sentry/react';
import { ServerFiltersProvider } from 'components/base/serverFilters/BaseServerFiltersContext';
import { CorporationsDomainProvider } from 'contexts/CorporationsDomainProvider';
import { CurrentOrganizationContextProvider } from 'contexts/CurrentOrganizationContext';
import { FeatureFlagsContextProvider } from 'contexts/FeatureFlagsContext';
import { FullscreenContextProvider } from 'contexts/FullscreenContext';
import { NotesContextProvider } from 'contexts/NotesContext';
import { PersonProvider } from 'contexts/PersonContext';
import { SettingsContextProvider } from 'contexts/SettingsContext';
import { SharedPagesProvider } from 'layouts/SharedPagesLayout';
import { ConfirmProvider } from 'material-ui-confirm';
import Page500 from 'pages/Page500';
import { useEffect } from 'react';
import { useApolloClientDoNotUse } from './apollo/apolloClient';
import NotistackProvider from './components/NotistackProvider';
import ThemeLocalization from './components/ThemeLocalization';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import './index.css';
import { getOrganizationSubdomain } from 'utils/general';
import { ViewingOrganizationPortalAsProvider } from 'components/OrganizationPortal/contexts/ViewingPortalAsContext';
import { FeaturesUpdatesBannerProvider } from 'contexts/FeaturesUpdatesBannerContext';

// ----------------------------------------------------------------------

const handleErrorBoundaryOnMount = () => {
  window.onunhandledrejection = (rejectionEvent: PromiseRejectionEvent) => {
    captureException(rejectionEvent.reason);
  };
};

export default function App() {
  const { isInitialized, isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (isInitialized) {
      const subdomain = getOrganizationSubdomain();
      if (isAuthenticated) {
        setUser({
          id: user.id,
          email: user.email,
          organizationId: user.organizationId,
          organizationSubdomain: subdomain,
        });
      } else {
        setUser({
          organizationSubdomain: subdomain,
        });
      }
    }
  }, [isAuthenticated, user, isInitialized]);

  const apolloClient = useApolloClientDoNotUse();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const error = urlParams.get('error');
  const errorDescription = urlParams.get('error_description');

  useEffect(() => {
    if (error) {
      captureException({ error, errorDescription });
      console.log({ error, errorDescription });
      alert(errorDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <ConfirmProvider>
            <ErrorBoundary
              fallback={() => <Page500 embedded />}
              showDialog
              onMount={handleErrorBoundaryOnMount}
            >
              <ApolloProvider client={apolloClient}>
                <FeatureFlagsContextProvider>
                  <FullscreenContextProvider>
                    <CurrentOrganizationContextProvider>
                      <SharedPagesProvider>
                        <SettingsContextProvider>
                          <CorporationsDomainProvider>
                            <NotistackProvider>
                              <PersonProvider>
                                <NotesContextProvider>
                                  <FeaturesUpdatesBannerProvider>
                                    <ViewingOrganizationPortalAsProvider>
                                      <ServerFiltersProvider>
                                        <Router />
                                      </ServerFiltersProvider>
                                    </ViewingOrganizationPortalAsProvider>
                                  </FeaturesUpdatesBannerProvider>
                                </NotesContextProvider>
                              </PersonProvider>
                            </NotistackProvider>
                          </CorporationsDomainProvider>
                        </SettingsContextProvider>
                      </SharedPagesProvider>
                    </CurrentOrganizationContextProvider>
                  </FullscreenContextProvider>
                </FeatureFlagsContextProvider>
              </ApolloProvider>
            </ErrorBoundary>
          </ConfirmProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
