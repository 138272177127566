import {
  Box,
  Button,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { DEFAULT_COLLAPSE_MIN_HEIGHT } from 'components/dashboard/CollapsedScopeAndDescription';
import {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const STANDARD_LINE_HEIGHT = 25;

type CollapsibleContentProps = {
  isOpen: boolean;
  onToggle: () => void;
  collapsedSize: string;
  children: ReactNode;
  allowEdit?: boolean;
  onEdit?: () => void;
  alwaysExpanded: boolean;
  editTitle?: string;
};

type ShowMoreOrLessProps = {
  content: string | JSX.Element;
  editDialogContent?: string | null | JSX.Element;
  onOpenEditDialog?: Dispatch<SetStateAction<boolean>>;
  editTitle?: string;
  alwaysExpanded?: boolean;
  collapsedSize?: string;
  allowEdit?: boolean;
  nonAllowEditText?: string;
};

type ShowMoreActionButtonProps = {
  title: string;
  open?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  onMouseUp?: MouseEventHandler<HTMLButtonElement> | undefined;
};

export const contentIsCollapsible = (
  content: string | null | undefined | JSX.Element,
  contentHeight: number,
) => content && contentHeight > DEFAULT_COLLAPSE_MIN_HEIGHT;

export const BaseShowMoreOrLess = ({
  content,
  editDialogContent,
  onOpenEditDialog,
  editTitle = 'Edit',
  alwaysExpanded = false,
  collapsedSize = `${DEFAULT_COLLAPSE_MIN_HEIGHT}px`,
  allowEdit = true,
  nonAllowEditText = '',
}: ShowMoreOrLessProps) => {
  const [contentHeight, onContentHeightRefChange] = useContentHeight();
  const [openContent, setOpenContent] = useState(false);

  const memoedContent = useMemo(
    () => <Box ref={onContentHeightRefChange}>{content}</Box>,
    [onContentHeightRefChange, content],
  );

  return (
    <>
      <Box>
        {contentIsCollapsible(memoedContent, contentHeight) ? (
          <CollapsibleContent
            collapsedSize={collapsedSize}
            isOpen={openContent}
            onToggle={() => setOpenContent(prev => !prev)}
            alwaysExpanded={alwaysExpanded}
            editTitle={editTitle}
            onEdit={() => onOpenEditDialog && onOpenEditDialog(true)}
            allowEdit={allowEdit}
          >
            {memoedContent}
          </CollapsibleContent>
        ) : (
          <>
            {memoedContent}
            {allowEdit ? (
              editDialogContent && (
                <ShowMoreActionButton
                  onClick={() => onOpenEditDialog && onOpenEditDialog(true)}
                  title={editTitle}
                />
              )
            ) : (
              <>
                {!memoedContent && (
                  <Typography variant='caption'>{nonAllowEditText}</Typography>
                )}
              </>
            )}
          </>
        )}
        {editDialogContent && editDialogContent}
      </Box>
    </>
  );
};

export const CollapsibleContent = ({
  isOpen,
  onToggle,
  collapsedSize,
  children,
  allowEdit = false,
  onEdit,
  alwaysExpanded,
  editTitle = 'Edit',
}: CollapsibleContentProps) => {
  const clampLength = useMemo(() => {
    const collapsedSizeInt = parseInt(collapsedSize);
    return Math.floor(collapsedSizeInt / STANDARD_LINE_HEIGHT);
  }, [collapsedSize]);

  return (
    <Box>
      <SimpleCollapse
        isOpen={isOpen}
        collapsedSize={collapsedSize}
        lineClamp={clampLength}
      >
        {children}
      </SimpleCollapse>
      {!alwaysExpanded && (
        <Stack direction='row' gap={2}>
          <ShowMoreActionButton
            open={isOpen}
            onMouseUp={e => {
              e.stopPropagation();
              onToggle();
            }}
            title={`Show ${isOpen ? 'less' : 'more'}`}
          />
          {allowEdit && (
            <ShowMoreActionButton onClick={onEdit} title={editTitle} />
          )}
        </Stack>
      )}
    </Box>
  );
};

const ShowMoreActionButton = ({
  title,
  sx,
  onClick,
  onMouseUp,
}: ShowMoreActionButtonProps) => {
  const theme = useTheme();
  const buttonStyles = {
    marginTop: 0,
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: 0,
    paddingX: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    ...sx,
  };

  return (
    <Button
      sx={buttonStyles}
      onClick={onClick}
      onMouseUp={onMouseUp}
      size='small'
      variant='text'
    >
      {title}
    </Button>
  );
};

export const useContentHeight = (
  initialHeight: number = 0,
): [number, (node: HTMLDivElement | null) => void] => {
  const [contentHeight, setContentHeight] = useState(initialHeight);

  const onRefChange = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      const updateHeight = () => {
        const height = node.getBoundingClientRect().height;
        setContentHeight(prevHeight =>
          height !== prevHeight ? height : prevHeight,
        );
      };
      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      resizeObserver.observe(node);
      updateHeight();

      // Cleanup function to unobserve the node
      return () => {
        resizeObserver.unobserve(node);
      };
    }
  }, []);

  return [contentHeight, onRefChange];
};

type SimpleCollapseProps = {
  isOpen: boolean;
  collapsedSize?: string;
  lineClamp?: number;
  children: ReactNode;
};

export const SimpleCollapse = ({
  isOpen,
  collapsedSize = '100px',
  lineClamp = 4,
  children,
}: SimpleCollapseProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<string>(collapsedSize);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const newHeight = isOpen ? `${contentHeight}px` : collapsedSize;
      if (height !== newHeight) {
        setHeight(newHeight);
      }
    }
  }, [isOpen, collapsedSize, height]);

  return (
    <Box
      ref={contentRef}
      sx={{
        height: height,
        overflow: 'hidden',
        transition: 'height 0.3s ease',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          ...(isOpen
            ? {}
            : {
                height: collapsedSize,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: lineClamp, // Number of lines to show before truncating
                WebkitBoxOrient: 'vertical',
                position: 'relative',
                maxWidth: '100%',
              }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
