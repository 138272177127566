import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Markdown from 'components/Markdown';

const ScoutingCommentModal = ({
  isOpen,
  onHide,
  scoutingComment,
}: {
  isOpen: boolean;
  onHide: () => void;
  scoutingComment: string;
}) => {
  return (
    <Dialog open={isOpen} fullWidth onClose={onHide}>
      <DialogTitle sx={{ padding: '14px 24px 10px' }}>
        Sourcing comment
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '14px' }}>
        <Typography component='div'>
          <Markdown>{scoutingComment}</Markdown>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ScoutingCommentModal;
