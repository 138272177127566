import {
  Button,
  Stack,
  Box,
  useTheme,
  ButtonProps,
  BoxProps,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const FeatureBox = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ mustHave, ...props }: BoxProps & { mustHave?: boolean }) => (
    <Box {...props} />
  ),
)(({ theme: { palette, spacing }, mustHave }) => ({
  borderRadius: spacing(1),
  wordBreak: 'break-word',
  padding: spacing(0.5),
  fontSize: '0.875rem',
  border: `1px solid ${mustHave ? palette.primary.main : palette.info.main}`,
  color: mustHave ? palette.primary.main : palette.info.main,
}));

const CloseButton = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ mustHave, ...props }: ButtonProps & { mustHave?: boolean }) => (
    <Button {...props} />
  ),
)(({ theme: { palette }, mustHave }) => ({
  borderRadius: '50%',
  background: mustHave ? palette.primary.main : palette.info.main,
  minWidth: '10px',
  padding: 0,
  marginLeft: 16,
  width: '18px',
  height: '18px',
  '&:hover': {
    ...(!mustHave && { background: palette.info.dark }),
  },
}));

export function StartupListFeatureBox({
  editModeEnabled,
  mustHave,
  featureText,
  onDelete,
  onClick,
}: {
  editModeEnabled?: boolean;
  mustHave: boolean;
  featureText: string;
  onDelete?: () => void;
  onClick?: () => void;
}) {
  const theme = useTheme();

  return (
    <FeatureBox
      mustHave={mustHave}
      theme={theme}
      sx={
        editModeEnabled
          ? {
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                boxShadow: theme.shadows[10],
              },
            }
          : {}
      }
      onClick={onClick}
    >
      <Stack direction='row' alignItems='center' gap={0.5}>
        {mustHave && <StarIcon color='primary' />}
        <Box sx={{ paddingY: 0.2 }}>{featureText}</Box>
        {editModeEnabled && (
          <CloseButton
            theme={theme}
            mustHave={mustHave}
            variant='contained'
            onClick={e => {
              e.stopPropagation();
              onDelete?.();
            }}
          >
            <CloseIcon
              sx={{
                color: theme.palette.grey[0],
                padding: 0.8,
                fontWeight: 700,
              }}
            />
          </CloseButton>
        )}
      </Stack>
    </FeatureBox>
  );
}
