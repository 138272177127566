import {
  Button,
  Fade,
  Skeleton,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Theme, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  EnumTableEntityVisibilityEnum,
  SharedProjectLinksSumFields,
  StartupLists,
  Teams,
} from 'apollo/generated/sdkInnovationManager';
import {
  Maybe,
  People,
  SharedProjectLinks,
  SourcingOrders,
  Users,
  useGetStartupListForShareQuery,
} from 'apollo/generated/sdkShared';
import { TotalViews } from 'components/TotalViews';
import { useMemo, useState } from 'react';
import { ShareVisibilityIcon } from '../Permissions';
import AvatarViews from './AvatarViews';
import NoViews from './NoViews';
import ShareStartupListModal from './ShareStartupListModal';

export type UserWithPerson = Pick<Users, 'id'> & {
  person?: Maybe<Pick<People, 'id' | 'full_name' | 'email'>>;
};

export type StartupListForShare = Pick<
  StartupLists,
  | 'id'
  | 'title'
  | 'public_uuid'
  | 'source'
  | '__typename'
  | 'visibility'
  | 'created_by_user_id'
> & {
  project?: Maybe<{ id: number; title: string }>;
  team?: Maybe<
    Pick<Teams, 'id' | 'name'> & {
      people?: Maybe<Pick<People, 'id' | 'full_name' | 'email'>>[];
    }
  >;
  sourcing_order?:
    | (Pick<SourcingOrders, 'id' | 'delivered_date'> & {
        created_by?: Maybe<UserWithPerson>;
      })
    | null;
  shared_project_links?:
    | Pick<
        SharedProjectLinks,
        | 'id'
        | 'target_group_slug'
        | 'visits_count'
        | 'unique_visits_count'
        | 'last_visited_at'
        | 'created_at'
      >[]
    | null;
  shared_project_links_aggregate?: {
    aggregate?: {
      sum?: SharedProjectLinksSumFields | null;
    } | null;
  } | null;
};

const useStyles = makeStyles(({ palette, transitions }: Theme) => ({
  button: {
    animation: `$pulsate 2s ${transitions.easing.easeInOut} 0s 3 normal forwards`,
    animationName: `$pulsate, $pulsate, $pulsate`,
    animationDuration: '2s, 2s, 2s',
    animationIterationCount: '1, 1, 1',
    animationDelay: '3s, 10s, 60s',
  },
  '@keyframes pulsate': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(palette.primary.main, 0.4)}`,
    },
    '50%': {
      boxShadow: `0 0 0 10px ${alpha(palette.primary.main, 0)}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${alpha(palette.primary.main, 0)}`,
    },
  },
}));

const ShareButton = ({
  disabled,
  numberOfShares,
  viewsCount,
  visibility,
  setShareModalOpen,
}: {
  disabled: boolean;
  numberOfShares: number;
  viewsCount: number;
  visibility: EnumTableEntityVisibilityEnum;
  setShareModalOpen: (_toOpen: boolean) => void;
}) => {
  const { palette } = useTheme();
  const pulseAnimation = useStyles();
  const shouldPulsate = useMemo(
    () => !disabled && (numberOfShares === 0 || viewsCount === 0),
    [disabled, numberOfShares, viewsCount],
  );

  return (
    <Tooltip
      title={
        disabled
          ? 'Order a sourcing or add startups manually before sharing'
          : ''
      }
    >
      <span>
        <Button
          data-testid={`share-button-${visibility}`}
          className={shouldPulsate ? pulseAnimation.button : ''}
          variant='outlined'
          disabled={disabled}
          startIcon={
            <ShareVisibilityIcon
              visibility={visibility}
              cssProps={{ fill: palette.primary.main }}
            />
          }
          sx={{ whiteSpace: 'nowrap' }}
          onClick={() => setShareModalOpen(true)}
        >
          Share
        </Button>
      </span>
    </Tooltip>
  );
};

export const ShareStartupList = ({
  currentStartupListId,
  startupsCount,
}: {
  currentStartupListId: number;
  startupsCount: number;
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const disabled = startupsCount === 0;
  const { data, loading } = useGetStartupListForShareQuery({
    variables: {
      startupListId: currentStartupListId,
    },
  });
  const shares = useMemo(
    () => data?.startup_lists_by_pk?.shared_project_links || [],
    [data?.startup_lists_by_pk?.shared_project_links],
  );
  const uniqueViewsCount = useMemo(
    () =>
      data?.startup_lists_by_pk?.shared_project_links_aggregate?.aggregate?.sum
        ?.unique_visits_count || 0,
    [
      data?.startup_lists_by_pk?.shared_project_links_aggregate?.aggregate?.sum
        ?.unique_visits_count,
    ],
  );

  if (loading || !data?.startup_lists_by_pk) {
    return (
      <Stack direction='row' gap={1}>
        <Skeleton variant='rounded' width={60} height={40} />
        <Skeleton variant='rounded' width={60} height={40} />
      </Stack>
    );
  }

  const currentStartupList = data.startup_lists_by_pk;
  const uniqueViewEmails = Array.from(
    new Set(shares.map(s => (s.visitor_emails as string[]) || []).flat()),
  );

  return (
    <Fade in>
      <Stack direction='row' alignItems='center' gap={1}>
        {uniqueViewsCount === 0 && (
          <NoViews
            currentStartupList={currentStartupList}
            disabled={disabled}
          />
        )}
        {uniqueViewsCount > 0 && uniqueViewsCount <= 4 && (
          <AvatarViews uniqueViewEmails={uniqueViewEmails} />
        )}
        {uniqueViewsCount > 4 && (
          <TotalViews
            onClick={() => setShareModalOpen(true)}
            viewsCount={uniqueViewsCount}
          />
        )}
        <ShareButton
          disabled={disabled}
          numberOfShares={shares.length}
          viewsCount={uniqueViewsCount}
          setShareModalOpen={setShareModalOpen}
          visibility={currentStartupList.visibility}
        />
        <ShareStartupListModal
          currentStartupList={currentStartupList}
          isOpen={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          shares={shares}
        />
      </Stack>
    </Fade>
  );
};
