import { Page, Text, View } from '@react-pdf/renderer';
import { chunk } from 'lodash';
import { CategoryCard } from './CategoryCard';
import { Pager } from './Pager';
import { styles } from './styles';
import { StartupLists } from 'apollo/generated/sdkInnovationManager';
import { CategoryForPDFExport } from './types';

const MAX_CATEGORIES_PER_PAGE = 3;

type StartupListForPDFCategoriesPageGroup = Pick<
  StartupLists,
  'id' | 'title' | 'public_uuid'
>;

export const CategoriesPageGroup = ({
  startupList,
  categories,
}: {
  startupList: StartupListForPDFCategoriesPageGroup;
  categories: CategoryForPDFExport[];
}) => {
  return (
    <>
      {chunk(categories, MAX_CATEGORIES_PER_PAGE).map(
        (categoriesSlice, sliceIndex) => (
          <CategoriesCardsPage
            key={sliceIndex}
            categoriesSlice={categoriesSlice}
            startupList={startupList}
            landscapePageNumber={sliceIndex + 1}
            hasMultiplePages={categories.length > MAX_CATEGORIES_PER_PAGE}
          />
        ),
      )}
    </>
  );
};

const CategoriesCardsPage = ({
  categoriesSlice,
  landscapePageNumber,
  startupList,
  hasMultiplePages,
}: {
  categoriesSlice: CategoryForPDFExport[];
  landscapePageNumber: number;
  startupList: StartupListForPDFCategoriesPageGroup;
  hasMultiplePages: boolean;
}) => {
  return (
    <Page size='A4' orientation='landscape' style={styles.contentPage}>
      <Pager />
      <View>
        <View
          fixed
          style={{
            ...styles.pageTitle,
            marginBottom: 48,
          }}
        >
          <Text>
            {startupList.title}{' '}
            {hasMultiplePages ? (
              <Text
                style={{
                  fontFamily: 'Helvetica-Oblique',
                  fontStyle: 'italic',
                  fontSize: 14,
                }}
              >
                part {landscapePageNumber}
              </Text>
            ) : null}
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {categoriesSlice.map(category => (
            <CategoryCard
              key={category.id}
              category={category}
              startupListUUID={startupList.public_uuid}
              startupListId={startupList.id}
            />
          ))}
        </View>
      </View>
    </Page>
  );
};
