import { captureException, captureMessage } from '@sentry/react';
import {
  EnumTableLeadMaturityEnum,
  useCreateProjectServiceMutation,
} from 'apollo/generated/sdkInnovationManager';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';

export const DEFAULT_LEAD_TITLE_PREFIX = 'New Lead by';

export const useCreateLead = () => {
  const [createProject] = useCreateProjectServiceMutation();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateLead = async (
    leadMaturityLevel?: EnumTableLeadMaturityEnum | null,
  ) => {
    try {
      const { data } = await createProject({
        variables: {
          object: {
            title: `${DEFAULT_LEAD_TITLE_PREFIX} ${currentUser.displayName}`,
            stage: 'scoping',
            source: 'self_created',
            type_of_solution: 'unknown',
            lead_maturity: leadMaturityLevel,
          },
        },
      });

      const projectId = data?.create_project?.project?.id;

      if (projectId) {
        navigate(buildProjectDetailsPath({ id: projectId, stage: 'scoping' }));
      } else {
        captureMessage('Failed to create new lead');
        enqueueSnackbar('Failed to create new lead', { variant: 'error' });
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Failed to create new lead', { variant: 'error' });
    }
  };

  return {
    handleCreateLead,
  };
};
