import { People } from '@mui/icons-material';
import {
  Card,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import {
  EnumTableProjectSourcesEnum,
  useGetProjectCategoriesForListQuery,
  useGetProjectViewsAggregateQuery,
} from 'apollo/generated/sdkInnovationManager';
import StyledDataGrid from 'components/StyledDataGrid';
import { ConnectedProjectLink } from 'components/dashboard/projects/ConnectedProjectLink';
import LinkToPerson from 'components/engagement/people/LinkToPerson';
import { GDLogo } from 'components/shared/GDLogo';
import { TeamFilter } from 'components/teams/TeamFilter';
import { SEARCH_PARAMS } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { LibraryTabType } from 'layouts/LibraryLayout';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { formatDate } from 'utils/datetime';
import { getListOwnerDetails } from 'utils/projects';
import { DecoratedStartupListForListPage } from '../../../../@types/startupList';
import { OwnerFilter } from './tableFilters/OwnerFilter';
import { SourcingsOnlyFilter } from './tableFilters/SourcingsOnlyFilter';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  CollaboratorFragment,
  useFindStartupListTagsQuery,
} from 'apollo/generated/sdkShared';
import { RouterLink } from 'components/RouterLink';
import { BaseStartupListStats } from 'components/base/BaseStartupListStats';
import { BaseServerFilterMenu } from 'components/base/serverFilters/BaseServerFilterMenu';
import {
  dotDivider,
  smallTextStyles,
} from 'components/base/typography/constants';
import { SharedUser } from 'types/shared';
import { UserWithPerson } from '../details/share/ShareStartupList';
import { TagsFilter } from './tableFilters/TagsFilters';

export const ABSENT_ASSIGNEE_LABEL = 'Not assigned';

export type StartupListTag = { id: number; name: string };

export const LIST_TABLE_SCOUTINGS_ONlY_FILTER_SELECTED =
  'list_table_scoutings_only_filter';

export default function ListTable({
  filteredStartupLists,
  loading,
}: {
  filteredStartupLists: DecoratedStartupListForListPage[];
  loading: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useLocalStorage(
    'library_lists_page_size',
    20,
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'created_at', sort: 'desc' },
  ]);

  const { data: tagsData } = useFindStartupListTagsQuery();
  const tagsValueOptions = (tagsData?.startup_list_tags || []).map(t => t.name);
  const columns = useGetColumns(tagsValueOptions);

  const [columnsVisibilityForPicker, setColumnsVisibilityForPicker] = useState(
    () => {
      return columns
        .filter(c => c.hideable !== false && c.headerName)
        .map(c => ({ label: c.headerName!, visible: true, field: c.field }));
    },
  );

  const columnsVisibilityModel = {
    // init all columns as not visible as by default they're visible and that shows some irrelevant ones
    ...columns.reduce((acum, col) => ({ ...acum, [col.field]: false }), {}),
    ...columnsVisibilityForPicker.reduce(
      (allCols, col) => ({
        ...allCols,
        [columns.find(c => c.headerName === col.label)!.field]: col.visible,
      }),
      {},
    ),
  };

  const onListClicked = (id: number) => {
    const { hash } = window.location;
    const newPath = `${window.location.pathname}${hash}?page=${
      searchParams.get(SEARCH_PARAMS.page) || 0
    }`;

    return navigate(PATH_ROOT.lists.details(id as number), {
      state: {
        backToPage: newPath,
      },
    });
  };

  return (
    <>
      <Card sx={{ marginTop: 2, marginBottom: 2 }}>
        <StyledDataGrid
          loading={loading}
          isRowClickable
          sortModel={sortModel}
          onSortModelChange={sortModel => setSortModel(sortModel)}
          rows={filteredStartupLists.map(fs => ({
            ...fs,
            startup_list_tags: fs.startup_list_tags.map(t => ({
              id: t.id,
              name: t?.tag?.name,
            })),
          }))}
          columns={columns}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => {
            setPageSize(newPageSize);
          }}
          page={Number(searchParams.get(SEARCH_PARAMS.page) || 0)}
          onPageChange={page => {
            setSearchParams({
              [SEARCH_PARAMS.page]: `${page}`,
              [SEARCH_PARAMS.tab]: 'lists' as LibraryTabType,
            });
          }}
          rowsPerPageOptions={[20, 50, 200]}
          columnVisibilityModel={columnsVisibilityModel}
          autoHeight
          disableSelectionOnClick
          disableColumnSelector
          onRowClick={(data, event) => {
            if (event.metaKey || event.ctrlKey) {
              window.open(
                `${window.location.origin}${PATH_ROOT.lists.details(
                  parseInt(data.id as string),
                )}`,
                '_blank',
              );
              return;
            }

            onListClicked(parseInt(data.id as string));
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              columnsVisibilityForPicker,
              setColumnsVisibilityForPicker,
            },
          }}
        />
      </Card>
    </>
  );
}

export const ConnectedProjectCell = (params: GridCellParams) => {
  const data = params.row as DecoratedStartupListForListPage;

  const project = data.project;

  return project ? (
    <Stack>
      <ConnectedProjectLink
        project={{
          id: project.id,
          stage: project.stage,
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {project.title}
      </ConnectedProjectLink>
    </Stack>
  ) : null;
};

const ViewsIndicator = ({ startupListId }: { startupListId: number }) => {
  const { data, loading } = useGetProjectViewsAggregateQuery({
    variables: { startupListId },
  });

  if (loading) {
    return (
      <Skeleton
        sx={({ spacing }) => ({
          height: spacing(2),
          width: spacing(4),
          marginLeft: 1,
        })}
        variant='text'
      />
    );
  }

  const visits =
    data?.shared_project_links_aggregate?.aggregate?.sum?.visits_count;

  if (visits && visits > 0) {
    return (
      <Tooltip
        title={`
        ${visits} ${visits === 1 ? 'person' : 'people'}
        visited this list
      `}
      >
        <People
          sx={({ palette }) => ({
            fontSize: '1rem',
            color: palette.grey[700],
            marginLeft: 1,
          })}
        />
      </Tooltip>
    );
  }

  return null;
};

export const TitleCell = (params: GridCellParams) => {
  const row = params.row as DecoratedStartupListForListPage;
  return (
    <StartupListCardContent
      row={row}
      detailsPagePath={PATH_ROOT.lists.details(row.id)}
    />
  );
};

export const StartupListCardContent = ({
  row,
  detailsPagePath,
}: {
  row: {
    id: number;
    title: string;
    source: EnumTableProjectSourcesEnum;
    owner?: SharedUser | null;
    sourcing_order?: {
      created_by?: UserWithPerson | null;
    } | null;
    collaborators: CollaboratorFragment[];
  };
  detailsPagePath: string | null;
}) => {
  const { isOwnedByGD, ownerName } = getListOwnerDetails({
    collaborators: row.collaborators,
  });
  const { palette } = useTheme();

  const { data: projectCategories, loading } =
    useGetProjectCategoriesForListQuery({
      variables: { startup_list_id: row.id },
    });

  const totalStartups = projectCategories?.project_categories.reduce(
    (acc, curr) => acc + curr.categorized_suppliers.length,
    0,
  );
  const totalCategories = projectCategories?.project_categories.length;

  return (
    <Stack direction='row' alignItems='center'>
      <Stack spacing={0}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2' data-testid='list-title'>
            {detailsPagePath ? (
              <RouterLink
                to={detailsPagePath}
                state={{
                  backToPage: `${window.location.pathname}${window.location.search}`,
                }}
                onClick={e => e.stopPropagation()}
              >
                {row.title}
              </RouterLink>
            ) : (
              row.title
            )}
          </Typography>
          <ViewsIndicator startupListId={row.id} />
        </Stack>
        <Typography
          sx={({ palette }) => ({
            display: loading ? 'flex' : 'inline',
            alignItems: 'center',
            color: palette.grey[500],
            ...smallTextStyles,
          })}
          component={'div'}
        >
          {ownerName && row.owner?.person.id && (
            <>
              {isOwnedByGD && (
                <GDLogo
                  size={20}
                  sxProps={{
                    display: 'inline-flex',
                    marginBottom: '-7px',
                  }}
                />
              )}
              <LinkToPerson
                sxProps={{
                  display: 'inline-flex',
                  '& .MuiTypography-root': {
                    color: palette.grey[500],
                    '&:hover': { color: palette.secondary.main },
                  },
                }}
                fullName={ownerName || ''}
                id={row.owner.person.id}
              />
              {dotDivider}
            </>
          )}
          {row.source === 'discovery_section' && (
            <Tooltip title='This list is maintained by GlassDollar and you are subscribed to updates'>
              <Typography
                marginRight={0.3}
                sx={smallTextStyles}
                component='span'
              >
                <GDLogo
                  size={20}
                  sxProps={{
                    display: 'inline-flex',
                    marginBottom: '-7px',
                  }}
                />
                Discovery {dotDivider}
              </Typography>
            </Tooltip>
          )}
          {loading ? (
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
              justifyContent='center'
            >
              <Skeleton
                sx={({ spacing }) => ({
                  height: spacing(2),
                  width: spacing(6),
                })}
                variant='text'
              />
              {dotDivider}
              <Skeleton
                sx={({ spacing }) => ({
                  height: spacing(2),
                  width: spacing(6),
                })}
                variant='text'
              />
            </Stack>
          ) : (
            <BaseStartupListStats
              totalStartups={totalStartups || 0}
              totalCategories={totalCategories || 0}
            />
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

// TODO: Enforce better types
const useGetColumns = (tagsValueOptions: string[]) => {
  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'id', hide: true, filterable: false, hideable: false },
      {
        field: 'title',
        headerName: 'Name',
        width: 400,
        renderCell: TitleCell,
        filterable: false,
      },
      {
        field: 'startup_list_tags',
        headerName: 'Tags',
        minWidth: 200,
        getApplyQuickFilterFn: undefined,
        type: 'singleSelect',
        valueOptions: tagsValueOptions,
        renderCell(
          params: GridRenderCellParams<
            StartupListTag[],
            DecoratedStartupListForListPage
          >,
        ) {
          return (
            <Stack
              sx={{ width: '100%', overflow: 'hidden' }}
              direction={'row'}
              spacing={1}
            >
              {params.value?.map(v => (
                <Chip
                  key={`startup-list-tag-${v.id}`}
                  sx={theme => ({
                    backgroundColor: theme.palette.grey[300],
                  })}
                  size='small'
                  label={
                    <Stack
                      direction={'row'}
                      spacing={0.5}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ position: 'relative' }}
                    >
                      <Typography variant='inherit'>{v.name}</Typography>
                    </Stack>
                  }
                />
              ))}
            </Stack>
          );
        },
      },
      {
        field: 'projects',
        headerName: 'Leads/Projects',
        minWidth: 160,
        flex: 0.5,
        filterable: false,
        renderCell: ConnectedProjectCell,
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        type: 'dateTime',
        width: 160,
        filterable: false,
        renderCell: params => {
          return (
            <>
              <Typography variant='body2'>
                {formatDate(params.value as string)}
              </Typography>
            </>
          );
        },
      },
    ],
    [tagsValueOptions],
  );

  return columns;
};

function CustomToolbar() {
  const { has_teams } = useCurrentOrganizationFromContext();
  const theme = useTheme();
  const staticStyles = {
    toolbarContainer: {
      width: '100%',
      justifyContent: 'flex-end',
      '&.MuiDataGrid-toolbarContainer .MuiButton-root': {
        marginRight: '0px',
      },
    },
    divider: { height: theme.spacing(2), marginY: 'auto' },
  };

  return (
    <GridToolbarContainer sx={staticStyles.toolbarContainer}>
      <BaseServerFilterMenu scope='listTableFilters'>
        <OwnerFilter />
        {has_teams && <TeamFilter scope='listTableFilters' />}
        <TagsFilter />
        <SourcingsOnlyFilter />
      </BaseServerFilterMenu>
    </GridToolbarContainer>
  );
}
