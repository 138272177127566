import { useGetOrganizationPortalConfigQuery } from 'apollo/generated/sdkShared';
import { X_HASURA_ORGANIZATION_UUID } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';

const useGetOrganizationPortalConfig = ({ teamSlug }: { teamSlug: string }) => {
  const { token } = useAuth();
  const currentOrganization = useCurrentOrganizationFromContext();
  // For anonymous users we need to pass UUID
  const context = !token
    ? {
        headers: {
          [X_HASURA_ORGANIZATION_UUID]: currentOrganization.uuid,
        },
      }
    : {};
  const variables =
    teamSlug !== undefined && teamSlug !== null
      ? { teamSlug }
      : { teamSlug: 'default' };

  const { data: organizationPortalConfigsData, refetch } =
    useGetOrganizationPortalConfigQuery({
      variables,
      context,
    });
  const organizationPortalConfig =
    organizationPortalConfigsData?.organization_portal_configs[0];

  return {
    config: organizationPortalConfig,
    refetch,
  };
};

export { useGetOrganizationPortalConfig };
