import { alpha, Button, useTheme } from '@mui/material';
import MenuButton, { MenuItemLabel } from 'components/MenuButton';
import { ReactNode, useState } from 'react';
import { useSafeCallback } from './useSafeCallback';

export const BaseStatusSelect = <V extends string>({
  value,
  optionLabelsMap,
  options,
  labelStyle,
  handleChange,
  label,
  readonly,
}: {
  value: V;
  optionLabelsMap: Record<V, MenuItemLabel>;
  options: V[];
  labelStyle: { backgroundColor: string; color: string };
  handleChange: (value: V) => Promise<unknown>;
  label?: ReactNode;
  readonly?: boolean;
}) => {
  const theme = useTheme();
  const [changeInProgress, setChangeInProgress] = useState(false);
  const handleChangeWrapper = useSafeCallback(
    async (newValue: V) => {
      try {
        setChangeInProgress(true);
        await handleChange(newValue);
      } finally {
        setChangeInProgress(false);
      }
    },
    [handleChange],
  );
  const buttonSx = {
    backgroundColor: labelStyle.backgroundColor,
    '&:hover': {
      backgroundColor: alpha(labelStyle.backgroundColor, 0.8),
    },
    color: labelStyle.color,
    width: 'fit-content',
    fontWeight: 600,
    paddingY: 0,
    fontSize: theme.typography.caption.fontSize,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  };

  if (readonly) {
    return <Button sx={buttonSx}>{label || optionLabelsMap[value]}</Button>;
  }

  return (
    <MenuButton
      containerSx={{
        marginLeft: 2,
      }}
      title={label || optionLabelsMap[value]}
      buttonProps={{
        variant: 'contained',
        size: 'small',
        disabled: changeInProgress,
        'data-testid': 'base-status-select',
      }}
      sx={buttonSx}
      items={options.map(stage => ({
        label: optionLabelsMap[stage],
        onClick: () => handleChangeWrapper(stage),
        disabled: stage === value,
      }))}
    />
  );
};
