import { Link, OpenInNew } from '@mui/icons-material';
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { buildStartupListResultsUrl } from 'utils/url';
import { SharedStartupList } from '../../../../../@types/shared';
import { Permissions } from '../Permissions';
import { ShareEmailTabContent } from './Email';
import { ShareLinkTabContent } from './Link';
import { ShareModalTabs, TabsType } from './ShareModalTabs';
import { StartupListForShare } from './ShareStartupList';
import { useCaptureCopyLink } from './useCaptureCopyLink';
import { useShareStartupList } from './useShareStartupList';
import { Person } from '../../../../../@types/startupList';

const ShareStartupListModal = ({
  currentStartupList,
  isOpen,
  shares,
  onClose,
}: {
  currentStartupList: StartupListForShare;
  isOpen: boolean;
  shares: SharedStartupList[];
  onClose: () => void;
}) => {
  const { handleSharing: onShare } = useShareStartupList({
    currentStartupList,
    isNoViews: false,
  });
  const [selectedStakeholders, setSelectedStakeholders] = useState<Person[]>(
    [],
  );

  const { enqueueSnackbar } = useSnackbar();
  const [currentTabValue, setCurrentTabValue] = useState<TabsType>('email');
  const captureCopyLinkEvent = useCaptureCopyLink(currentStartupList);
  const url = useMemo(() => {
    if (!shares.length || !currentStartupList.public_uuid) {
      return '';
    }

    return buildStartupListResultsUrl({
      startupListPublicUUID: currentStartupList.public_uuid,
      slug: shares[0].target_group_slug,
    });
  }, [currentStartupList.public_uuid, shares]);
  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={onClose}>
      <Stack>
        <Typography variant='h4' sx={{ paddingX: 2, paddingY: 2 }}>
          {/* eslint-disable-next-line */}
          Share "{currentStartupList.title}"
        </Typography>
        <DialogTitle
          sx={{
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 0,
          }}
        >
          <Stack direction='row' width='100%' alignItems='center' gap={1}>
            <ShareModalTabs
              currentTabValue={currentTabValue}
              onClick={setCurrentTabValue}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {currentTabValue === 'link' && (
            <ShareLinkTabContent
              shares={shares.filter(
                s => !s.target_group_slug.startsWith('Email for'),
              )}
              onShare={onShare}
              startupList={currentStartupList}
              captureCopyLinkEvent={captureCopyLinkEvent}
            />
          )}
          {currentTabValue === 'email' && (
            <ShareEmailTabContent
              currentStartupList={currentStartupList}
              onShare={onShare}
              setSelectedStakeholders={setSelectedStakeholders}
              selectedStakeholders={selectedStakeholders}
            />
          )}
          <Divider sx={{ marginTop: 2 }} />
          <Collapse in={selectedStakeholders.length === 0}>
            <Stack direction='row' gap={1} marginTop={2}>
              {currentTabValue === 'email' && (
                <Permissions
                  currentStartupList={currentStartupList}
                  sxProps={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                />
              )}
              <Stack direction='row' sx={{ marginLeft: 'auto' }} gap={1}>
                <CopyToClipboard
                  text={url}
                  onCopy={() => {
                    captureCopyLinkEvent();
                    enqueueSnackbar('Link copied', { variant: 'success' });
                  }}
                >
                  <Button
                    disabled={!url}
                    sx={{
                      whiteSpace: 'nowrap',
                      minWidth: 120,
                      marginLeft: 'auto',
                    }}
                    startIcon={<Link />}
                  >
                    Copy link
                  </Button>
                </CopyToClipboard>
                {currentTabValue === 'link' && (
                  <Button
                    disabled={!url}
                    sx={{
                      whiteSpace: 'nowrap',
                      minWidth: 120,
                    }}
                    href={url}
                    target='_blank'
                    startIcon={<OpenInNew />}
                  >
                    Preview
                  </Button>
                )}
                <Button variant='contained' onClick={onClose}>
                  Done
                </Button>
              </Stack>
            </Stack>
          </Collapse>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default ShareStartupListModal;
