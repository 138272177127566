import { Check, KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  styled,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { EnumTableEntityVisibilityEnum } from 'apollo/generated/sdkInnovationManager';
import {
  useAssignStartupListToTeamMutation,
  useUpdateStartupListVisibilityMutation,
} from 'apollo/generated/sdkShared';
import TeamAvatar from 'components/teams/TeamAvatar';
import { useGetAllTeamsData } from 'components/teams/useGetAllTeamsData';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useSnackbar } from 'notistack';
import { CSSProperties, MouseEvent, useState } from 'react';
import { StartupListForShare } from './share/ShareStartupList';
import OrganizationIcon from './share/assets/organization.svg?react';
import PrivateIcon from './share/assets/private.svg?react';
import TeamIcon from './share/assets/team.svg?react';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import useAuth from 'hooks/useAuth';

const ALREADY_LINKED_WITH_PROJECT_MESSAGE =
  'This list is already linked with a project and requires a full organization visibility.';

const StyledMenuItem = styled(MenuItem)<{ isPrivateAndTeamDisabled: boolean }>(
  ({ isPrivateAndTeamDisabled }) => ({
    cursor: isPrivateAndTeamDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: isPrivateAndTeamDisabled
      ? 'action.disabledBackground'
      : 'transparent',
    '&:hover': {
      backgroundColor: isPrivateAndTeamDisabled
        ? 'action.disabledBackground'
        : 'transparent',
    },
    '& *': {
      color: isPrivateAndTeamDisabled ? 'action.disabled' : 'inherit',
    },
  }),
);

export const ShareVisibilityIcon = ({
  visibility,
  cssProps = {},
}: {
  visibility: EnumTableEntityVisibilityEnum;
  cssProps?: CSSProperties;
}) => {
  switch (visibility) {
    case 'organization':
      return <OrganizationIcon style={cssProps} />;
    case 'team':
      return <TeamIcon style={cssProps} />;
    case 'private':
      return <PrivateIcon style={cssProps} />;
  }
};

const OptionInnerContent = ({
  currentStartupList,
  mainText,
  subText,
  isMainGreenSelector = false,
}: {
  currentStartupList: StartupListForShare;
  mainText: string;
  subText: string;
  isMainGreenSelector?: boolean;
}) => {
  const { palette } = useTheme();
  const mainTextColor = isMainGreenSelector
    ? palette.primary.main
    : palette.text.primary;

  const subTextColor = isMainGreenSelector
    ? palette.primary.main
    : palette.text.secondary;

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      <ShareVisibilityIcon
        cssProps={{ fill: subTextColor }}
        visibility={currentStartupList.visibility}
      />
      <Stack>
        <Typography
          textAlign='left'
          color={mainTextColor}
          fontSize={14}
          fontWeight={isMainGreenSelector ? 'bold' : 'normal'}
        >
          {mainText}
        </Typography>
        <Typography variant='caption' color={subTextColor}>
          {subText}
        </Typography>
      </Stack>
    </Stack>
  );
};

const OptionContent = ({
  currentStartupList,
  isMainGreenSelector = false,
}: {
  currentStartupList: StartupListForShare;
  isMainGreenSelector?: boolean;
}) => {
  const { name } = useCurrentOrganizationFromContext();

  switch (currentStartupList.visibility) {
    case 'organization':
      return (
        <OptionInnerContent
          isMainGreenSelector={isMainGreenSelector}
          currentStartupList={currentStartupList}
          mainText={`Everyone at ${name}`}
          subText={isMainGreenSelector ? '' : 'Can view and edit'}
        />
      );
    case 'team':
      return (
        <OptionInnerContent
          isMainGreenSelector={isMainGreenSelector}
          currentStartupList={currentStartupList}
          mainText={currentStartupList.team?.name || 'Cross-functional'}
          subText={
            isMainGreenSelector
              ? ''
              : 'Only you and your team can view and edit'
          }
        />
      );
    case 'private':
      return (
        <OptionInnerContent
          isMainGreenSelector={isMainGreenSelector}
          currentStartupList={currentStartupList}
          mainText='Invite only'
          subText={isMainGreenSelector ? '' : 'Only you can view and edit'}
        />
      );
  }
};

export const Permissions = ({
  currentStartupList,
  sxProps = {},
}: {
  currentStartupList: StartupListForShare;
  sxProps?: SxProps;
}) => {
  const { avatarUrlMap, teamsData } = useGetAllTeamsData();
  const [assignListToTeam] = useAssignStartupListToTeamMutation();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const submenuOpen = Boolean(submenuAnchorEl);
  // TODO: Remove once we implement entity permissions for projects
  const isPrivateAndTeamDisabled = !!currentStartupList.project?.id;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
  };

  const handleSubmenuClick = (event: MouseEvent<HTMLElement>) => {
    if (isPrivateAndTeamDisabled) {
      enqueueSnackbar(ALREADY_LINKED_WITH_PROJECT_MESSAGE, {
        variant: 'warning',
      });
      return;
    }

    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const [updateVisibility] = useUpdateStartupListVisibilityMutation();

  const handleSelect = async (
    visibility: EnumTableEntityVisibilityEnum,
    teamId?: number | null,
  ) => {
    if (isPrivateAndTeamDisabled && visibility !== 'organization') {
      enqueueSnackbar(ALREADY_LINKED_WITH_PROJECT_MESSAGE, {
        variant: 'warning',
      });
      return;
    }

    await updateVisibility({
      variables: {
        id: currentStartupList.id,
        payload: {
          visibility,
          created_by_user_id: currentStartupList?.created_by_user_id || user.id,
        },
      },
    });

    if (visibility === 'team') {
      await assignListToTeam({
        variables: { teamId, id: currentStartupList.id },
      });
    }

    captureAnalyticsEvent('Set entity permissions', {
      id: currentStartupList.id,
      type: 'list',
      permission: visibility,
    });

    handleClose();
  };

  return (
    <Stack direction='row'>
      <Button
        id='list-permissions-button'
        data-testid='list-permissions-button'
        aria-controls={open ? 'list-permissions-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        variant='outlined'
        sx={{
          ...sxProps,
          whiteSpace: 'nowrap',
          backgroundColor: open ? 'primary.luminous' : 'transparent',
        }}
      >
        <OptionContent
          currentStartupList={currentStartupList}
          isMainGreenSelector
        />
      </Button>
      <Menu
        id='list-permissions-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'list-permissions-button',
        }}
      >
        <MenuItem
          onClick={() => handleSelect('organization')}
          data-testid='menu-item-organization-list-permission'
        >
          <Stack direction='row' alignItems='center' width='100%'>
            <OptionContent
              currentStartupList={{
                ...currentStartupList,
                visibility: 'organization',
              }}
            />
            {currentStartupList.visibility === 'organization' && (
              <Check sx={{ marginLeft: 'auto' }} fontSize='small' />
            )}
          </Stack>
        </MenuItem>
        <StyledMenuItem
          data-testid='menu-item-team-list-permission'
          onClick={event => {
            handleSubmenuClick(event);
          }}
          aria-haspopup='true'
          aria-controls={submenuOpen ? 'team-submenu' : undefined}
          aria-expanded={submenuOpen ? 'true' : undefined}
          isPrivateAndTeamDisabled={isPrivateAndTeamDisabled}
        >
          <Tooltip
            title={
              isPrivateAndTeamDisabled
                ? ALREADY_LINKED_WITH_PROJECT_MESSAGE
                : ''
            }
          >
            <Stack direction='row' alignItems='center'>
              <OptionContent
                currentStartupList={{
                  ...currentStartupList,
                  visibility: 'team',
                }}
              />
              <KeyboardArrowDown
                fontSize='small'
                sx={{ color: 'text.primary' }}
              />
            </Stack>
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          data-testid='menu-item-private-list-permission'
          onClick={() => handleSelect('private')}
          isPrivateAndTeamDisabled={isPrivateAndTeamDisabled}
        >
          <Tooltip
            title={
              isPrivateAndTeamDisabled
                ? ALREADY_LINKED_WITH_PROJECT_MESSAGE
                : ''
            }
          >
            <Stack direction='row' alignItems='center' width='100%'>
              <OptionContent
                currentStartupList={{
                  ...currentStartupList,
                  visibility: 'private',
                }}
              />
              {currentStartupList.visibility === 'private' && (
                <Check sx={{ marginLeft: 'auto' }} />
              )}
            </Stack>
          </Tooltip>
        </StyledMenuItem>
      </Menu>
      {/* Submenu for Team */}
      <Menu
        id='team-submenu'
        anchorEl={submenuAnchorEl}
        open={submenuOpen}
        onClose={handleSubmenuClose}
        MenuListProps={{
          'aria-labelledby': 'team-submenu-button',
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {teamsData.map(mappedTeam => (
          <MenuItem
            data-testid={`sub-menu-item-${mappedTeam.name}`}
            key={mappedTeam.id}
            onClick={() => handleSelect('team', mappedTeam.id)}
            sx={{ fontSize: 14 }}
          >
            {mappedTeam && avatarUrlMap?.[mappedTeam.id] && (
              <TeamAvatar
                src={avatarUrlMap[mappedTeam.id]}
                alt={mappedTeam.name}
                sx={{ marginRight: 1 }}
              />
            )}
            {mappedTeam.name}
            {currentStartupList.team?.id === mappedTeam.id && (
              <Check
                fontSize='small'
                sx={{ marginLeft: 'auto', paddingLeft: 1 }}
              />
            )}
          </MenuItem>
        )) || []}
      </Menu>
    </Stack>
  );
};
