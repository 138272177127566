import { StyleSheet } from '@react-pdf/renderer';
import palette from 'theme/palette';

export const styles = StyleSheet.create({
  introPage: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    paddingBottom: '12px',

    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Helvetica',
  },

  contentPage: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    paddingBottom: '12px',
    fontFamily: 'Helvetica',
  },

  pageTitle: {
    fontSize: 30,
    marginBottom: 20,
  },
});

export const tableCellStyle = {
  padding: 5,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontSize: 11,
  borderColor: palette.light.grey[300],
  borderBottom: `1px solid ${palette.light.grey[300]}`,
};

export const headerCellStyle = {
  ...tableCellStyle,
  backgroundColor: '#F4F6F8',
};
