import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Grow,
  Popper,
  Stack,
  useTheme,
} from '@mui/material';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import {
  GetDiscoveryItemsMinimalQuery,
  GetProjectSearchMinimalQuery,
  GetStartupListsMinimalQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useNavigate } from 'react-router';
import { PATH_ROOT, buildProjectDetailsPath } from 'routes/paths';
import { ProjectResults } from './ProjectResults';
import { StartupResults } from './StartupResults';

import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { DEFAULT_LINK_TARGET_GROUP_SLUG } from 'components/OrganizationPortal/shared/usePortalAnalytics';
import { StartupResultForGoToSearch } from './useSearch';
import { StartupListResults } from './StartupListResults';

type Props = {
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
  startups: StartupResultForGoToSearch[];
  projects: GetProjectSearchMinimalQuery['projects'];
  startupLists: GetStartupListsMinimalQuery['startup_lists'];
  discoveryItems: GetDiscoveryItemsMinimalQuery['startup_lists'];
  onKeyDown: (event: React.KeyboardEvent) => void;
  query: string;
};

function Results({
  anchorEl,
  onClose,
  onKeyDown,
  open,
  startups,
  projects,
  startupLists,
  discoveryItems,
  query,
}: Props) {
  const { shadows, palette, zIndex } = useTheme();
  const navigate = useNavigate();
  const { isSharedPage } = useSharedPagesContext();

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement='bottom-start'
      transition
      sx={{ zIndex: zIndex.appBar }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper
            sx={{
              boxShadow: shadows[10],
              width: isSharedPage ? '445px !important' : '297px !important',
            }}
          >
            <ClickAwayListener
              onClickAway={e => {
                if (
                  e.target === anchorEl ||
                  e.target === anchorEl?.parentElement
                ) {
                  return;
                }

                onClose();
              }}
            >
              <MenuList
                autoFocusItem={open}
                id='composition-menu'
                aria-labelledby='composition-button'
                onKeyDown={onKeyDown}
              >
                <StartupResults onClose={onClose} startups={startups} />
                {isSharedPage ? (
                  <StartupListResults
                    navigateTo={(_id, publicUUID) =>
                      publicUUID &&
                      navigate(
                        PATH_ROOT.lists.shared(
                          publicUUID,
                          DEFAULT_LINK_TARGET_GROUP_SLUG,
                        ),
                      )
                    }
                    onClose={onClose}
                    label='Lists'
                    startupLists={startupLists}
                  />
                ) : (
                  <Box>
                    <StartupListResults
                      navigateTo={(id?: number) =>
                        navigate(PATH_ROOT.lists.details(id!))
                      }
                      onClose={onClose}
                      label='Lists'
                      startupLists={startupLists}
                    />
                    <ProjectResults
                      navigateTo={(id, stage) =>
                        navigate(buildProjectDetailsPath({ id, stage }))
                      }
                      onClose={onClose}
                      projects={projects}
                    />
                    <StartupListResults
                      navigateTo={(id?: number) =>
                        navigate(PATH_ROOT.lists.details(id!, true))
                      }
                      onClose={onClose}
                      label='Discovery Items'
                      startupLists={discoveryItems}
                    />
                  </Box>
                )}
              </MenuList>
            </ClickAwayListener>
            <Fade in={!!query} mountOnEnter unmountOnExit>
              <Stack>
                <Divider />
                <Button
                  onClick={e => {
                    e.stopPropagation();

                    if (isSharedPage) {
                      navigate(PATH_ROOT.organizationPortal.searchFor(query));
                    } else {
                      navigate(PATH_ROOT.scout.searchFor(query));
                    }
                  }}
                  sx={{ color: palette.primary.main, borderRadius: 0 }}
                >
                  {/* eslint-disable-next-line */}
                  Search for "{query}"
                </Button>
              </Stack>
            </Fade>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export { Results };
