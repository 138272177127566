import { AboutPageState } from 'components/AboutPageBuilder/shared/AboutPageContext';
import LoadingScreen from 'components/LoadingScreen';
import { useGetOrganizationPortalConfig } from 'components/OrganizationPortal/useGetOrganizationPortalConfig';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { HomePageState } from '../useUpdateHomePageState';

type PortalConfig = {
  id: number;
  home_page_enabled: boolean;
  home_page_state: HomePageState;
  about_page_state: AboutPageState;
  about_page_enabled: boolean;
  searchbox_enabled: boolean;
  request_form_page_enabled: boolean;
  team_id?: number | null;
  team_slug?: string | null;
  team?: {
    id: number;
    name: string;
  } | null;
};

type OrganizationPortalState = {
  currentPortalConfig: PortalConfig;
};

type OrganizationPortalContextType = {
  currentPortalConfig: PortalConfig;
  setOrganizationPortalState: React.Dispatch<
    React.SetStateAction<OrganizationPortalState>
  >;
  resetPortalState: () => void;
  updatePortalConfig: (
    newConfig: Partial<OrganizationPortalState['currentPortalConfig']>,
  ) => void;
  getPortalConfigField: <K extends keyof PortalConfig>(
    field: K,
  ) => PortalConfig[K] | undefined;
};

const defaultState: OrganizationPortalState = {
  currentPortalConfig: {
    id: 0,
    home_page_enabled: false,
    home_page_state: {
      title: '',
      subtitle: '',
      sections: {
        lists: { section_order: 0, section_items: [], hide_section: false },
      },
    },
    about_page_state: {
      'team-members': [],
      'paragraph-section-one': {
        content: '',
      },
      'process-flow-section': [],
      'button-section-one': {
        content: '',
        href: '',
        linkType: 'custom',
        variant: 'text',
        size: 'medium',
      },
      'paragraph-section-two': {
        content: '',
      },
      'button-section-two': {
        content: '',
        href: '',
        linkType: 'custom',
        variant: 'text',
        size: 'medium',
      },
      'paragraph-section-three': {
        content: '',
      },
    },

    about_page_enabled: false,
    searchbox_enabled: false,
    request_form_page_enabled: false,
  },
};

export const OrganizationPortalContext =
  createContext<OrganizationPortalContextType | null>(null);

export const OrganizationPortalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { teamSlug } = useParams();
  const { config } = useGetOrganizationPortalConfig({
    teamSlug: teamSlug ?? 'default',
  });

  const [organizationPortalState, setOrganizationPortalState] =
    useState<OrganizationPortalState>(defaultState);

  useEffect(() => {
    if (config?.id) {
      setOrganizationPortalState({ currentPortalConfig: config });
    }
  }, [config]);

  const resetPortalState = () => {
    setOrganizationPortalState(defaultState);
  };

  const updatePortalConfig = (
    newConfig: Partial<OrganizationPortalState['currentPortalConfig']>,
  ) => {
    setOrganizationPortalState(prevState => ({
      currentPortalConfig: {
        ...prevState.currentPortalConfig!,
        ...newConfig,
      },
    }));
  };

  const getPortalConfigField = <K extends keyof PortalConfig>(
    field: K,
  ): PortalConfig[K] | undefined => {
    return organizationPortalState.currentPortalConfig?.[field];
  };

  if (!config?.id) return <LoadingScreen />;

  return (
    <OrganizationPortalContext.Provider
      value={{
        currentPortalConfig: organizationPortalState.currentPortalConfig,
        setOrganizationPortalState,
        resetPortalState,
        updatePortalConfig,
        getPortalConfigField,
      }}
    >
      {children}
    </OrganizationPortalContext.Provider>
  );
};

// Custom hook to use the OrganizationPortalContext
export const useOrganizationPortalContext = () => {
  const context = useContext(OrganizationPortalContext);
  if (!context) {
    throw new Error(
      'useOrganizationPortalContext must be used within an OrganizationPortalContextProvider',
    );
  }
  return context;
};
