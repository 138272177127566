import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
// material
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import {
  COLLAPSE_WIDTH,
  DRAWER_TRANSITION_LENGTH,
  DRAWER_WIDTH,
} from './DashboardSidebar';
import NavbarContent from './NavbarContent';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import { UPDATES_BANNER_HEIGHT } from '.';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
  transition: `width ${DRAWER_TRANSITION_LENGTH}`,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({
  onOpenSidebar,
}: DashboardNavbarProps) {
  const { isCollapsed } = useCollapseDrawer();
  const { isFeaturesUpdatesBannerVisible } = useFeaturesUpdatesBanner();
  return (
    <RootStyle
      sx={{
        ...(isFeaturesUpdatesBannerVisible && { top: UPDATES_BANNER_HEIGHT }),
        ...(isCollapsed && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width='lgUp'>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <NavbarContent />
        <Box sx={{ flexGrow: 1 }} />
      </ToolbarStyle>
    </RootStyle>
  );
}
