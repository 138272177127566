import { gql, useSubscription } from '@apollo/client';
import { FeatureFlags } from 'apollo/generated/sdkShared';
import { ReactNode, createContext, useContext } from 'react';

type State = Array<Pick<FeatureFlags, 'enabled' | 'id' | 'name'>>;

const defaultState: State = [];

export const FeatureFlagsContext = createContext<{
  loading: boolean;
  state: State;
}>({
  loading: true,
  state: defaultState,
});

export const FeatureFlagsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { loading, data } = useSubscription(gql`
    subscription getFeatureFlags {
      feature_flags {
        id
        name
        enabled
      }
    }
  `);

  return (
    <FeatureFlagsContext.Provider
      value={{
        loading,
        state: data?.feature_flags || defaultState,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useGetFeatureFlag = (
  flag: 'ff_client_refresh' | 'ff_new_features_released',
) => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      'using FeatureFlagsContext without the appropriate provider',
    );
  }

  if (context.loading) {
    return false;
  }

  return context.state.find(f => f.name === flag)?.enabled || false;
};
