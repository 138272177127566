import { ReactNode, useMemo } from 'react';
// material
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// hooks
//
import componentsOverride from '../theme/overrides';
import palette from '../theme/palette';

// ----------------------------------------------------------------------

type ThemePrimaryColorProps = {
  children: ReactNode;
};

export default function ThemePrimaryColor({
  children,
}: ThemePrimaryColorProps) {
  const defaultTheme = useTheme();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: {
          name: 'default',
          ...palette.light.primary,
        },
      },
    }),
    [defaultTheme],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
