import { Page, Text, View, Link, Path, Svg, Image } from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import { buildProjectPreviewUrl } from 'utils/url';
import { CustomDimensionCellJSON } from '../../../../@types/shared';
import { CustomFeatureHeader } from '../../../../@types/shared';
import { styles, tableCellStyle } from './styles';
import { parseLinksInText, truncateWithEllipsis } from 'utils/general';
import { removeBorders } from './utils';
import { headerCellStyle } from './styles';
import { Pager } from './Pager';
import { FALLBACK_IMAGE } from 'components/AvatarGroup';
import { OrderedCategorizedStartup } from './types';

export const ComparisonMatrixPageGroup = ({
  startupList,
  startups,
  customColumnsSchema,
}: {
  startupList: { public_uuid: string };
  startups: OrderedCategorizedStartup[];
  customColumnsSchema: CustomFeatureHeader[];
}) => {
  const customFeatures = customColumnsSchema;

  const triboolCustomFeatures = customFeatures.filter(
    f => f.type === 'tribool',
  );
  const textCustomFeatures = customFeatures.filter(f => f.type === 'text');

  if (customFeatures.length === 0) return null;

  return (
    <Page size='A4' orientation='landscape' style={styles.contentPage}>
      <Pager />
      <View>
        <View
          fixed
          style={{
            ...styles.pageTitle,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text>{startups.length} Most Relevant Startups: Comparison</Text>
          {/* TODO: generate default share link and use it instead of the public preview */}
          <Link src={buildProjectPreviewUrl(startupList.public_uuid)}>
            <Text
              style={{
                // TODO: color from the theme doesn't work
                border: `1px solid #00AB55`,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 15,
                paddingRight: 15,
                borderRadius: 5,
                fontSize: 14,
                backgroundColor: '#00AB55',
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Select favorite startup(s)
            </Text>
          </Link>
        </View>
        {triboolCustomFeatures.length && (
          <View wrap={false} style={{ height: '100%', marginBottom: 24 }}>
            <ComparisonMatrix
              startups={startups}
              customFeatures={triboolCustomFeatures}
            />
          </View>
        )}
        {textCustomFeatures.map(customFeature => (
          <View
            wrap={false}
            style={{ height: '100%' }}
            key={customFeature.name}
          >
            <ComparisonMatrix
              startups={startups}
              customFeatures={[customFeature]}
            />
          </View>
        ))}
      </View>
    </Page>
  );
};

const ComparisonMatrix = ({
  startups,
  customFeatures,
}: {
  startups: OrderedCategorizedStartup[];
  customFeatures: CustomFeatureHeader[];
}) => {
  return (
    <Table data={startups}>
      <TableHeader {...removeBorders}>
        <TableCell style={{ ...headerCellStyle, maxWidth: 160, minWidth: 160 }}>
          Position & Company
        </TableCell>

        {customFeatures.map(customFeature => {
          const colWeighting = customFeature.type === 'tribool' ? 0.1 : 0.3;
          return (
            <TableCell
              key={customFeature.name}
              weighting={colWeighting}
              style={{ ...headerCellStyle, maxHeight: 100 }}
            >
              {customFeature.name}
            </TableCell>
          );
        })}
      </TableHeader>
      <TableBody {...removeBorders}>
        <DataTableCell
          style={{
            ...tableCellStyle,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            minWidth: 160,
            maxWidth: 160,
          }}
          getContent={(categorizedStartup: OrderedCategorizedStartup) => {
            return (
              <>
                <Text>{categorizedStartup.order}</Text>
                <Image
                  source={categorizedStartup.startup.logo_url || FALLBACK_IMAGE}
                  style={{ width: 20, marginLeft: 5 }}
                />
                <Text style={{ marginLeft: 5, minWidth: 110, maxWidth: 110 }}>
                  {truncateWithEllipsis(categorizedStartup.startup.name, 30)}
                </Text>
              </>
            );
          }}
        />
        {customFeatures.map(customFeature => {
          const colWeighting = customFeature.type === 'tribool' ? 0.1 : 0.3;
          return (
            <DataTableCell
              key={customFeature.name}
              weighting={colWeighting}
              style={tableCellStyle}
              getContent={(startup: OrderedCategorizedStartup) => {
                const data = startup.custom_dimensions_json_v1[
                  customFeature.name
                ] || { value: 'unknown' };

                const props = { value: data.value };

                if (customFeature.type === 'tribool') {
                  return <TriboolFeature {...props} />;
                } else if (customFeature.type === 'number') {
                  return <NumberFeatureValue value={data.value as number} />;
                } else {
                  return <TextFeatureValue value={data.value as string} />;
                }
              }}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const TextFeatureValue = ({ value }: { value: string }) => {
  const normalizedValue = (value || '-')
    .trim()
    .replace(/\r\n/g, ' ')
    .replace(/\n/g, ' ');
  const enrichedValue = parseLinksInText({
    textToParse: normalizedValue,
    renderLink: (link, domain) => (
      <Link src={link}>
        <Text>{domain}</Text>
      </Link>
    ),
  });

  // maxHeight: 30pt, assuming each text feature is a single line and longest one is up to 240 characters
  return (
    <Text style={{ textAlign: 'left', maxHeight: 30 }}>{enrichedValue}</Text>
  );
};

const NumberFeatureValue = ({ value }: { value: number }) => (
  <Text style={{ textAlign: 'left', maxHeight: 30 }}>{value}</Text>
);

const TriboolFeature = ({ value }: Partial<CustomDimensionCellJSON>) => {
  if (value === 'yes') {
    return (
      <Svg
        // @ts-expect-error: TODO: FIXME!
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 24 24'
        fill='none'
      >
        <Path
          d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
          fill='#4CA85E'
        />
      </Svg>
    );
  } else if (value === 'no') {
    return (
      <Svg
        // @ts-expect-error: TODO: FIXME!
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 24 24'
        fill='none'
      >
        <Path
          d='M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z'
          fill='#EB574C'
        />
      </Svg>
    );
  } else {
    return (
      <Svg
        // @ts-expect-error: TODO: FIXME!
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 24 24'
        fill='none'
      >
        <Path
          d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z'
          fill='#667280'
        />
      </Svg>
    );
  }
};
