import {
  Stack,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';

import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { FieldProps } from '../../pages/request-form/types';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { EditModeTitle } from './Shared';
import { InfoOutlined } from '@mui/icons-material';

type ProblemSchemaValues = Pick<SourcingOrderState, 'problem'>;

interface FormLabelWithTooltipProps extends TypographyProps {
  label: string;
  tooltipText: string;
  isMandatory?: boolean;
}

export const FormLabelWithTooltip = ({
  label,
  tooltipText,
  isMandatory = false,
  ...props
}: FormLabelWithTooltipProps) => {
  return (
    <Typography
      gutterBottom
      variant='h5'
      component='div'
      sx={{ display: 'flex', alignItems: 'center' }}
      {...props}
    >
      {label}
      <Tooltip title={tooltipText} placement='top-start'>
        <InfoOutlined
          fontSize='small'
          sx={{ marginLeft: 1, cursor: 'help', color: 'text.secondary' }}
        />
      </Tooltip>
      {isMandatory && '*'}
    </Typography>
  );
};

export function ProblemField({
  getFieldProps,
  touched,
  isEditMode,
  errors,
}: FieldProps<Pick<ProblemSchemaValues, 'problem'>>) {
  const { isSharedPage } = useSharedPagesContext();

  return (
    <>
      {isEditMode ? (
        <EditModeTitle sx={{ mt: 2 }}>Scope*</EditModeTitle>
      ) : (
        <FormLabelWithTooltip
          label='Need / Pain Point'
          tooltipText={
            isSharedPage
              ? 'Please describe the specific pain point or challenge you are facing and provide some examples if possible.'
              : 'Please describe the specific pain point or challenge your stakeholders are facing and provide some examples if possible.'
          }
          isMandatory
        />
      )}
      <Stack spacing={2} sx={{ marginTop: '20px' }}>
        <TextField
          rows={4}
          multiline={true}
          fullWidth
          data-test='need--field'
          {...getFieldProps('problem')}
          error={Boolean(touched.problem && errors.problem)}
          helperText={touched.problem && errors.problem}
        />
      </Stack>
    </>
  );
}
