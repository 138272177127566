import {
  Archive as ArchiveIcon,
  FiberDvr as DiscoverLetterIcon,
  Fullscreen as FullscreenIcon,
  GridView as GridViewIcon,
  Input as InputIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/core';
import {
  useArchiveProjectMutation,
  useArchiveStartupListMutation,
  useGetAllUsersQuery,
  useUpdateDiscoveryItemSourceMutation,
  useUpdateStartupListTitleMutation,
} from 'apollo/generated/sdkInnovationManager';
import MoreMenu from 'components/MoreMenu';
import DeliverSourcing from 'components/admin/DeliverSourcing';
import DetailsHeaderTitle from 'components/dashboard/DetailsHeaderTitle';
import AddStartupDialog from 'components/library/AddStartupDialog';
import { GDLogo } from 'components/shared/GDLogo';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getListOwnerDetails } from 'utils/projects';
import { buildAdminPlatformDomain } from 'utils/url';
import { SharedProjectCategory } from '../../../../@types/shared';
import { StartupListForDetail } from '../../../../@types/startupList';
import { PATH_ROOT } from '../../../../routes/paths';
import { useCreateNewCategoryModal } from './CreateNewCategory';
import ScoutingCommentModal from './ScoutingCommentModal';

import { useApolloClient } from '@apollo/client';
import {
  useCreateStartupListCollaboratorMutation,
  useUpsertStartupListOwnerMutation,
} from 'apollo/generated/sdkShared';
import { FullscreenContext } from 'contexts/FullscreenContext';
import useBreakpoints from 'hooks/useBreakpoints';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import EnrichListMenuItem from '../enrichment/EnrichListMenuItem';
import CollaboratorsList, { Collaborator } from './CollaboratorsList';
import DuplicateListMenuItem from './DuplicateListMenuItem';
import { EditOwner } from './EditOwner';
import LinkListToLead from './LinkListToLead';
import OrderSourcingOnStartupListDetails from './OrderSourcingOnStartupListDetails';
import { ShowHideChartButton } from './ShowHideChartButton';
import { StartupListTagsSelect } from './StartupListTagsSelect';
import { ExportCSVMenuItem } from './export/ExportCSVMenuItem';
import { ExportPDFMenuItem } from './export/ExportPDFMenuItem';
import { useBulkImportModal } from './fileImport/BulkImportStartupsModal';
import { ShareStartupList } from './share/ShareStartupList';

export type CategorizedStartupForHeader = {
  id: number;
  startup: {
    id: number;
    name: string;
    domain: string;
  };
};

export default function Header({
  currentStartupList,
  categories,
  categorizedStartups,
  refetchMainQuery,
}: {
  currentStartupList: StartupListForDetail;
  categories: SharedProjectCategory[];
  categorizedStartups: CategorizedStartupForHeader[];
  refetchMainQuery: () => void;
}) {
  const usersData = useGetAllUsersQuery();
  const [upsertOwner] = useUpsertStartupListOwnerMutation();

  const { isBelowMd } = useBreakpoints();
  const { isStaff, user: currentUser } = useAuth();
  const { subdomain: organizationSubdomain } =
    useCurrentOrganizationFromContext();

  const { palette, zIndex } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [openSourcingComment, setOpenSourcingComment] = useState(false);
  const [openArchiveListModal, setOpenArchiveListModal] =
    useState<boolean>(false);

  const [addStartupInProgress, setAddStartupInProgress] = useState(false);

  const isFavourites = currentStartupList.source === 'favourites';

  const { isOwnedByGD, owner } = getListOwnerDetails({
    collaborators: currentStartupList?.collaborators,
  });

  const addOrUpdateOwner = async (userId: number) => {
    const owner = await upsertOwner({
      variables: {
        object: {
          startup_list_id: currentStartupList.id,
          user_id: userId,
        },
      },
    });

    captureAnalyticsEvent('StartupList Owner Added/Modified', {
      startupListCollaboratorId:
        owner?.data?.upsert_startup_list_owner?.startup_list_collaborator_id ??
        null,
    });
  };

  const hasOwner = currentStartupList.collaborators.some(
    c => c.role === 'owner',
  );

  const collaborators = currentStartupList.collaborators.filter(c =>
    hasOwner ? c.role !== 'owner' : c.role !== 'creator',
  );

  const scoutingComment = currentStartupList.sourcing_order?.comment;
  const allowEdit = currentStartupList.source !== 'favourites';

  const isLandscape = currentStartupList?.result_type === 'landscape';
  const isDiscoveryAccount = organizationSubdomain === 'discovery';

  const isSourcingInProgress =
    !!currentStartupList.sourcing_order &&
    currentStartupList.sourcing_order.status === 'in_progress';

  const [bulkImportModal, showBulkImportModal] = useBulkImportModal({
    startupListId: currentStartupList.id,
    categoryId: categories[0] ? categories[0].id : null,
    invalidateCache: refetchMainQuery,
  });

  const invalidateCache = useCallback(() => {
    refetchMainQuery();
  }, [refetchMainQuery]);

  const { showCreateNewCategoryModal } = useCreateNewCategoryModal({
    startupListId: currentStartupList.id,
    hasZeroCategories: categories.length === 0,
  });

  const [updateDiscoveryItemSourceMutation] =
    useUpdateDiscoveryItemSourceMutation();

  const [startupSupplierIDs, setStartupSupplierIDs] = useState<number[]>(
    categorizedStartups.map(startup => startup.id),
  );

  useEffect(() => {
    setStartupSupplierIDs(categorizedStartups.map(startup => startup.id));
  }, [categorizedStartups]);

  const isListEmpty = !Boolean(startupSupplierIDs.length);

  const isDiscoveryItem = Boolean(
    currentStartupList.source === 'discovery_section',
  );

  const convertListToDiscoveryItem = async () => {
    const updateListSourceResponse = await updateDiscoveryItemSourceMutation({
      variables: {
        id: currentStartupList.id,
        source: 'discovery_section',
      },
    });
    if (updateListSourceResponse.data?.update_startup_lists_by_pk) {
      enqueueSnackbar('List converted to Discovery Item!', {
        variant: 'success',
        action: (
          <Button
            component={Link}
            href={`${buildAdminPlatformDomain()}/discovery-items`}
            target='_blank'
          >
            Navigate to Startups Admin
          </Button>
        ),
      });
    }
  };

  const { toggleFullscreen } = useContext(FullscreenContext);

  return (
    <Box marginBottom={2} data-testid='header'>
      <Stack
        direction='row'
        sx={{
          marginBottom: 0.5,
          flexWrap: isBelowMd ? 'wrap' : 'nowrap',
          flexShrink: isBelowMd ? 'inherit' : 0,
          gap: 1,
        }}
        alignItems='center'
        justifyContent='space-between'
        zIndex={zIndex.drawer}
        bgcolor={palette.background.paper}
      >
        <Title currentStartupList={currentStartupList} />
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          marginLeft={isBelowMd ? 0 : 'auto'}
          flexWrap={isBelowMd ? 'wrap' : 'nowrap'}
          alignSelf='self-start'
        >
          <ShareStartupList
            currentStartupListId={currentStartupList.id}
            startupsCount={categorizedStartups.length}
          />
          {isStaff && isSourcingInProgress && (
            <DeliverSourcing
              currentStartupList={currentStartupList}
              hasResults={categories.some(
                category => category.categorized_suppliers.length > 0,
              )}
            />
          )}
          <Tooltip title={'Go full screen'}>
            <span>
              <IconButton
                onClick={() => {
                  toggleFullscreen();
                }}
              >
                <FullscreenIcon />
              </IconButton>
            </span>
          </Tooltip>
          {!isFavourites && (
            <MoreMenu>
              {!currentStartupList.sourcing_order && (
                <OrderSourcingOnStartupListDetails
                  startupList={currentStartupList}
                  invalidateCache={invalidateCache}
                />
              )}
              {!isLandscape && [
                <MenuItem
                  sx={{ color: 'text.secondary' }}
                  onClick={() => showCreateNewCategoryModal()}
                  key='menu-item-turn-into-landscape'
                >
                  <GridViewIcon fontSize='small' sx={{ marginRight: 0.5 }} />
                  <Typography variant='body1'>Turn into Landscape</Typography>
                </MenuItem>,
              ]}
              <DuplicateListMenuItem listId={currentStartupList.id} />
              <ExportPDFMenuItem
                currentLandscapeCategoryId={false}
                currentStartupListId={currentStartupList.id}
              />
              <ExportCSVMenuItem
                currentLandscapeCategoryId={false}
                currentStartupListId={currentStartupList.id}
              />
              <MenuItem
                sx={{ color: 'text.secondary' }}
                onClick={() => setOpenArchiveListModal(true)}
              >
                <ArchiveIcon fontSize='small' sx={{ marginRight: 0.5 }} />
                <Typography variant='body1'>Archive</Typography>
              </MenuItem>

              <Tooltip
                title={
                  isLandscape
                    ? 'Before you can import, please select a category below!'
                    : ''
                }
                placement='right'
              >
                {/* Needed to get the tooltip working when the below component is disabled */}
                <span>
                  <MenuItem
                    sx={{ color: 'text.secondary' }}
                    onClick={() => showBulkImportModal()}
                    disabled={isLandscape}
                  >
                    <InputIcon fontSize='small' sx={{ marginRight: 0.5 }} />
                    <Typography variant='body1'>Import from file</Typography>
                  </MenuItem>
                </span>
              </Tooltip>
              {isStaff && (
                <Box>
                  {isDiscoveryAccount && (
                    <Tooltip
                      title={
                        isDiscoveryItem
                          ? 'Discovery Items are not editable. Convert back to list.'
                          : ''
                      }
                      placement='right'
                    >
                      <span>
                        <MenuItem
                          sx={{ color: palette.secondary.main }}
                          onClick={convertListToDiscoveryItem}
                        >
                          <DiscoverLetterIcon
                            fontSize='small'
                            sx={{ marginRight: 0.5 }}
                          />
                          <Typography variant='body1'>
                            Convert to Discovery Item
                          </Typography>
                        </MenuItem>
                      </span>
                    </Tooltip>
                  )}
                </Box>
              )}
              {currentUser.isContentEditor && (
                <>
                  <EnrichListMenuItem
                    categorizedStartups={categorizedStartups}
                    title={currentStartupList.title}
                    isListEmpty={isListEmpty}
                  />
                  <MenuItem
                    sx={{ color: palette.secondary.main }}
                    component={Link}
                    href={`${buildAdminPlatformDomain()}/startups?startupListId=${
                      currentStartupList.id
                    }`}
                    target='_blank'
                  >
                    <OpenInNewIcon fontSize='small' sx={{ marginRight: 0.5 }} />
                    <Typography variant='body1'>View in Admin</Typography>
                  </MenuItem>
                </>
              )}
            </MoreMenu>
          )}
        </Stack>
        {bulkImportModal}
        {openArchiveListModal && (
          <ConfirmArchiveListModal
            id={currentStartupList.id}
            archiveType='list'
            title={currentStartupList.title}
            onHide={() => setOpenArchiveListModal(false)}
            confirmClickSource='list'
          />
        )}
        {addStartupInProgress && (
          <AddStartupDialog
            open={addStartupInProgress}
            handleClose={() => setAddStartupInProgress(false)}
            startupListId={currentStartupList.id}
            projectCategoryId={categories[0]?.id}
            navigateToLibrary={false}
            title={'Add startup to the current list'}
          />
        )}
      </Stack>
      {allowEdit && (
        <Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            marginBottom={1}
          >
            <StartupListTagsSelect startupListId={currentStartupList.id} />
            <ShowHideChartButton
              categorizedStartups={categorizedStartups}
              currentStartupList={{
                id: currentStartupList.id,
                is_startup_charts_data_shown:
                  currentStartupList.is_startup_charts_data_shown,
              }}
            />
          </Stack>
          <Stack
            direction='row'
            alignItems='baseline'
            spacing={2}
            marginLeft={'0px !important'}
            alignSelf='baseline'
          >
            <>
              <Typography
                sx={{ display: 'inline-flex', alignItems: 'center' }}
                variant='body2'
                color='text.secondary'
                component={'div'}
              >
                {isOwnedByGD && (
                  <GDLogo
                    size={20}
                    sxProps={{
                      display: 'inline-flex',
                      marginTop: 0.1,
                    }}
                  />
                )}
                <EditOwner
                  owner={owner}
                  updateOwner={addOrUpdateOwner}
                  options={
                    usersData?.data?.users.map(u => ({
                      ...u.person,
                      // Warning: Here addOrUpdateOwner needs user.id but EditOwner expects a Person
                      id: u.id,
                    })) || []
                  }
                  removeOwner={() => addOrUpdateOwner(0)}
                />
              </Typography>
              <Divider orientation='vertical' sx={{ height: 15 }} />
            </>
            {collaborators.length > 0 && (
              <>
                <CollaboratorsList
                  collaborators={collaborators
                    .map(c => {
                      const collaborator: Collaborator = {
                        updated_at: c.updated_at,
                        full_name: c.user.person.full_name,
                        email: c.user.person.email,
                      };
                      return collaborator;
                    })
                    .filter(
                      stakeholder =>
                        stakeholder !== null && stakeholder !== undefined,
                    )}
                />
                <Divider orientation='vertical' sx={{ height: 15 }} />
              </>
            )}
            {scoutingComment && !isSourcingInProgress && (
              <>
                <Typography
                  onClick={() => setOpenSourcingComment(true)}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    cursor: 'pointer',
                    color: palette.info.main,
                    fontSize: 14,
                    lineHeight: 1.6,
                  }}
                >
                  Sourcing comment
                </Typography>
                <Divider orientation='vertical' sx={{ height: 15 }} />
              </>
            )}
            <LinkListToLead
              startupList={{
                id: currentStartupList.id,
                title: currentStartupList.title,
                visibility: currentStartupList.visibility,
              }}
            />
            <Divider orientation='vertical' sx={{ height: 15 }} />
          </Stack>
        </Stack>
      )}
      {/* TODO: Rename => Scouting to Sourcing */}
      {scoutingComment && allowEdit && (
        <ScoutingCommentModal
          isOpen={openSourcingComment}
          onHide={() => setOpenSourcingComment(false)}
          scoutingComment={scoutingComment}
        />
      )}
    </Box>
  );
}

// TODO: Refactor and inject functionality and not conditionals
export const ConfirmArchiveListModal = ({
  archiveType,
  confirmClickSource,
  onHide,
  title,
  id,
}: {
  id: number;
  title: string;
  onHide: () => void;
  confirmClickSource: 'order' | 'list' | 'lead' | 'poc';
  archiveType: 'list' | 'project';
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [archiveStartupList] = useArchiveStartupListMutation();
  const [archiveProject] = useArchiveProjectMutation();
  const { cache: apolloCache } = useApolloClient();

  const handleArchiveList = async (projectId: number) => {
    try {
      if (archiveType === 'list') {
        await archiveStartupList({ variables: { startupListId: id } });

        apolloCache.evict({
          id: apolloCache.identify({ __typename: 'startup_lists', id }),
        });
      } else {
        await archiveProject({ variables: { projectId: id } });

        apolloCache.evict({
          id: apolloCache.identify({ __typename: 'projects', id }),
        });
      }

      apolloCache.gc();

      enqueueSnackbar(
        `${
          archiveType === 'project'
            ? 'PoC has been successfully deleted'
            : 'List has been successfully archived'
        }
          `,
        {
          variant: 'success',
        },
      );

      if (confirmClickSource === 'list') {
        navigate(PATH_ROOT.lists.root);
      }

      if (confirmClickSource === 'order') {
        navigate(PATH_ROOT.sourcings.list);
      }

      if (confirmClickSource === 'lead') {
        navigate(PATH_ROOT.projectLeads.list);
      }

      if (confirmClickSource === 'poc') {
        navigate(PATH_ROOT.projects.pocs);
      }
      closeSnackbar(`delete-startup-list-${projectId}`);
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Failed archiving ${title}`, {
        variant: 'error',
      });
    }
  };
  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography sx={{ marginTop: 2 }}>
          Are you sure you want to{' '}
          {archiveType === 'project' ? 'delete' : 'archive'} {title}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => handleArchiveList(id)}
          variant='contained'
          color='error'
        >
          {archiveType === 'project' ? 'Delete' : 'Archive'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Title = ({
  currentStartupList,
}: {
  currentStartupList: StartupListForDetail;
}) => {
  const [updateStartupListTitle] = useUpdateStartupListTitleMutation();
  const [upsertCollaborator] = useCreateStartupListCollaboratorMutation();

  const updateListTitle = async (newTitle: string) => {
    await updateStartupListTitle({
      variables: {
        startupListId: currentStartupList.id,
        title: newTitle,
      },
    });

    await upsertCollaborator({
      variables: {
        object: {
          role: 'editor',
          startup_list_id: currentStartupList.id,
        },
      },
    });
  };

  const location = useLocation();
  const locationState =
    (location.state as {
      backFromProjectDetails?: string;
      backToPage?: string;
    }) || {};

  const allowEdit = currentStartupList.source !== 'favourites';

  const navigateBackTo =
    locationState?.backToPage ||
    locationState?.backFromProjectDetails ||
    PATH_ROOT.lists.root;

  return (
    // Why the min-width? https://css-tricks.com/flexbox-truncated-text/#aa-the-solution-is-min-width-0-on-the-flex-child
    <Stack flexGrow={1} minWidth={0}>
      <DetailsHeaderTitle
        {...(allowEdit
          ? {
              readonly: false,
              updateTitle: updateListTitle,
            }
          : {
              readonly: true,
            })}
        navigateBackTo={navigateBackTo}
        title={currentStartupList.title}
      />
    </Stack>
  );
};
