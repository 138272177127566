import { AvatarGroup, Box, SxProps, Tooltip, useTheme } from '@mui/material';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';

const AvatarViews = ({ uniqueViewEmails }: { uniqueViewEmails: string[] }) => {
  const { spacing } = useTheme();
  const sx: SxProps = {
    width: spacing(3),
    height: spacing(3),
    fontSize: '12px',
    margin: 'inherit',
  };

  return (
    <AvatarGroup spacing={0.5}>
      {uniqueViewEmails.map(email => (
        <Tooltip title={`Seen by: ${email}`} key={`avatar-views-${email}`}>
          <Box sx={{ background: 'transparent !important' }}>
            <BaseInitialsAvatar full_name={email} sx={sx} />
          </Box>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default AvatarViews;
