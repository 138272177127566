import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Suppliers } from 'apollo/generated/sdkShared';
import { FALLBACK_REFERENCED_CUSTOMER_LOGO_URL } from 'components/AvatarGroup';
import { useCorporationsDomainContext } from 'contexts/CorporationsDomainProvider';
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import getReferencedCustomersAndSuppliers from 'utils/getReferencedCustomersAndSuppliers';
import { CorporateCustomer } from './StartupInfoSidePanel/useStartupDetailsFormik';

export type StartupForCategoryReferencedCustomers = Pick<
  Suppliers,
  'id' | 'name' | 'funding' | 'founded_year' | 'logo_url'
> & {
  startup_corporate_customers: {
    corporate_customer: Pick<CorporateCustomer, 'name' | 'domain' | 'logo_url'>;
  }[];
};

type ReferenceCustomerProps = {
  domain?: string;
  name?: string | null;
  logo_url?: string | null;
  suppliers?: { name: string; id: number; logo_url?: string | null }[];
  size: number;
  projectId: number | null;
  allowHoverDropDown?: boolean;
};

type ReferenceCustomersProps = {
  customers: {
    domain: string;
    logo_url?: string | null;
    suppliers: { name: string; id: number }[];
    projectId: number | null;
  }[];
  gap: number;
  size: number;
  maxCap?: number;
  containerSx?: SxProps;
  allowHoverDropDown?: boolean;
};

type CategoryReferenceCustomersProps = {
  startups: StartupForCategoryReferencedCustomers[];
  size?: number;
  gap?: number;
  maxCap?: number;
  containerSx?: SxProps;
  renderAllSx?: SxProps;
  allowHoverDropDown?: boolean;
};

// TODO: render reference customers by priority

export default function CategoryReferenceCustomers({
  startups,
  size = 35,
  gap = 0,
  maxCap,
  renderAllSx,
  containerSx,
  allowHoverDropDown,
}: CategoryReferenceCustomersProps) {
  const { startupsSupplierMapping } = useCorporationsDomainContext();

  const referenceCustomersWithSuppliersList = useMemo(() => {
    const { referenceCustomersWithSuppliers } =
      getReferencedCustomersAndSuppliers({
        startups,
      });
    return referenceCustomersWithSuppliers.map(item => ({
      ...item,
      projectId: startupsSupplierMapping[item.domain as string],
    }));
  }, [startupsSupplierMapping, startups]);

  if (!referenceCustomersWithSuppliersList?.length) return null;

  if (renderAllSx) {
    return (
      <Stack direction='row' flexWrap='wrap' gap={`${gap}px`} sx={renderAllSx}>
        {referenceCustomersWithSuppliersList.map((customer, index) => (
          <ReferenceCustomerItem
            key={`${customer.domain}-${index}`}
            {...customer}
            size={size}
            allowHoverDropDown={allowHoverDropDown}
          />
        ))}
      </Stack>
    );
  }

  return (
    <ReferenceCustomers
      customers={referenceCustomersWithSuppliersList}
      size={size}
      gap={gap}
      maxCap={maxCap}
      containerSx={containerSx}
      allowHoverDropDown={allowHoverDropDown}
    />
  );
}

export const ReferenceCustomers = ({
  customers,
  size,
  gap = 0,
  maxCap,
  containerSx,
  allowHoverDropDown,
}: ReferenceCustomersProps) => {
  const { palette } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [refCustomersOptions, setRefCustomersOptions] = useState({
    max: 0,
    rest: customers.length,
  });

  const handleWindowResize = useDebouncedCallback(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      const maxWithoutCap = Math.floor(width / (size + gap * 2.5));
      const max = maxCap ? Math.min(maxWithoutCap, maxCap) : maxWithoutCap;
      const rest = customers.length - max;

      setRefCustomersOptions({ max, rest });
    }
  }, 200);

  useLayoutEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <Stack
      direction='row'
      ref={ref}
      gap={`${gap}px`}
      width='100%'
      overflow='hidden'
      sx={containerSx}
    >
      {customers.slice(0, refCustomersOptions.max).map((props, index) => (
        // eslint-disable-next-line
        <Fragment key={props.domain}>
          {index > 0 && (
            <Divider
              orientation='vertical'
              sx={{
                height: 8,
                marginY: 'auto',
                backgroundColor: palette.grey[500],
              }}
            />
          )}
          <ReferenceCustomerItem
            {...props}
            size={size}
            allowHoverDropDown={allowHoverDropDown}
          />
        </Fragment>
      ))}
      {refCustomersOptions.rest > 0 && (
        <Avatar
          sx={{
            width: size,
            height: size,
            backgroundColor: 'transparent',
            color: palette.grey[500],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
          }}
        >
          +{refCustomersOptions.rest}
        </Avatar>
      )}
    </Stack>
  );
};

const ReferenceCustomerItem = ({
  projectId,
  domain,
  name,
  logo_url,
  suppliers,
  size,
  allowHoverDropDown = true,
}: ReferenceCustomerProps) => {
  const { palette } = useTheme();

  return allowHoverDropDown ? (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.white',
            border: 'solid 1px lightgrey',
            paddingBottom: 1,
          },
        },
      }}
      title={
        <>
          <Typography
            variant='body2'
            sx={{
              color: palette.text.primary,
              fontSize: '0.75rem',
              fontWeight: 800,
              textAlign: 'center',
              marginBottom: 0.5,
            }}
          >
            {name || domain}
          </Typography>
          {suppliers && suppliers.length > 0 && (
            <List
              dense
              sx={{
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              {suppliers.map(supplier => (
                <ListItem
                  key={supplier.id + 'key'}
                  sx={{ marginX: 0.5, padding: 0 }}
                >
                  <Box
                    component='img'
                    src={
                      supplier.logo_url ?? FALLBACK_REFERENCED_CUSTOMER_LOGO_URL
                    }
                    alt={supplier.name}
                    sx={{
                      width: '20px',
                      height: '20px',
                      marginRight: '5px',
                      borderRadius: '50%',
                    }}
                  />
                  <ListItemText
                    primary={supplier.name}
                    primaryTypographyProps={{
                      variant: 'caption',
                      color: 'black',
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </>
      }
    >
      <Stack sx={{ position: 'relative' }}>
        <ClickableReferenceAvatar
          projectId={projectId}
          domain={domain}
          logoUrl={logo_url}
          size={size}
        />
      </Stack>
    </Tooltip>
  ) : (
    <Stack sx={{ position: 'relative' }}>
      <ClickableReferenceAvatar
        projectId={projectId}
        domain={domain}
        logoUrl={logo_url}
        size={size}
      />
    </Stack>
  );
};

const ClickableReferenceAvatar = ({
  projectId,
  domain,
  logoUrl,
  size,
}: {
  projectId?: number | null;
  domain?: string;
  logoUrl?: string | null;
  size: number;
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const baseAvatarStyles = {
    width: size,
    height: size,
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  };

  const clickableAvatarStyles = {
    ...baseAvatarStyles,
    cursor: 'pointer',
    border: '1px solid transparent',
    '&:hover': {
      borderColor: palette.primary.light,
      background: palette.grey[0],
    },
    zIndex: 2,
  };

  const nonClickableAvatarStyles = {
    ...baseAvatarStyles,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.2)',
      zIndex: 1,
    },
    '& img': {
      opacity: 0.8,
      transition: 'opacity 0.2s ease-in-out',
    },
  };

  const handleClick = () => {
    navigate(`/discovery/${projectId}`, {
      state: {
        backToPage: window.location.pathname,
      },
    });
  };

  return (
    <Avatar
      onClick={projectId ? handleClick : undefined}
      sx={projectId ? clickableAvatarStyles : nonClickableAvatarStyles}
      alt={domain}
      src={logoUrl || ''}
      role={projectId ? 'button' : undefined}
    >
      {domain && domain[0]}
    </Avatar>
  );
};

export const ReferenceCustomersColumn = ({
  referenceCustomerList,
  allowHoverDropDown,
}: {
  referenceCustomerList: {
    domain: string;
    name?: string | null;
    logo_url?: string | null;
    suppliers?:
      | {
          name: string;
          logo_url?: string | null;
          id: number;
        }[]
      | undefined;
    size?: number;
  }[];
  allowHoverDropDown?: boolean;
}) => {
  const { startupsSupplierMapping } = useCorporationsDomainContext();

  const referenceCustomersWithSuppliersList = useMemo(() => {
    return (referenceCustomerList || []).map(item => ({
      ...item,
      projectId: startupsSupplierMapping[item.domain as string],
    }));
  }, [referenceCustomerList, startupsSupplierMapping]);

  return (
    <AvatarGroup
      sx={{
        '.MuiAvatarGroup-avatar:first-of-type': {
          fontSize: '10px !important',
          height: '25px !important',
          width: '25px !important',
        },
        '.MuiAvatar-root:last-child': {
          marginLeft: '-8px !important',
        },
        '.MuiAvatarGroup-avatar:not(:last-of-type)': {
          background: 'transparent !important',
          border: 'none',
          padding: ({ spacing }) => spacing(0.5),
        },
      }}
      max={6}
    >
      {referenceCustomersWithSuppliersList?.map((customer, index) => (
        <ReferenceCustomerItem
          key={`${customer.domain}-${index}`}
          {...customer}
          size={25}
          allowHoverDropDown={allowHoverDropDown}
        />
      ))}
    </AvatarGroup>
  );
};
