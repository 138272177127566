import {
  Add,
  ArrowBack,
  ArrowForward,
  Delete,
  Edit,
} from '@mui/icons-material';
import ClearFilledIcon from '@mui/icons-material/Cancel';
import CheckFilledIcon from '@mui/icons-material/CheckCircle';
import HelpFilledIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Button,
  Link,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { captureException } from '@sentry/react';
import {
  useUpdateCategorizedStartupMutation,
  useUpdateStartupListCategoryCustomDimensionsSchemaMutation,
} from 'apollo/generated/sdkInnovationManager';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import MoreMenu from 'components/MoreMenu';
import { BaseDialog } from 'components/base/BaseDialog';
import TrendLineChart from 'components/charts/TrendLineChart';
import { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { memo, useContext, useRef, useState } from 'react';
import { parseLinksInText } from 'utils/general';
import {
  CustomDimensionCellJSON,
  CustomFeatureHeader,
  SharedCategorizedSupplier,
  Tribool,
} from '../../@types/shared';
import { OnChangeCustomField } from './CustomColumnCell';
import { StartupsTableContext } from './shared/StartupsTableContext';
import { useReorderCustomHeaders } from './useReorderCustomHeaders';
import { useSetInitialHeaderWidth } from './useSetInitialHeaderWidth';

export const TREND_CHART_WIDTH = 130;
export const TREND_CHART_WIDTH_WITH_PADDING = TREND_CHART_WIDTH + 14;

const CustomDimensionTribool = memo(({ tribool }: { tribool: Tribool }) => {
  const theme = useTheme();

  if (tribool === 'yes') {
    return <CheckFilledIcon color='primary' />;
  } else if (tribool === 'no') {
    return <ClearFilledIcon sx={{ color: theme.palette.error.main }} />;
  } else if (tribool === 'unknown' || !tribool) {
    return <HelpFilledIcon sx={{ color: theme.palette.grey[600] }} />;
  } else {
    console.error(`Unknown tribool value: ${tribool}`);
    return <HelpFilledIcon sx={{ color: theme.palette.grey[600] }} />;
  }
});

CustomDimensionTribool.displayName = 'CustomDimensionTribool';

type CustomFeatureCellReadonlyProps = {
  value: CustomDimensionCellJSON['value'];
  type: CustomFeatureHeader['type'];
};

type CustomFeatureCellProps = {
  value: CustomDimensionCellJSON['value'];
  type: CustomFeatureHeader['type'];
  onChange: OnChangeCustomField;
  categorizedStartup: SharedCategorizedSupplier;
};

export type CustomSchemaDimensionType = Pick<
  CustomFeatureCellProps,
  'type' | 'value'
>;

const CustomFeatureTriboolReadonly = ({
  value,
}: {
  value: CustomDimensionCellJSON['value'];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <CustomDimensionTribool tribool={value as Tribool} />
    </Box>
  );
};

const CustomFeatureTextReadonly = ({
  value,
}: Partial<CustomDimensionCellJSON>) => {
  const { palette } = useTheme();

  return (
    <ExpandedGridCell
      wrapperSxProps={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: value ? 'flex-start' : 'center',
        width: '100%',
      }}
      value={
        parseLinksInText({
          textToParse: (value as string) || '-',
          renderLink: (link, domain) => (
            <Link
              onClick={event => event.stopPropagation()}
              key={`${link}-${domain}`}
              href={link}
              target='_blank'
              rel='noreferrer'
              style={{ color: palette.secondary.main }}
            >
              {domain}
            </Link>
          ),
        }) || ''
      }
      multiLineElipsis={true}
      numberOfLines={3}
    />
  );
};

const CustomFeatureNumberReadonly = ({
  value,
}: Partial<CustomDimensionCellJSON>) => {
  return (
    <ExpandedGridCell
      wrapperSxProps={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: value ? 'flex-start' : 'center',
        width: '100%',
      }}
      value={value}
    />
  );
};

const CustomFeatureLineReadOnly = ({
  value,
}: Partial<CustomDimensionCellJSON>) => {
  let parsedValue = [] as number[];
  try {
    parsedValue = Array.from(JSON.parse((value as string) || '[]')).map(v =>
      parseInt((v as string | number).toString()),
    );
  } catch {
    return null;
  }

  if (parsedValue.length === 0) return null;

  return (
    <TrendLineChart
      series={[
        {
          // @ts-expect-error: TODO: FIXME!
          data: parsedValue.map((v: number, i: number) => ({ x: i + 1, y: v })),
        },
      ]}
      // Required to set the directly in the cell and render the chart absolutely within
      sx={{
        width: '100%',
      }}
      message=''
      height={50}
      fixedWidth={TREND_CHART_WIDTH}
    />
  );
};

export const CustomFeatureCellReadonly = ({
  type,
  value,
}: CustomFeatureCellReadonlyProps) => {
  switch (type) {
    case 'tribool':
      return <CustomFeatureTriboolReadonly value={value} />;
    case 'line':
      return <CustomFeatureLineReadOnly value={value} />;
    case 'number':
      return <CustomFeatureNumberReadonly value={value} />;
    default:
      return <CustomFeatureTextReadonly value={value} />;
  }
};

export const ColumnSettings = () => {
  const { setState } = useContext(StartupsTableContext)!;

  return (
    <Tooltip title='Add/Toggle columns'>
      <Button
        sx={{ marginLeft: 0.75 }}
        fullWidth
        startIcon={<Add />}
        onClick={() =>
          setState(prevState => ({
            ...prevState,
            editComparisonColumnModalSettings: {
              isActive: true,
              customDimensionColumnToEdit: '',
            },
          }))
        }
      >
        Add
      </Button>
    </Tooltip>
  );
};

export const CustomDimensionHeaderReadonly = ({
  name,
  type,
  field,
  isMustHave,
}: {
  name: string;
  field: string;
  type: CustomFeatureHeader['type'];
  isMustHave: CustomFeatureHeader['isMustHave'];
}) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  useSetInitialHeaderWidth({ field, textRef, type, readonly: true });

  return (
    <>
      {isMustHave && (
        <Stack alignItems='center' direction='row' marginRight={0.5}>
          <Tooltip title='Must-Have'>
            <StarIcon sx={{ fontSize: '0.85rem' }} />
          </Tooltip>
        </Stack>
      )}
      <Tooltip title={name} placement='top-end'>
        <Typography
          variant='body2'
          fontWeight={600}
          ref={textRef}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 'unset',
          }}
        >
          {name}
        </Typography>
      </Tooltip>
    </>
  );
};

export const EditCustomDimensionHeader = ({
  name,
  field,
  selectedCategory,
  type,
  isMustHave,
}: {
  name: string;
  field: string;
  selectedCategory?: {
    id: number;
    custom_dimensions_schema: CustomFeatureHeader[];
  };
  type: CustomFeatureHeader['type'];
  isMustHave: CustomFeatureHeader['isMustHave'];
}) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  useSetInitialHeaderWidth({ field, textRef, type });

  const [updateCategoryDimensionsSchema] =
    useUpdateStartupListCategoryCustomDimensionsSchemaMutation();
  const { reorderColumn, reorderColumnPlan } =
    useReorderCustomHeaders(selectedCategory);
  const theme = useTheme();
  const [updateCategorizedStartup] = useUpdateCategorizedStartupMutation();
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const { categorizedStartupsById, setState } =
    useContext(StartupsTableContext)!;
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await updateCategoryDimensionsSchema({
        variables: {
          // eslint-disable-next-line
          project_category_id: selectedCategory?.id!,
          custom_dimensions_schema: (
            selectedCategory?.custom_dimensions_schema || []
          ).filter(c => c.name !== name),
        },
      });

      // remove the dimension from all startups
      for (const categorizedStartupId in categorizedStartupsById) {
        const copiedStartupCategorizedDimensions = omit(
          categorizedStartupsById[categorizedStartupId]
            .custom_dimensions_json_v1,
          [name],
        );

        const categorizedStartup = {
          ...categorizedStartupsById[categorizedStartupId],
        };

        await updateCategorizedStartup({
          variables: {
            id: categorizedStartup.id,
            updateData: {
              custom_dimensions_json_v1: copiedStartupCategorizedDimensions,
            },
          },
        });
      }
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Error while deleting custom dimension', {
        variant: 'error',
      });
    }

    setIsShowConfirmationModal(false);
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      data-testid={`custom-column-header-${name}`}
      maxWidth={'300px'}
      gap={0.5}
    >
      {isMustHave && (
        <Stack alignItems='center' direction='row'>
          <Tooltip title='Must-Have'>
            <StarIcon sx={{ fontSize: '0.85rem' }} />
          </Tooltip>
        </Stack>
      )}
      <Tooltip title={name} placement='top-end'>
        <Typography
          ref={textRef}
          variant='body2'
          fontWeight={600}
          sx={{
            zIndex: theme.zIndex.modal,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 'unset',
          }}
        >
          {name}
        </Typography>
      </Tooltip>
      <Stack justifyContent='center'>
        <MoreMenu>
          <MenuItem
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                editComparisonColumnModalSettings: {
                  isActive: true,
                  customDimensionColumnToEdit: name,
                },
              }));
            }}
          >
            <Stack direction='row' spacing={0.5}>
              <Stack justifyContent='center'>
                <Edit fontSize='small' />
              </Stack>
              <Typography variant='body2'>Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={() => setIsShowConfirmationModal(true)}>
            <Stack direction='row' spacing={0.5}>
              <Stack justifyContent='center'>
                <Delete fontSize='small' />
              </Stack>
              <Typography variant='body2'>Delete</Typography>
            </Stack>
          </MenuItem>
          {reorderColumnPlan(name, 'right').reorderAllowed && (
            <MenuItem onClick={() => reorderColumn(name, 'right')}>
              <Stack direction='row' spacing={0.5}>
                <Stack justifyContent='center'>
                  <ArrowForward fontSize='small' />
                </Stack>
                <Typography variant='body2'>Move right</Typography>
              </Stack>
            </MenuItem>
          )}
          {reorderColumnPlan(name, 'left').reorderAllowed && (
            <MenuItem onClick={() => reorderColumn(name, 'left')}>
              <Stack direction='row' spacing={0.5}>
                <Stack justifyContent='center'>
                  <ArrowBack fontSize='small' />
                </Stack>
                <Typography variant='body2'>Move left</Typography>
              </Stack>
            </MenuItem>
          )}
        </MoreMenu>
      </Stack>
      {isShowConfirmationModal && (
        <BaseDialog
          heading='Remove custom column'
          onHide={() => setIsShowConfirmationModal(false)}
          onConfirm={onDelete}
        >
          <Typography variant='body2'>
            Are you sure that you want to remove column <b>{name}</b> from the
            comparison table?
          </Typography>
        </BaseDialog>
      )}
    </Stack>
  );
};
