import {
  useFilesWithSignedUrlsQuery,
  useGetAllTeamsQuery,
} from 'apollo/generated/sdkShared';
import { useMemo } from 'react';

export const useGetAllTeamsData = () => {
  const { data, loading } = useGetAllTeamsQuery();

  const teamAvatarFileIds = useMemo(
    () =>
      data?.teams.filter(t => !!t.logo_file_id).map(t => t.logo_file_id!) || [],
    [data?.teams],
  );

  const { data: signedFileUrl } = useFilesWithSignedUrlsQuery({
    skip: teamAvatarFileIds.length === 0,
    variables: { files_ids: teamAvatarFileIds },
    fetchPolicy: 'cache-and-network',
  });

  const avatarUrlMap = useMemo(
    () =>
      data?.teams.reduce<Record<number, string>>((acc, team) => {
        const signedUrl = signedFileUrl?.files_with_signed_urls?.data.find(
          file => file.id === team.logo_file_id,
        )?.signed_url;

        if (!signedUrl) {
          return acc;
        }

        return {
          ...acc,
          [team.id]: signedUrl,
        };
      }, {}),
    [data?.teams, signedFileUrl?.files_with_signed_urls?.data],
  );

  return {
    teamsData: data?.teams || [],
    loadingTeamsData: loading,
    avatarUrlMap,
  };
};
