import closeFill from '@iconify/icons-eva/close-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import attachmentIcon from '@iconify/icons-ic/attachment';
import roundSend from '@iconify/icons-ic/round-send';
import { Icon as IconifyIcon } from '@iconify/react';
import {
  Chat,
  Clear as ClearIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { alpha } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  EnumTableProjectStagesEnum,
  useDeprecatedGenerateSignedUploadUrlMutation,
} from 'apollo/generated/sdkInnovationManager';
import { Projects, StartupLists } from 'apollo/generated/sdkShared';
import { QuillEditor } from 'components/editor';
import { NotesContext } from 'contexts/NotesContext';
import { usePersonContext } from 'contexts/PersonContext';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { parseISO } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { isString, orderBy, trim } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { formatApiDate } from 'utils/datetime';
import { fData } from 'utils/formatNumber';
import { getFileExtension, getNameInitials, uploadToS3 } from 'utils/general';
import { MIconButton } from '../@material-extend';
import Markdown from '../Markdown';
import { CustomFile } from '../upload/UploadSingleFile';
import { stageTabsType } from './ProjectNotesSection';
import { getLabelStyleMap } from 'pages/dashboard/scoping/ProjectStageSelect';
import {
  PROJECTS_STAGE_MAPPING,
  VisibleProjectStages,
} from 'utils/projectStageEnum';
import { E2E_COLUMNS } from 'pages/dashboard/constants';

type Attachment = {
  id: number;
  file_id: number;
  url: string;
  name: string;
};

export type Note = {
  id: number;
  created_at: string;
  body: string;
  // @deprecated only for backwards compatibility
  DEPRECATED_attachment_urls: string[];
  // Uses the new file architecture
  attachments: Attachment[];
  created_by?: {
    id: number;
    person?: {
      email?: string | null;
      full_name?: string | null;
    } | null;
  } | null;
  isBuMember?: boolean;
  project_stage?: EnumTableProjectStagesEnum | null;
  label_type?: string | null;
  startup_id?: number | null;
  startup_name?: string | null;
  startup_logo_url?: string | null;
  project?:
    | (Pick<Projects, 'id'> & {
        startup_list?: Pick<StartupLists, 'id' | 'title'> | null;
      })
    | null;
  created_by_user_id?: number | null;
};

type CreateNoteFunction = (body: string) => Promise<number>;

type AddAttachmentToNoteFunction = ({
  noteId,
  attachmentUrls,
}: {
  noteId: number;
  attachmentUrls: string[];
}) => Promise<unknown>;

type InsertAttachmentFunction = ({
  file,
  documentUrl,
}: {
  file: CustomFile;
  documentUrl: string;
}) => void;

export type HandleNewNoteFunction = ({
  noteBody,
  selectedFiles,
}: {
  noteBody: string;
  selectedFiles: ArrayLike<File> | null;
}) => Promise<void>;

const stageFilterOptions = [
  { value: 'none', title: 'None' },
  ...E2E_COLUMNS.map(column => ({
    value: column.id,
    title: column.name,
  })),
];

// TODO: Split into a component to create notes and one to display notes
export const NotesSection = ({
  quilId,
  notes,
  loading,
  // -- TODO: Consume should pass all the create note logic
  // Old API for backwards compatibility
  insertAttachment,
  createNote,
  addAttachmentToNote,
  // -- New API for new files architecture
  handleNewNote,
  uploading: uploadingNewApi,
  //--
  creatingNoteInProgress,
  deleteNote,
  isProjectNotes = false,
  isPersonNotes = false,
  enableStageFiltering = false,
  additionalActions = null,
}: {
  quilId: string;
  notes?: Note[];
  loading: boolean;
  // @deprecated
  insertAttachment?: InsertAttachmentFunction;
  createNote?: CreateNoteFunction;
  addAttachmentToNote?: AddAttachmentToNoteFunction;
  // --
  handleNewNote?: HandleNewNoteFunction;
  uploading?: boolean;
  // --
  creatingNoteInProgress: boolean;
  isProjectNotes?: boolean;
  isPersonNotes?: boolean;
  enableStageFiltering?: boolean;
  deleteNote: (noteId: number) => Promise<unknown>;
  additionalActions?: ReactNode;
}) => {
  const { startupListPublicUUID: publicUUID } = useParams();
  const { setPersonModal, needPersonInformation } = usePersonContext();

  const [newNoteBody, setNewNoteBody] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<ArrayLike<File> | null>(
    null,
  );
  const [uploading, setUploading] = useState<boolean>(false);
  const inputOpenFileRef = useRef<HTMLInputElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [generateSignedUploadUrl] =
    useDeprecatedGenerateSignedUploadUrlMutation();

  const showOpenFileDlg = () => inputOpenFileRef?.current?.click();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    setSelectedFiles(files);
  };

  const handleRemoveFile = (file: File | string) => {
    const filteredItems = Array.from(selectedFiles!).filter(
      _file => _file !== file,
    );
    setSelectedFiles(filteredItems);
  };

  const handleNewNoteSubmit = async () => {
    const normalizedNoteBody = trim(newNoteBody);
    if (!normalizedNoteBody) {
      return;
    }

    if (handleNewNote) {
      await handleNewNote({
        noteBody: normalizedNoteBody,
        selectedFiles,
      });
      setSelectedFiles(null);

      return;
    }

    /**
     * @deprecated The approach below should be deprecated in favor of a handleNewNote function
     */
    if (!createNote || !addAttachmentToNote) {
      console.warn('NotesSection called without the right arguments');
      return;
    }

    try {
      const createdNoteId = await createNote(normalizedNoteBody);

      if (selectedFiles) {
        setUploading(true);
        const documentUrls: string[] = [];

        for (let i = 0; i < selectedFiles.length; i++) {
          const { documentUrl } = await handleFileUpload(selectedFiles[i]);
          documentUrls.push(documentUrl);
        }

        await addAttachmentToNote({
          noteId: createdNoteId,
          attachmentUrls: documentUrls,
        });

        setSelectedFiles(null);
        setUploading(false);
      }
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Failed submitting the note', {
        variant: 'error',
      });
    }
  };

  /**
   * @deprecated
   */
  const handleFileUpload = async (file: CustomFile) => {
    const { data } = await generateSignedUploadUrl({
      variables: {
        fileName: file.name,
        fileType: getFileExtension(file.name),
      },
    });
    // eslint-disable-next-line
    const { documentUrl, signedRequestUrl } = data?.generate_signed_upload_url!;

    uploadToS3(file, signedRequestUrl);
    if (insertAttachment) {
      insertAttachment({ file, documentUrl });
    }
    return { documentUrl };
  };

  const { noteStartup } = useContext(NotesContext);

  const [currentStageFilter, setCurrentStageFilter] =
    useState<stageTabsType | null>(null);

  const creatingNote = creatingNoteInProgress || uploading || uploadingNewApi;

  const notesInputPlaceholder = isPersonNotes
    ? 'Leave a note ...'
    : isProjectNotes
      ? `Leave a note ${noteStartup?.name ? 'for ${noteStartup.name}' : ''}...`
      : 'Is the technology / product / service interesting for you?\nDo you see any starting points for a collaboration with this startup?\nAre you interested in a call with the startup to get to know the team and solution?';

  return (
    <Stack spacing={2}>
      <List disablePadding style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedFiles &&
          Array.from(selectedFiles).map((file, i) => (
            <Box
              sx={{
                my: 1,
                py: 0.75,
                px: 2,
                marginRight: '0.25rem',
                padding: '0.25rem',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
                border: theme => `solid 1px ${theme.palette.divider}`,
                bgcolor: 'background.paper',
              }}
              key={`selected-files-${i}`}
            >
              <ListItemIcon sx={{ marginRight: '4px' }}>
                <IconifyIcon icon={fileFill} width={28} height={28} />
              </ListItemIcon>
              <ListItemText
                primary={isString(file) ? file : file.name.slice(0, 10)}
                secondary={isString(file) ? '' : fData(file.size || 0)}
                primaryTypographyProps={{ variant: 'overline' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
              {!creatingNote && (
                <MIconButton
                  edge='end'
                  size='small'
                  onClick={() => handleRemoveFile(file)}
                >
                  <IconifyIcon icon={closeFill} />
                </MIconButton>
              )}
            </Box>
          ))}
      </List>
      <Stack sx={{ width: '100%' }}>
        <QuillEditor
          id={quilId}
          sx={{ paddingBottom: 0.5 }}
          simple
          onChange={e => setNewNoteBody(e)}
          value={newNoteBody}
          readOnly={creatingNote}
          placeholder={notesInputPlaceholder}
          onKeyDown={e => {
            e.stopPropagation();
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
              if (publicUUID && needPersonInformation) {
                setPersonModal({ show: true });
              } else {
                handleNewNoteSubmit();
                setNewNoteBody('');
              }
            }
          }}
        />
        <Stack direction='row' justifyContent='space-between'>
          <Typography
            variant='caption'
            sx={{
              color: 'text.secondary',
              marginTop: '5px',
              alignSelf: 'flex-start',
            }}
          >
            Submit by hitting Ctrl/Cmd + Enter
          </Typography>
          <Stack direction='row' gap={1} marginTop={2}>
            {additionalActions}
            <IconButton
              onClick={() => showOpenFileDlg()}
              disabled={creatingNote}
            >
              <input
                ref={inputOpenFileRef}
                type='file'
                multiple
                style={{ display: 'none' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFileSelect(event)
                }
              />
              <IconifyIcon
                icon={attachmentIcon}
                width={24}
                height={24}
                rotate={3}
              />
            </IconButton>
            <LoadingButton
              loading={creatingNote}
              type='submit'
              sx={({ spacing, palette }) => ({
                width: spacing(5),
                height: spacing(5),
                minWidth: spacing(5),
                boxShadow: 'none !important',
                backgroundColor: 'transparent !important',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: `${palette.action.hover} !important`,
                },
              })}
              disabled={!newNoteBody.trim() || creatingNoteInProgress}
              onClick={async () => {
                if (publicUUID && needPersonInformation) {
                  setPersonModal({ show: true });
                } else {
                  await handleNewNoteSubmit();
                  setNewNoteBody('');
                }
              }}
              data-testid='submit-note'
            >
              <IconifyIcon icon={roundSend} width={24} height={24} />
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        sx={{ paddingX: 3.5 }}
      >
        {enableStageFiltering && (
          <FormControl variant='standard' sx={{ m: 1, minWidth: 125 }}>
            <InputLabel>Filter notes by stage</InputLabel>
            <Select
              value={currentStageFilter || 'none'}
              onChange={event => {
                const targetStage =
                  event.target.value === 'none'
                    ? null
                    : (event.target.value as stageTabsType);
                return setCurrentStageFilter(targetStage);
              }}
              endAdornment={
                currentStageFilter && (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setCurrentStageFilter(null)}
                      edge='end'
                      sx={{ marginRight: 2 }}
                    >
                      <ClearIcon fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {stageFilterOptions.map(filterOption => (
                <MenuItem value={filterOption.value} key={filterOption.value}>
                  {filterOption.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      {loading ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
        </>
      ) : (
        <NotesList
          notes={notes!}
          deleteNote={deleteNote}
          currentStageFilter={currentStageFilter}
          setCurrentStageFilter={setCurrentStageFilter}
        />
      )}
    </Stack>
  );
};

const NotesList = ({
  notes,
  deleteNote,
  currentStageFilter,
  setCurrentStageFilter,
}: {
  notes: Note[];
  deleteNote: (noteId: number) => Promise<unknown>;
  currentStageFilter: stageTabsType | null;
  setCurrentStageFilter: Dispatch<SetStateAction<stageTabsType | null>>;
}) => {
  const { user: currentUser } = useAuth();
  const { isSharedPage } = useSharedPagesContext();

  const { noteStartup, clearNotesContext } = useContext(NotesContext);

  const sortedNotes = useMemo(() => {
    let sortedNoteResults: Note[] = [];

    if (noteStartup) {
      const sortedByStartup = orderBy(
        notes.filter(note => note.startup_id === noteStartup.id),
        [note => parseISO(note.created_at)],
        ['desc'],
      );
      const sortedRest = orderBy(
        notes.filter(note => note.startup_id !== noteStartup.id),
        [note => parseISO(note.created_at)],
        ['desc'],
      );

      sortedNoteResults = [...sortedByStartup, ...sortedRest];
    } else {
      sortedNoteResults = orderBy(
        notes,
        [note => parseISO(note.created_at)],
        ['desc'],
      );
    }

    if (currentStageFilter) {
      sortedNoteResults = sortedNoteResults.filter(note => {
        if (!note.label_type) {
          return note;
        } else {
          return note.project_stage === currentStageFilter;
        }
      });
    }
    return sortedNoteResults;
  }, [noteStartup, currentStageFilter, notes]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const confirmNoteDeletion = (noteId: number) => {
    const note = notes.find(n => n.id === noteId);
    if (!note) return;

    let text = 'Are you sure you want to delete this note?';
    if (note.attachments.length > 0) {
      text = 'Are you sure you want to delete this note and its attachments?';
    }

    enqueueSnackbar(text, {
      variant: 'warning',
      key: `delete-note-${noteId}`,
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      onClose: (e, reason) => {
        if (reason === 'clickaway') {
          closeSnackbar(`delete-note-${noteId}`);
        }
      },
      persist: true,
      action: (
        <Button color='error' onClick={() => handleNoteDeletion(noteId)}>
          Delete
        </Button>
      ),
    });
  };

  const handleNoteDeletion = (noteId: number) => {
    deleteNote(noteId);
    closeSnackbar(`delete-note-${noteId}`);
  };

  const { openStartupSidePanel } = useStartupSidePanel();

  const theme = useTheme();
  const labelStyleMap = useMemo(() => getLabelStyleMap(theme), [theme]);

  const baseProjectStageChipSx = {
    width: 'fit-content',
    fontWeight: 600,
    paddingY: 0,
    fontSize: theme.typography.caption.fontSize,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  };

  type LabelStyleType = {
    backgroundColor: string;
    color: string;
  };

  const getProjectStageChipSx = (labelStyle: LabelStyleType) => ({
    ...baseProjectStageChipSx,
    backgroundColor: labelStyle.backgroundColor,
    '&:hover': {
      backgroundColor: alpha(labelStyle.backgroundColor, 0.8),
    },
    color: labelStyle.color,
  });

  return (
    <Timeline position='right'>
      {sortedNotes &&
        sortedNotes.map(note => {
          const label = note.project_stage as VisibleProjectStages;
          const labelStyle = labelStyleMap[label] || {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          };

          const projectStageChipSx = getProjectStageChipSx(labelStyle);

          const currentNoteStageTitle =
            PROJECTS_STAGE_MAPPING[
              note.project_stage as keyof typeof PROJECTS_STAGE_MAPPING
            ] || note.project_stage;

          return (
            <TimelineItem sx={{ display: 'flex' }} key={note.id}>
              <TimelineOppositeContent sx={{ flex: 0, padding: 0 }} />
              <TimelineSeparator>
                {note.startup_logo_url ? (
                  <Tooltip
                    title={`Open ${note.startup_name}${
                      note.startup_name!.endsWith('s') ? "'" : "'s"
                    } Profile`}
                    arrow
                    placement='top-start'
                  >
                    <Avatar
                      src={note.startup_logo_url}
                      onClick={() => {
                        clearNotesContext();
                        openStartupSidePanel(note.startup_id!);
                      }}
                      sx={{
                        height: 36,
                        width: 36,
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  </Tooltip>
                ) : (
                  <TimelineDot color='secondary'>
                    <Chat />
                  </TimelineDot>
                )}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  sx={{
                    p: 2,
                    bgcolor: 'grey.50012',
                  }}
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    width='100%'
                  >
                    <Avatar
                      alt={note.created_by?.person?.full_name || ''}
                      sx={({ spacing }) => ({
                        width: spacing(3),
                        height: spacing(3),
                        fontSize: 12,
                      })}
                    >
                      {getNameInitials(note.created_by?.person?.full_name)}
                    </Avatar>
                    <Typography
                      variant='subtitle2'
                      data-testid='note-card-title'
                    >
                      {note.created_by?.person?.full_name}{' '}
                      {note.startup_name && `on ${note.startup_name}`}
                      {note.project && !isSharedPage && (
                        <>
                          {' '}
                          in{' '}
                          <Link
                            to={PATH_ROOT.lists.details(note.project.id)}
                            color='secondary'
                            component={RouterLink}
                          >
                            {note.project.startup_list?.title}
                          </Link>
                        </>
                      )}
                    </Typography>
                    <Typography
                      variant='caption'
                      sx={{ color: 'text.secondary' }}
                    >
                      {formatApiDate(parseISO(note.created_at))}
                    </Typography>

                    <Stack
                      direction='row'
                      spacing={0.5}
                      flex={1}
                      justifySelf='flex-end'
                      justifyContent='flex-end'
                      alignItems='center'
                    >
                      {note.project_stage && (
                        <Tooltip
                          arrow
                          placement='top-start'
                          title={`Filter notes in ${currentNoteStageTitle}`}
                        >
                          <Chip
                            size='small'
                            label={currentNoteStageTitle}
                            clickable
                            onClick={() =>
                              setCurrentStageFilter(note?.project_stage || null)
                            }
                            sx={projectStageChipSx}
                          />
                        </Tooltip>
                      )}
                      {(currentUser.id === note.created_by?.id ||
                        currentUser.type !== 'bu_member') && (
                        <IconButton
                          onClick={() => confirmNoteDeletion(note.id)}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                  {!isSharedPage && note.isBuMember && (
                    <Typography
                      variant='subtitle2'
                      sx={{ marginTop: '5px', fontSize: '12px' }}
                    >
                      Email:{' '}
                      <Link
                        href={`mailto:${note.created_by?.person?.email}`}
                        target='_blank'
                        color='secondary'
                      >
                        {note.created_by?.person?.email}
                      </Link>
                    </Typography>
                  )}

                  <Typography
                    marginTop={2}
                    whiteSpace='pre-wrap'
                    variant='body2'
                    data-testid='note-card-body'
                    component='div'
                  >
                    {/* Required for handling the migrated notes from Stacker/GD1 */}
                    {note.body.startsWith('<') ? (
                      <Markdown>{note.body}</Markdown>
                    ) : (
                      note.body
                    )}
                  </Typography>
                </Paper>
                <List
                  disablePadding
                  style={{
                    display: 'flex',
                    marginTop: '0.25rem',
                    flexWrap: 'wrap',
                  }}
                >
                  {note.attachments.length > 0
                    ? note.attachments.map(a => (
                        <NoteAttachment key={a.id} name={a.name} url={a.url} />
                      ))
                    : note.DEPRECATED_attachment_urls.map(url => (
                        <NoteAttachment
                          key={url}
                          name={getFileNameFromUrl(url)}
                          url={url}
                        />
                      ))}
                </List>
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
};

const NoteAttachment = ({ name, url }: { name: string; url: string }) => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0.25rem',
        alignItems: 'center',
        marginRight: '0.25rem',
        borderRadius: 1,
        border: theme => `solid 1px ${theme.palette.divider}`,
        bgcolor: 'background.paper',
        marginBottom: '0.15rem',
      }}
    >
      <Link
        href={url}
        target='_blank'
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          color: 'rgb(51, 102, 255)',
        }}
      >
        <IconifyIcon color='#637381' icon={fileFill} width={24} height={24} />
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'caption' }}
        />
      </Link>
    </Stack>
  );
};

const getFileNameFromUrl = (string: string): string => {
  let newWord = '';
  const checkCharacter = string.includes('dl=')
    ? 'dl='
    : 's3.amazonaws.com/documents/';

  const index = string.indexOf(checkCharacter);
  const endIndex = index + checkCharacter.length;

  newWord +=
    checkCharacter === 'dl='
      ? new URL(string).searchParams.get('dl')
      : string
          // eslint-disable-next-line
          .split(/\-/)
          .join(' ')
          .slice(endIndex + 15);

  return newWord.length > 50 ? newWord.slice(0, 50) + '..' : newWord;
};
