import { Person } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { HeaderStyledLogo } from 'components/OrganizationPortalUtils/shared/OrganizationPortalHeader';
import { LoginPageType } from './useGetLoginCopy';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { EmptyListDataGrid } from 'components/dashboard/startupList/list/EmptyListDataGrid';

function LoginBackdrop({
  loginPageType,
  startupListTitle,
}: {
  loginPageType: LoginPageType;
  startupListTitle?: string;
}) {
  const { spacing, palette } = useTheme();
  const { logo_url } = useCurrentOrganizationFromContext();

  if (loginPageType === 'regular' || loginPageType === 'scoping') return null;

  return (
    <>
      <Stack
        data-testid='organization-portal-header'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.03) 0px 2px 4px',
          marginBottom: 6,
          paddingX: 4,
        }}
      >
        <Stack direction='row' alignItems={'center'} sx={{ marginBottom: 1 }}>
          <HeaderStyledLogo src={logo_url} />
        </Stack>
        <IconButton
          disabled
          sx={{
            padding: 0,
            width: spacing(6),
            height: spacing(6),
          }}
        >
          <Avatar>
            <Person />
          </Avatar>
        </IconButton>
      </Stack>
      <Container maxWidth='xxl' sx={{ position: 'relative', paddingX: 2 }}>
        <Stack
          sx={{
            position: 'absolute',
            top: spacing(16),
            left: spacing(4),
            right: spacing(4),
            width: `calc(100% - ${spacing(8)})`,
            gap: 2,
          }}
        >
          <Typography variant='h4'>{startupListTitle}</Typography>
          {loginPageType === 'benchmark' ? (
            <EmptyListDataGrid withSkeleton nbRows={7} showNewRow={false} />
          ) : (
            <Grid container spacing={2} marginTop={2}>
              {Array.from(Array(9).keys()).map(i => (
                <Grid key={i} item xs={6} lg={4}>
                  <Card>
                    <Skeleton variant='rounded' height={250} />
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Stack>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: palette.grey['700'],
          opacity: 0.2,
        }}
      />
    </>
  );
}

export { LoginBackdrop };
