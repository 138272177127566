import { Link, Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { HtmlRenderer } from 'react-pdf-html/dist/render';

const STYLESHEET = {
  div: {
    fontFamily: 'Helvetica',
  },
  strong: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  em: {
    fontFamily: 'Helvetica-Oblique',
    fontStyle: 'italic',
  },
  h1: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  h2: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  h3: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 800,
  },
  h4: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  h5: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  h6: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
};

const CustomHTML = ({ html, fontSize }: { html: string; fontSize: number }) => {
  const PDFLink: HtmlRenderer = props => {
    return (
      // eslint-disable-next-line
      <Link src={props.element.attributes.href}>
        {/* eslint-disable-next-line */}
        <Text>{props.element.innerHTML}</Text>
      </Link>
    );
  };

  const buildPDFTextComponent = ({
    fontSize,
    fontWeight,
  }: {
    fontSize: number;
    fontWeight: string;
  }): HtmlRenderer => {
    const renderText: HtmlRenderer = props => (
      // eslint-disable-next-line
      <Text style={props.element.style}>
        <Html
          // eslint-disable-next-line
          stylesheet={props.stylesheets}
          style={{ fontSize: fontSize, fontWeight }}
          renderers={renderers}
          collapse={false}
        >
          {/* eslint-disable-next-line */}
          {props.element.innerHTML}
        </Html>
        <Text style={{ fontSize, lineHeight: 1 }}>{'\n'}</Text>
      </Text>
    );

    return renderText;
  };

  const PDFDiv: HtmlRenderer = props => (
    <View>
      <Html
        stylesheet={STYLESHEET}
        style={{ fontSize }}
        renderers={renderers}
        collapse={false}
      >
        {/* eslint-disable-next-line */}
        {props.element.innerHTML}
      </Html>
    </View>
  );

  const renderers = {
    a: PDFLink,
    p: buildPDFTextComponent({ fontWeight: 'normal', fontSize: fontSize }),
    div: PDFDiv,
    h1: buildPDFTextComponent({ fontWeight: 'bold', fontSize: fontSize + 9 }),
    h2: buildPDFTextComponent({ fontWeight: 'bold', fontSize: fontSize + 7 }),
    h3: buildPDFTextComponent({ fontWeight: 'bold', fontSize: fontSize + 5 }),
    h4: buildPDFTextComponent({ fontWeight: 'bold', fontSize: fontSize + 3 }),
    h5: buildPDFTextComponent({ fontWeight: 'bold', fontSize: fontSize + 1 }),
    h6: buildPDFTextComponent({ fontWeight: 'bold', fontSize }),
  };

  return (
    <Html stylesheet={STYLESHEET} renderers={renderers} collapse={false}>
      {html}
    </Html>
  );
};

export default CustomHTML;
