import closeFill from '@iconify/icons-eva/close-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon as IconifyIcon } from '@iconify/react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  useDeleteFileMutation,
  useFilesWithSignedUrlsQuery,
  useGetFilesByProblemScopeIdQuery,
  useInsertProblemScopeFileByPkMutation,
} from 'apollo/generated/sdkShared';
import { MIconButton } from 'components/@material-extend';
import { BaseConfirmDeleteFileModal } from 'components/base/upload/BaseConfirmDeleteFileModal';
import UploadMultipleFiles from 'components/upload/UploadMultipleFiles';
import { FileError } from 'errors';
import useFiles from 'hooks/useFiles';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fData } from 'utils/formatNumber';

type UploadedFile = {
  id: number;
  attachment_url: string;
  file_id: number;
  name: string;
};

type FileToShow = UploadedFile | File;

export const useScopeFiles = ({
  problemScopeId,
}: {
  problemScopeId: number;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: filesData,
    loading: loadingfiles,
    error,
  } = useGetFilesByProblemScopeIdQuery({
    variables: {
      problemScopeId: problemScopeId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !problemScopeId,
  });

  const [deleteFile] = useDeleteFileMutation();

  const fileIds = useMemo(
    () => filesData?.problem_scopes_by_pk?.files.map(pf => pf.file_id),
    [filesData?.problem_scopes_by_pk?.files],
  );

  const skipFetchingSignedUrls = !fileIds;

  const { data: filesWithSignedUrlsData, loading: loadingFilesWithSignedUrls } =
    useFilesWithSignedUrlsQuery({
      variables: {
        files_ids: fileIds!,
      },
      skip: skipFetchingSignedUrls,
    });

  const files = useMemo(() => {
    const filesWithSignedUrls =
      filesWithSignedUrlsData?.files_with_signed_urls?.data;

    if (!filesData?.problem_scopes_by_pk || !Array.isArray(filesWithSignedUrls))
      return [];

    const signedUrlByFileId = filesWithSignedUrls.reduce<
      Record<number, string>
    >((acc, file) => {
      acc[file.id] = file.signed_url;
      return acc;
    }, {});

    const problemScopeFiles = filesData.problem_scopes_by_pk.files.map(
      ({ id, file_id, file }) => ({
        id,
        attachment_url: signedUrlByFileId[file_id],
        file_id,
        name: file.name,
      }),
    );

    return [...problemScopeFiles];
  }, [filesData, filesWithSignedUrlsData?.files_with_signed_urls?.data]);

  const uploadProblemScopeFile = useUploadProblemScopeFile();

  const deleteScopeFile = async (
    fileId: number,
    problemScopeFileId: number,
  ) => {
    try {
      await deleteFile({
        variables: {
          fileId: fileId,
        },
        update: (cache, { data }) => {
          if (!data) return;

          cache.evict({
            id: cache.identify({
              __typename: 'problem_scope_files',
              id: problemScopeFileId,
            }),
          });
          cache.gc();
        },
      });
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error deleting file', {
        variant: 'error',
      });
    }
  };

  return {
    files,
    loading: loadingfiles || loadingFilesWithSignedUrls,
    error,
    uploadProblemScopeFile,
    deleteScopeFile,
  };
};

export const useUploadProblemScopeFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { uploadFile } = useFiles({ filePrefix: 'project_documents' });
  const [insertProblemScopeFileByPk] = useInsertProblemScopeFileByPkMutation();

  const uploadProblemScopeFile = async (file: File, problemScopeId: number) => {
    if (!file) return;

    try {
      const insertedFile = await uploadFile(file, 50);

      if (!insertedFile) return;

      const uploadedFileData = await insertProblemScopeFileByPk({
        variables: {
          object: {
            problem_scope_id: problemScopeId,
            file_id: insertedFile.id,
          },
        },
      });

      return { id: uploadedFileData.data?.insert_problem_scope_files_one?.id };
    } catch (error) {
      if (error instanceof FileError) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      } else {
        captureException(error);
        enqueueSnackbar('Error uploading file', {
          variant: 'error',
        });
      }
    }
  };

  return uploadProblemScopeFile;
};

export const UploadScopeFile = ({
  setFieldValue,
  problemScopeId,
}: {
  setFieldValue: (field: 'scope_files', value: FileToShow[]) => void;
  problemScopeId?: number;
}) => {
  const { files, deleteScopeFile } = useScopeFiles({
    problemScopeId: problemScopeId!,
  });
  const [selectedFiles, setSelectedFiles] = useState<ArrayLike<File> | null>(
    null,
  );
  const [fileToBeDeleted, setFileToBeDeleted] = useState<UploadedFile | null>(
    null,
  );

  useEffect(() => {
    const uploadedFileNames = files.map(file => file.name);
    const filteredFiles = Array.from(selectedFiles || []).filter(
      file => !uploadedFileNames.includes(file.name),
    );
    if (filteredFiles.length !== selectedFiles?.length) {
      setSelectedFiles(filteredFiles);
    }
  }, [files, selectedFiles]);

  const filesToShow: FileToShow[] = [
    ...files,
    ...Array.from(selectedFiles || []),
  ];

  const handleDropFiles = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      setSelectedFiles(prev => [...Array.from(prev || []), ...acceptedFiles]);
      setFieldValue('scope_files', acceptedFiles);
    },
    [setFieldValue],
  );

  const handleRemoveFile = (file: FileToShow) => {
    if (file instanceof File) {
      setSelectedFiles(prev =>
        Array.from(prev || []).filter(_file => _file !== file),
      );
    } else {
      setFileToBeDeleted(file);
    }
  };

  const handleRemoveUploadedFile = async (file: UploadedFile) => {
    await deleteScopeFile(file.file_id, file.id);
    setFileToBeDeleted(null);
  };

  return (
    <>
      <UploadMultipleFiles files={selectedFiles} onDrop={handleDropFiles} />
      <List disablePadding style={{ display: 'flex', flexWrap: 'wrap' }}>
        {filesToShow &&
          filesToShow.map((file, i) => {
            const isFileToBeDeleted = fileToBeDeleted === file;
            const deletingStyle = {
              opacity: isFileToBeDeleted ? 0.5 : 1,
              transition: 'opacity 0.3s',
            };

            return (
              <ListItem
                sx={{
                  marginTop: 1,
                  width: 'fit-content',
                  paddingY: 0.75,
                  paddingX: 2,
                  marginRight: '0.25rem',
                  padding: '0.25rem',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 1,
                  border: theme => `solid 1px ${theme.palette.divider}`,
                  bgcolor: 'background.paper',
                  ...deletingStyle,
                }}
                key={`selected-files-${i}`}
              >
                <ListItemIcon
                  sx={{
                    marginRight: 0.5,
                    filter: fileToBeDeleted === file ? 'blur(2px)' : 'none',
                  }}
                >
                  <IconifyIcon icon={fileFill} width={28} height={28} />
                </ListItemIcon>
                <Tooltip title={file.name}>
                  <ListItemText
                    primary={file.name}
                    secondary={
                      file instanceof File ? fData(file.size) || 0 : ''
                    }
                    primaryTypographyProps={{
                      variant: 'overline',
                      style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '6rem',
                        ...deletingStyle,
                      },
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      style: deletingStyle,
                    }}
                  />
                </Tooltip>
                {!isFileToBeDeleted && (
                  <MIconButton
                    edge='end'
                    size='small'
                    onClick={() => handleRemoveFile(file)}
                  >
                    <IconifyIcon icon={closeFill} />
                  </MIconButton>
                )}
              </ListItem>
            );
          })}
      </List>
      {fileToBeDeleted && (
        <BaseConfirmDeleteFileModal
          title={fileToBeDeleted.name}
          onConfirm={() => handleRemoveUploadedFile(fileToBeDeleted)}
          onHide={() => setFileToBeDeleted(null)}
        />
      )}
    </>
  );
};

export const ScopeFilesListReadOnly = ({
  problemScopeId,
}: {
  problemScopeId: number;
}) => {
  const { files } = useScopeFiles({ problemScopeId });
  return (
    <List disablePadding style={{ display: 'flex', flexWrap: 'wrap' }}>
      {files &&
        files.map((file, i) => {
          return (
            <ListItem
              sx={{
                width: 'fit-content',
                marginTop: 1,
                paddingY: 0.75,
                paddingX: 2,
                marginRight: '0.25rem',
                padding: '0.25rem',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
                border: theme => `solid 1px ${theme.palette.divider}`,
                bgcolor: 'background.paper',
              }}
              key={`selected-files-${i}`}
            >
              <ListItemIcon
                sx={{
                  marginRight: 0.5,
                }}
              >
                <IconifyIcon icon={fileFill} width={28} height={28} />
              </ListItemIcon>
              <Tooltip title={file.name}>
                <ListItemText
                  primary={
                    <a
                      href={file.attachment_url}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {file.name}
                    </a>
                  }
                  secondary={file instanceof File ? fData(file.size) || 0 : ''}
                  primaryTypographyProps={{
                    variant: 'overline',
                    style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '6rem',
                    },
                  }}
                  secondaryTypographyProps={{
                    variant: 'caption',
                  }}
                />
              </Tooltip>
            </ListItem>
          );
        })}
    </List>
  );
};
