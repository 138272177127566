import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { buildStartupListResultsUrl } from 'utils/url';
import { StartupListForShare } from './ShareStartupList';
import { useCaptureCopyLink } from './useCaptureCopyLink';
import { useShareStartupList } from './useShareStartupList';

const NoViews = ({
  currentStartupList,
  disabled,
}: {
  currentStartupList: StartupListForShare;
  disabled: boolean;
}) => {
  useShareStartupList({ currentStartupList, isNoViews: true });
  const { enqueueSnackbar } = useSnackbar();
  const captureCopyLinkEvent = useCaptureCopyLink(currentStartupList);
  const url = useMemo(() => {
    if (
      !currentStartupList?.shared_project_links?.length ||
      !currentStartupList ||
      !currentStartupList.public_uuid
    ) {
      return '';
    }

    return buildStartupListResultsUrl({
      startupListPublicUUID: currentStartupList.public_uuid,
      slug: currentStartupList.shared_project_links[0].target_group_slug,
    });
  }, [currentStartupList]);

  return (
    <Stack justifyContent='center' alignItems='center'>
      <Typography variant='caption'>No views</Typography>
      <CopyToClipboard
        text={url}
        onCopy={() => {
          captureCopyLinkEvent();
          enqueueSnackbar('Link copied', { variant: 'success' });
        }}
      >
        <Button
          variant='text'
          disabled={!url || disabled}
          sx={{ whiteSpace: 'nowrap', minWidth: 80, padding: 0, fontSize: 12 }}
        >
          Copy link
        </Button>
      </CopyToClipboard>
    </Stack>
  );
};

export default NoViews;
