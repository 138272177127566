import { captureException } from '@sentry/react';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

export const SEARCH_ELEMENT_ID = 'scout-startups-page';

const useSearchSimilarActions = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isSharedPage } = useSharedPagesContext();

  const onSearchSimilarClickFromStartupProfile = useCallback(
    async (startupDomain: string, startupId: number) => {
      captureAnalyticsEvent('Search similar startups', {
        startupDomain,
        startupId,
        eventLocation: 'Startup profile',
      });

      try {
        navigate(
          isSharedPage
            ? PATH_ROOT.organizationPortal.similarTo(startupDomain)
            : PATH_ROOT.scout.similarTo(startupDomain),
          {
            state: {
              backToPage: `${window.location.pathname}${window.location.hash}`,
            },
          },
        );

        setTimeout(() => {
          document.getElementById(SEARCH_ELEMENT_ID)?.scrollIntoView({
            behavior: 'smooth',
          });
        }, 200);
      } catch (error) {
        captureException(error);
        enqueueSnackbar(`Error getting similar startups. Please try again.`, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, navigate, isSharedPage],
  );

  return {
    onSearchSimilarClickFromStartupProfile,
  };
};

export default useSearchSimilarActions;
