import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import CollapsedScopeAndDescription from 'components/dashboard/CollapsedScopeAndDescription';

import { Save } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import {
  useUpdateStartupListCategoryMutation,
  useUpdateStartupListMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useCreateStartupListCollaboratorMutation } from 'apollo/generated/sdkShared';
import SectionFieldLayout from 'components/dashboard/SectionFieldLayout';
import { StartupsCharts } from 'components/dashboard/StartupsCharts';
import { QuillEditor } from 'components/editor';
import { FullscreenContext } from 'contexts/FullscreenContext';
import useBreakpoints from 'hooks/useBreakpoints';
import { useSnackbar } from 'notistack';
import SourcingOrderPage from 'pages/request-form/SourcingOrderPage';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type SetAboutState = Dispatch<SetStateAction<AboutSectionProps>>;

export type AboutSectionProps = {
  allowEdit?: boolean;
  startupListId: number;
  categoryId?: number;
  isEditDialogOpen: boolean;
  isEditScopeDialogOpen: boolean;
  description: string;
  descriptionKey: 'short_description' | 'public_description';
  startupIds: number[];
};

export type AboutSectionContextProps = AboutSectionProps & {
  setState: SetAboutState;
};

export const AboutContext = createContext<AboutSectionContextProps>({
  startupListId: 0,
  categoryId: 0,
  description: '',
  isEditDialogOpen: false,
  isEditScopeDialogOpen: false,
  descriptionKey: 'public_description',
  startupIds: [],
  setState: () => {},
});

const EditDescription = () => {
  const { description, setState } = useContext(AboutContext);

  return (
    <SectionFieldLayout sx={{ fontSize: 14, paddingTop: 3 }}>
      <QuillEditor
        simple
        value={description}
        onChange={newValue =>
          setState(prevState => ({
            ...prevState,
            description: newValue,
          }))
        }
        sx={{ paddingBottom: 14 }}
      />
    </SectionFieldLayout>
  );
};

const offsetTop = 3;

const AboutSection = ({
  startupIds,
  description = '',
  isPublicPage,
  descriptionKey = 'public_description',
  startupListId,
  isDiscovery,
  categoryId,
  shouldShowData,
}: {
  startupIds: number[];
  description?: string | null;
  isPublicPage?: boolean;
  descriptionKey?: 'short_description' | 'public_description';
  startupListId: number;
  isDiscovery?: boolean;
  categoryId?: number;
  shouldShowData?: boolean | null;
}) => {
  const { isFullscreenView } = useContext(FullscreenContext);
  const { isBelowMd } = useBreakpoints();

  const allowEdit = !isPublicPage && !isDiscovery && !isFullscreenView;
  const [state, setState] = useState<AboutSectionProps>({
    allowEdit,
    isEditDialogOpen: false,
    isEditScopeDialogOpen: false,
    description: description ?? '',
    descriptionKey,
    startupListId,
    startupIds,
    categoryId,
  });

  const [chartsHeight, setChartsHeight] = useState<number | null>(null);
  const { spacing } = useTheme();
  const uniqueStartupIds = useMemo(
    () => [...new Set(startupIds)],
    [startupIds],
  );
  const shouldRenderMetrics =
    (uniqueStartupIds?.length >= 5 && shouldShowData) || isDiscovery;

  const offsetTopNumber = Number(spacing(offsetTop).replace('px', ''));
  const isDescriptionEmpty =
    !description ||
    !description.trim().length ||
    description === '<p><br></p>' ||
    description === '<p></p>';
  const renderDescription = useMemo(
    () => (
      <Stack
        sx={{
          minWidth: 0,
          flexGrow: 1,
          flexBasis: 0,
        }}
      >
        <CollapsedScopeAndDescription
          chartsHeight={chartsHeight}
          markup={description}
          shouldAdjustToSibling
          shouldRenderMetrics={shouldRenderMetrics}
          offsetTop={offsetTopNumber}
          allowEdit={allowEdit}
          isDescriptionEmpty={isDescriptionEmpty}
          startupListId={startupListId}
        />
      </Stack>
    ),
    [
      allowEdit,
      chartsHeight,
      description,
      startupListId,
      isDescriptionEmpty,
      offsetTopNumber,
      shouldRenderMetrics,
    ],
  );

  return (
    <AboutContext.Provider value={{ ...state, setState }}>
      <Stack direction={isBelowMd ? 'column' : 'row'} gap={2}>
        {allowEdit ? <>{renderDescription}</> : renderDescription}
        {shouldRenderMetrics && (
          <StartupsCharts
            setHeight={setChartsHeight}
            startupIds={uniqueStartupIds}
          />
        )}
      </Stack>
    </AboutContext.Provider>
  );
};

export const EditDescriptionDialog = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    description,
    descriptionKey,
    setState,
    startupListId,
    isEditDialogOpen,
    categoryId,
  } = useContext(AboutContext);

  const [updateStartupList] = useUpdateStartupListMutation();
  const [upsertCollaborator] = useCreateStartupListCollaboratorMutation();
  const [updateStartupListCategory] = useUpdateStartupListCategoryMutation();
  const { palette } = useTheme();

  const handleClose = () =>
    setState(prevState => ({ ...prevState, isEditDialogOpen: false }));
  const handleSubmit = async () => {
    if (!startupListId) return;

    try {
      if (descriptionKey === 'public_description') {
        await updateStartupList({
          variables: {
            id: startupListId,
            payload: { [descriptionKey]: description },
          },
        });
      } else if (categoryId) {
        await updateStartupListCategory({
          variables: {
            id: categoryId,
            payload: { [descriptionKey]: description },
          },
        });
      }

      await upsertCollaborator({
        variables: {
          object: {
            role: 'editor',
            startup_list_id: startupListId,
          },
        },
      });
      handleClose();
      setState(prevState => ({ ...prevState, isEditMode: false }));
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Update failed', { variant: 'error' });
    }
  };

  return (
    <Dialog open={isEditDialogOpen} fullWidth onClose={handleClose}>
      <DialogTitle>Edit description</DialogTitle>
      <DialogContent sx={{ marginTop: '25px' }}>
        <EditDescription />
      </DialogContent>
      <DialogActions>
        <Button
          size='small'
          sx={{ color: palette.grey[600] }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button startIcon={<Save />} size='small' onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditScopeDialog = () => {
  const { setState, isEditScopeDialogOpen } = useContext(AboutContext);
  const handleClose = () =>
    setState(prevState => ({ ...prevState, isEditScopeDialogOpen: false }));
  return (
    <Dialog open={isEditScopeDialogOpen} fullWidth onClose={handleClose}>
      <DialogTitle>Edit scope</DialogTitle>
      <DialogContent sx={{ marginTop: '25px' }}>
        <SourcingOrderPage renderWithinList closeOrderModal={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AboutSection;
