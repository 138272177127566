import { InfoOutlined, SearchOutlined, Telegram } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';
import { useOrganizationPortalContext } from 'components/OrganizationPortal/contexts/OrganizationPortalContext';
import { useViewingOrganizationPortalAs } from 'components/OrganizationPortal/contexts/ViewingPortalAsContext';
import TeamAvatar from 'components/teams/TeamAvatar';
import { useGetCurrentUserTeamAvatarUrl } from 'components/teams/useGetCurrentUserTeamAvatarUrl';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import useBreakpoints from 'hooks/useBreakpoints';
import AccountPopover from 'layouts/dashboard/AccountPopover';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';

export const HeaderStyledLogo = styled('img')(({ theme: { spacing } }) => ({
  maxWidth: 100,
  maxHeight: spacing(6),
  borderRadius: spacing(0.5),
  marginTop: spacing(1),
}));

const activeButtonBackground = (isActive: boolean) =>
  isActive ? 'rgba(0, 171, 85, 0.08) !important' : 'transparent';

export const OrganizationPortalHeader = () => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const { getPortalConfigField } = useOrganizationPortalContext();
  const requestSolutionsPageEnabled = getPortalConfigField(
    'request_form_page_enabled',
  );
  const aboutPageEnabled = getPortalConfigField('about_page_enabled');
  const homePageEnabled = getPortalConfigField('home_page_enabled');
  const teamSlug = getPortalConfigField('team_slug') ?? 'default';

  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const { spacing } = useTheme<Theme>();
  const { startupListPublicUUID } = useParams<{
    startupListPublicUUID: string;
  }>();
  const isExternalListScopeForm = !!useMatch(
    PATH_ROOT.lists.sharedListScopeDetails(startupListPublicUUID || ''),
  );
  const isSearchPage = !!useMatch(PATH_ROOT.organizationPortal.search);
  const isBriefForm = !!useMatch(PATH_ROOT.briefUs(teamSlug));
  const isAboutPage = !!useMatch(PATH_ROOT.organizationPortal.about(teamSlug));
  const isRequestFormPagePortalBuilder = !!useMatch(
    PATH_ROOT.organizationPortalBuilder.requestSolutions(teamSlug),
  );
  const isAboutPagePortalBuilder = !!useMatch(
    PATH_ROOT.organizationPortalBuilder.about(teamSlug),
  );
  const isHomePagePortalBuilder = !!useMatch(
    PATH_ROOT.organizationPortalBuilder.home(teamSlug),
  );

  const isPortalBuilder =
    isAboutPagePortalBuilder ||
    isRequestFormPagePortalBuilder ||
    isHomePagePortalBuilder;

  const handleNavigate = (path: string) => {
    if (isPortalBuilder) return;
    navigate(path);
  };

  const { isBelowMd } = useBreakpoints();
  const ButtonComponent = isBelowMd ? IconButton : Button;
  const teamAvatarUrl = useGetCurrentUserTeamAvatarUrl();

  return (
    <Stack
      data-testid='organization-portal-header'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        boxShadow: 'rgba(0, 0, 0, 0.03) 0px 2px 4px',
        marginBottom: 6,
        paddingX: 3,
      }}
    >
      <Stack direction='row' alignItems={'center'} paddingY={0.5}>
        <TeamAvatar
          onClick={() =>
            homePageEnabled &&
            handleNavigate(PATH_ROOT.organizationPortal.home(teamSlug))
          }
          alt='organization-portal-logo'
          src={teamAvatarUrl || currentOrganization.logo_url}
          sx={{
            maxWidth: 100,
            maxHeight: spacing(4.5),
            borderRadius: spacing(0.5),
            cursor: 'pointer',
            width: '100% !important',
            height: `${spacing(4.5)} !important`,
            '& img': {
              borderRadius: spacing(0.5),
              width: '100% !important',
              height: `${spacing(4.5)} !important`,
            },
          }}
        />
      </Stack>
      <Stack direction='row' gap={isBelowMd ? 1 : 2}>
        {user.type &&
          ['innovation_manager', 'staff'].includes(user.type) &&
          !isBelowMd && (
            <>
              <Button
                variant='text'
                color={'inherit'}
                sx={{
                  fontWeight: 500,
                }}
                onClick={() => handleNavigate(PATH_ROOT.root)}
              >
                Venture Clienting OS
              </Button>
            </>
          )}
        <Box
          component={ButtonComponent}
          sx={{
            width: isBelowMd ? spacing(6) : 'auto',
            fontWeight: 500,
            backgroundColor: activeButtonBackground(isSearchPage),
          }}
          color={isSearchPage || isBelowMd ? 'primary' : 'inherit'}
          onClick={() => handleNavigate(PATH_ROOT.organizationPortal.search)}
        >
          {isBelowMd ? <SearchOutlined /> : 'Search'}
        </Box>
        {requestSolutionsPageEnabled && (
          <Box
            component={ButtonComponent}
            sx={{
              width: isBelowMd ? spacing(6) : 'auto',
              fontWeight: 500,
              backgroundColor: activeButtonBackground(
                isBriefForm ||
                  !!isExternalListScopeForm ||
                  isRequestFormPagePortalBuilder,
              ),
            }}
            color={
              isBriefForm || isExternalListScopeForm || isBelowMd
                ? 'primary'
                : 'inherit'
            }
            onClick={() => handleNavigate(PATH_ROOT.briefUs(teamSlug))}
          >
            {isBelowMd ? <Telegram /> : 'Request solutions'}
          </Box>
        )}
        {aboutPageEnabled && (
          <>
            <Box
              component={ButtonComponent}
              variant='text'
              sx={{
                width: isBelowMd ? spacing(6) : 'auto',
                fontWeight: 500,
                backgroundColor: activeButtonBackground(
                  isAboutPage || isAboutPagePortalBuilder,
                ),
              }}
              color={isAboutPage || isBelowMd ? 'primary' : 'inherit'}
              onClick={() =>
                handleNavigate(PATH_ROOT.organizationPortal.about(teamSlug))
              }
            >
              {isBelowMd ? <InfoOutlined /> : 'About'}
            </Box>
          </>
        )}
        {isAuthenticated && <AccountPopover />}
      </Stack>
    </Stack>
  );
};

export const ReadonlyOrganizationPortalHeader = () => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const { user } = useAuth();
  const { getPortalConfigField } = useOrganizationPortalContext();
  const requestSolutionsPageEnabled = getPortalConfigField(
    'request_form_page_enabled',
  );
  const aboutPageEnabled = getPortalConfigField('about_page_enabled');
  const teamSlug = getPortalConfigField('team_slug') ?? 'default';

  const { viewingOrganizationPortalAs } = useViewingOrganizationPortalAs();
  const { spacing } = useTheme<Theme>();

  const isRequestFormPagePortalBuilder = !!useMatch(
    PATH_ROOT.organizationPortalBuilder.requestSolutions(teamSlug),
  );
  const isAboutPagePortalBuilder = !!useMatch(
    PATH_ROOT.organizationPortalBuilder.about(teamSlug),
  );

  const { isBelowMd } = useBreakpoints();
  const ButtonComponent = isBelowMd ? IconButton : Button;
  const teamAvatarUrl = useGetCurrentUserTeamAvatarUrl();

  return (
    <Stack
      data-testid='organization-portal-header'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        boxShadow: 'rgba(0, 0, 0, 0.03) 0px 2px 4px',
        marginBottom: 6,
        paddingX: 3,
      }}
    >
      <Stack direction='row' alignItems={'center'} paddingY={0.5}>
        <Tooltip title='Link is only clickable when shared'>
          <span>
            <TeamAvatar
              alt='organization-portal-logo'
              src={teamAvatarUrl || currentOrganization.logo_url}
              sx={{
                maxWidth: 100,
                maxHeight: spacing(4.5),
                borderRadius: spacing(0.5),
                width: '100% !important',
                height: `${spacing(4.5)} !important`,
                '& img': {
                  borderRadius: spacing(0.5),
                  width: '100% !important',
                  height: `${spacing(4.5)} !important`,
                },
              }}
            />
          </span>
        </Tooltip>
      </Stack>
      <Stack direction='row' gap={isBelowMd ? 1 : 2}>
        {viewingOrganizationPortalAs === 'admin' && !isBelowMd && (
          <>
            <Tooltip title='Link is only clickable when shared'>
              <Button
                variant='text'
                color={'inherit'}
                sx={{
                  fontWeight: 500,
                  cursor: 'unset',
                }}
              >
                Venture Clienting OS
              </Button>
            </Tooltip>
          </>
        )}
        <Tooltip title='Link is only clickable when shared'>
          <Box
            component={ButtonComponent}
            sx={{
              width: isBelowMd ? spacing(6) : 'auto',
              fontWeight: 500,
              cursor: 'unset',
            }}
            color={isBelowMd ? 'primary' : 'inherit'}
          >
            {isBelowMd ? <SearchOutlined /> : 'Search'}
          </Box>
        </Tooltip>
        {requestSolutionsPageEnabled && (
          <Tooltip title='Link is only clickable when shared'>
            <Box
              component={ButtonComponent}
              sx={{
                width: isBelowMd ? spacing(6) : 'auto',
                fontWeight: 500,
                backgroundColor: activeButtonBackground(
                  isRequestFormPagePortalBuilder,
                ),
                cursor: 'unset',
              }}
              color={
                isRequestFormPagePortalBuilder || isBelowMd
                  ? 'primary'
                  : 'inherit'
              }
            >
              {isBelowMd ? <Telegram /> : 'Request solutions'}
            </Box>
          </Tooltip>
        )}
        {aboutPageEnabled && (
          <Tooltip title='Link is only clickable when shared'>
            <Box
              component={ButtonComponent}
              variant='text'
              sx={{
                width: isBelowMd ? spacing(6) : 'auto',
                fontWeight: 500,
                backgroundColor: activeButtonBackground(
                  isAboutPagePortalBuilder,
                ),
                cursor: 'unset',
              }}
              color={isBelowMd ? 'primary' : 'inherit'}
            >
              {isBelowMd ? <InfoOutlined /> : 'About'}
            </Box>
          </Tooltip>
        )}
        <Tooltip title='Link is only clickable when shared'>
          <Box>
            <IconButton
              disabled
              sx={{
                padding: 0,
                width: spacing(6),
                height: spacing(6),
              }}
            >
              <Avatar src={user?.photoURL}></Avatar>
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
