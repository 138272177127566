// @mui
import {
  PersonSearch,
  RocketLaunch,
  ScienceOutlined,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
// components
import { Avatar } from '@mui/material';
import { EnumTableNotificationTypeEnum } from 'apollo/generated/sdkInnovationManager';
import { formatDistanceToNow } from 'date-fns';
import DOMPurify from 'dompurify';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import DiscoverIcon from './assets/binoculars.svg?react';

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: {
    id: number;
    body: string;
    created_at: string;
    seen: boolean;
    type: EnumTableNotificationTypeEnum;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action?: any;
    preview?: string | null;
    logo_url?: string | null;
  };
  onClick: () => void;
};

type InputValue = Date | string | number | null | undefined;

function formatDateDistanceToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

type Category = 'Startups' | 'PoC' | 'Engagement';

const mapNotificationTypeToCategory: Record<
  EnumTableNotificationTypeEnum,
  Category
> = {
  SOURCING_ORDER_COMPLETED: 'Startups',
  STARTUP_SELECTED_FOR_DEMO: 'PoC',
  STARTUP_SELECTED_FOR_POC: 'PoC',
  STARTUP_NOTE_ADDED: 'Engagement',
  NEW_DISCOVERY_ITEM: 'Startups',
  MANY_NEW_DISCOVERY_ITEMS: 'Startups',
  NEW_STARTUP_IN_DISCOVERY_SUBSCRIPTION: 'Startups',
  MANY_NEW_STARTUPS_IN_DISCOVERY_SUBSCRIPTION: 'Startups',
  FIRST_PARTY_DATA_APPROVED: 'Startups',
  IMPACT_OF_VENTURE_CLIENTING_2023: 'Startups',
};

const mapNotificationTypeToIcon: Record<
  EnumTableNotificationTypeEnum,
  React.ReactNode
> = {
  SOURCING_ORDER_COMPLETED: <PersonSearch />,
  STARTUP_SELECTED_FOR_DEMO: <ScienceOutlined />,
  STARTUP_SELECTED_FOR_POC: <ScienceOutlined />,
  STARTUP_NOTE_ADDED: <RocketLaunch />,
  NEW_DISCOVERY_ITEM: <DiscoverIcon />,
  MANY_NEW_DISCOVERY_ITEMS: <DiscoverIcon />,
  NEW_STARTUP_IN_DISCOVERY_SUBSCRIPTION: <DiscoverIcon />,
  MANY_NEW_STARTUPS_IN_DISCOVERY_SUBSCRIPTION: <DiscoverIcon />,
  FIRST_PARTY_DATA_APPROVED: <DiscoverIcon />,
  IMPACT_OF_VENTURE_CLIENTING_2023: null,
};

export default function NotificationItem({
  notification,
  onClick,
}: NotificationItemProps) {
  const openActionInNewTab = !notification.action?.url?.startsWith('/');
  const navigate = useNavigate();

  return (
    <ListItemButton
      disableRipple
      sx={{
        padding: 2.5,
        alignItems: 'flex-start',
        borderBottom: theme => `dashed 1px ${theme.palette.divider}`,
      }}
      onClick={() => {
        if (openActionInNewTab) {
          window.open(notification.action.url, '_blank');
        } else {
          // Navigate to url
          if (notification.action?.url) {
            navigate(notification.action.url);
          }
        }
        onClick();
      }}
    >
      {!notification.seen && (
        <Box
          sx={{
            top: 26,
            width: 8,
            height: 8,
            right: 20,
            borderRadius: '50%',
            bgcolor: 'info.main',
            position: 'absolute',
          }}
        />
      )}
      <ListItemAvatar>
        {notification.logo_url ? (
          <Avatar sizes='40' src={notification.logo_url}>
            <NotificationAvatar type={notification.type} />
          </Avatar>
        ) : (
          <NotificationAvatar type={notification.type} />
        )}
      </ListItemAvatar>

      <Stack sx={{ flexGrow: 1 }}>
        <ListItemText
          disableTypography
          primary={
            <Box
              dangerouslySetInnerHTML={{ __html: notification.body }}
              sx={theme => ({
                marginBottom: 0.5,
                paddingRight: 1.5,
                '&': { typography: 'body2', margin: 0 },
                '& p': { typography: 'body2', margin: 0 },
                '& a': { color: 'inherit', textDecoration: 'none' },
                '& strong': { typography: 'subtitle2' },
                '& b': { typography: 'subtitle2' },
                '& img': {
                  display: 'inline',
                  width: theme.spacing(2),
                  height: theme.spacing(2),
                  marginBottom: theme.spacing(-0.25),
                  marginRight: theme.spacing(0.25),
                  borderRadius: '50%',
                },
              })}
            />
          }
          secondary={
            <Stack
              direction='row'
              alignItems='center'
              sx={{ typography: 'caption', color: 'text.disabled' }}
              divider={
                <Box
                  sx={{
                    width: 2,
                    height: 2,
                    bgcolor: 'currentColor',
                    marginX: 0.5,
                    borderRadius: '50%',
                  }}
                />
              }
            >
              {formatDateDistanceToNow(notification.created_at)}
              {mapNotificationTypeToCategory[notification.type]}
            </Stack>
          }
        />
        {notification.preview && (
          <NotificationPreview html={notification.preview} />
        )}
      </Stack>
    </ListItemButton>
  );
}

const NotificationPreview = (props: { html: string }) => {
  const decodedHtml = useMemo(
    () => decodeAndSanitizedHtmlEntities(props.html),
    [props.html],
  );
  return (
    <Box
      sx={{
        padding: 1.5,
        marginY: 1.5,
        borderRadius: 1.5,
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Box
        component={'div'}
        dangerouslySetInnerHTML={{ __html: decodedHtml }}
        sx={{
          marginBottom: 0.5,
          '&': { typography: 'body2', margin: 0 },
          '& p': { typography: 'body2', margin: 0 },
          '& a': { color: 'inherit', textDecoration: 'none' },
          '& strong': { typography: 'subtitle2' },
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
        }}
      />
    </Box>
  );
};

// Function to decode HTML entities
const decodeAndSanitizedHtmlEntities = (preview: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = preview;
  // https://www.stackhawk.com/blog/react-xss-guide-examples-and-prevention/
  return DOMPurify.sanitize(textarea.value);
};

function NotificationAvatar({ type }: { type: EnumTableNotificationTypeEnum }) {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      sx={{
        width: 40,
        height: 40,
        borderRadius: '50%',
        bgcolor: 'background.neutral',
      }}
    >
      {mapNotificationTypeToIcon[type]}
    </Stack>
  );
}
