import { ArrowBackIos } from '@mui/icons-material';
import {
  Box,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  TypographyTypeMap,
  useTheme,
} from '@mui/material';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { useSnackbar } from 'notistack';
import { To, useNavigate } from 'react-router';
import { FocusEvent, useState } from 'react';
import { ProjectStatusField } from 'pages/dashboard/scoping/MainStatusFields';

type EditableProps = {
  readonly: false;
  updateTitle: (newTitle: string) => Promise<void>;
  title: string;
  tooltipTitle?: string;
  titleVariant?: TypographyTypeMap['props']['variant'];
  navigateBackTo: To | null;
  sx?: SxProps;
  type?: 'project' | 'lead' | 'list';
  children?: React.ReactNode;
};

type ReadonlyProps = {
  readonly: true;
  updateTitle?: undefined;
  title: string;
  tooltipTitle?: string;
  titleVariant?: TypographyTypeMap['props']['variant'];
  navigateBackTo: To | null;
  sx?: SxProps;
  type?: 'project' | 'lead' | 'list';
  children?: React.ReactNode;
};

export default function DetailsHeaderTitle({
  navigateBackTo,
  title,
  tooltipTitle = 'Back',
  titleVariant = 'h4',
  children,
  type,
  sx,
  ...editingProps
}: EditableProps | ReadonlyProps) {
  const navigate = useNavigate();
  const { spacing } = useTheme();
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleTitleUpdate = async (e: FocusEvent<HTMLSpanElement, Element>) => {
    if (loading) return;
    if (editingProps.readonly) return;

    try {
      const newTitleRaw = e.currentTarget.textContent;

      if (title === newTitleRaw) return;
      const newTitle = newTitleRaw?.trim();

      if (newTitle === '' || !newTitle) {
        enqueueSnackbar('Title cannot be empty', { variant: 'error' });
        e.currentTarget.textContent = title;
        return;
      }

      setLoading(true);
      await editingProps.updateTitle(newTitle);

      enqueueSnackbar('Title updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to update title', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction='row'
      justifyContent={'flex-start'}
      flexGrow={1}
      minWidth={0}
      alignItems='center'
      data-testid='details-title-heading-container'
      sx={sx}
    >
      <Tooltip title={tooltipTitle}>
        <ArrowBackIos
          onClick={() => {
            if (navigateBackTo) {
              navigate(navigateBackTo);
            } else {
              navigate(-1);
            }
          }}
          fontSize='small'
          sx={{ cursor: 'pointer', width: spacing(2) }}
        />
      </Tooltip>
      {type === 'project' && (
        <Box marginRight={0.5}>
          <ProjectStatusField />
        </Box>
      )}
      <TypographyWithEllipsis
        data-testid='details-title-heading'
        tooltipTitle={<Typography variant='body2'>{title}</Typography>}
        noWrap
        display='inline-block'
        variant={titleVariant}
        fontWeight={400}
        contentEditable={!editingProps.readonly}
        suppressContentEditableWarning
        // eslint-disable-next-line
        onKeyDown={(e: any) => {
          if (editingProps.readonly) return;
          if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            e.currentTarget.blur();
            window?.getSelection()?.removeAllRanges();
          }

          if (e.key === 'Escape') {
            e.preventDefault();
            e.currentTarget.textContent = title;
            e.currentTarget.blur();
            window?.getSelection()?.removeAllRanges();
          }
        }}
        onBlur={handleTitleUpdate}
      >
        {title}
      </TypographyWithEllipsis>
      {children}
    </Stack>
  );
}
