import { captureMessage } from '@sentry/react';
import { OrganizationPortalContextProvider } from 'components/OrganizationPortal/contexts/OrganizationPortalContext';
import { OrganizationPortalHeader } from 'components/OrganizationPortalUtils/shared/OrganizationPortalHeader';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router';

export const SharedPagesLayout = () => {
  const { isSharedPage, setIsSharedPage } = useSharedPagesContext();

  useEffect(() => {
    if (setIsSharedPage) {
      setIsSharedPage(true);
    } else {
      captureMessage('setIsSharedPage is not defined');
    }

    return () => {
      setIsSharedPage?.(false);
    };
  }, [setIsSharedPage]);

  if (!isSharedPage) {
    return null;
  }

  return (
    <>
      <OrganizationPortalContextProvider>
        <OrganizationPortalHeader />
      </OrganizationPortalContextProvider>
      <Outlet />
    </>
  );
};

export const BuPageLayout = () => {
  return (
    <>
      <OrganizationPortalContextProvider>
        <OrganizationPortalHeader />
      </OrganizationPortalContextProvider>
      <Outlet />
    </>
  );
};

export const SharedPagesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSharedPage, setIsSharedPage] = useState(false);
  const contextValue = useMemo(() => {
    return {
      isSharedPage,
      setIsSharedPage,
    };
  }, [isSharedPage]);

  return (
    <SharedPagesContext.Provider value={contextValue}>
      {children}
    </SharedPagesContext.Provider>
  );
};

export const SharedPagesContext = createContext<{
  isSharedPage: boolean;
  setIsSharedPage: Dispatch<SetStateAction<boolean>> | null;
}>({
  isSharedPage: false,
  setIsSharedPage: null,
});

export const useSharedPagesContext = () => {
  const context = useContext(SharedPagesContext);

  return context;
};

export default SharedPagesLayout;
