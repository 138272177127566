import { DOMAIN_COLORS } from 'theme/palette';
import {
  EnumTableProjectHealthStatusesEnum,
  EnumTableProjectCategoriesEnum,
} from 'apollo/generated/sdkInnovationManager';

export type StatusMap = {
  [key in EnumTableProjectHealthStatusesEnum]: string;
};

export const STATUS_COLOR_MAPPING: StatusMap = {
  green: DOMAIN_COLORS.projectStatus.green,
  amber: DOMAIN_COLORS.projectStatus.amber,
  red: DOMAIN_COLORS.projectStatus.red,
};

export const PROJECT_STATUSES: EnumTableProjectHealthStatusesEnum[] = [
  'green',
  'amber',
  'red',
];
export const PROJECT_CATEGORIES: EnumTableProjectCategoriesEnum[] = [
  'product_innovation',
  'business_enablers',
  'production_technology',
];

export const PROJECT_CATEGORIES_MAP: Record<
  EnumTableProjectCategoriesEnum,
  string
> = {
  product_innovation: 'Product Innovation',
  business_enablers: 'Business Enablers',
  production_technology: 'Production Technology',
};
