// material
import { SxProps } from '@mui/material';
import { alpha, Theme, useTheme, styled } from '@mui/material/styles';
// @types
import { ColorSchema } from '../../@types/theme';
import { MouseEventHandler } from 'react';

// ----------------------------------------------------------------------

type LabelColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'yellow'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

type IntentType = 'Hot' | 'Warm' | 'Cold';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span', {
  shouldForwardProp(propName) {
    return !['styleProps', 'sx'].includes(propName as string);
  },
})(({
  theme,
  styleProps,
}: {
  theme: Theme;
  styleProps: {
    color: LabelColor;
    variant: LabelVariant;
    intentLabel?: IntentType;
  };
}) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant, intentLabel } = styleProps;

  const styleFilled = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
  });

  const styleOutlined = (color: ColorSchema) => ({
    color: theme.palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[color].main}`,
  });

  const styleGhost = (color: ColorSchema) => ({
    color: theme.palette[color][isLight ? 'dark' : 'light'],
    backgroundColor: alpha(theme.palette[color].main, 0.16),
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // @ts-expect-error: TODO: FIXME! multiple declarations
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    // @ts-expect-error: TODO: FIXME! multiple declarations
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,
    margin: '0.1rem',

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
      : {
          ...(variant === 'filled' && {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[700],
          }),
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === 'ghost' && {
            color: isLight
              ? theme.palette.text.secondary
              : theme.palette.common.white,
            backgroundColor: theme.palette.grey[500_16],
          }),
        }),

    ...(intentLabel === 'Hot'
      ? { backgroundColor: '#FFE2E1', color: '#B72136' }
      : intentLabel === 'Warm'
        ? { backgroundColor: '#FFF5D7', color: '#B78103' }
        : intentLabel === 'Cold'
          ? { backgroundColor: '#DEE6FF', color: '#1939B7' }
          : {}),
  };
});

// ----------------------------------------------------------------------

interface LabelProps {
  color?: LabelColor;
  variant?: LabelVariant;
  intentLabel?: IntentType;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  sx?: SxProps<Theme>;
}

export default function BaseLabel({
  color = 'default',
  variant = 'ghost',
  intentLabel,
  children,
  onClick,
  sx,
}: LabelProps) {
  const theme = useTheme();

  return (
    <RootStyle
      onClick={onClick}
      styleProps={{ color, variant, intentLabel }}
      sx={sx}
      theme={theme}
    >
      {children}
    </RootStyle>
  );
}
