import { FavoriteBorder } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useOrganizationPortalContext } from 'components/OrganizationPortal/contexts/OrganizationPortalContext';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

export const IamInterestedButton = ({
  title = '',
  variant = 'contained',
}: {
  title: string;
  variant?: 'contained' | 'outlined' | 'text' | undefined;
}) => {
  const navigate = useNavigate();
  const { getPortalConfigField } = useOrganizationPortalContext();
  const requestSolutionsPageEnabled = getPortalConfigField(
    'request_form_page_enabled',
  );

  if (!title || !requestSolutionsPageEnabled) return null;

  return (
    <Button
      startIcon={<FavoriteBorder />}
      variant={variant}
      sx={{
        width: variant === 'text' ? 'auto' : 180,
        height: '100%',
      }}
      onClick={() => {
        navigate(PATH_ROOT.briefUsFromPortal(title));
      }}
    >
      {/* eslint-disable-next-line */}
      I'm interested
    </Button>
  );
};
