import { useUpsertPersonActionMutation } from 'apollo/generated/sdkInnovationManager';
import { useGetUserProfileQuery } from 'apollo/generated/sdkShared';
import IdentifyPersonModal from 'components/dashboard/startupList/result/IdentifyPersonModal';
import useAuth from 'hooks/useAuth';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { PersonUser } from '../@types/authentication';
import { useCurrentOrganizationFromContext } from './CurrentOrganizationContext';

type SetPersonModalArg = {
  show: boolean;
  onConfirm?: (arg: Omit<PersonUser, 'id'>) => void;
};

type PersonParams = {
  person: PersonUser | undefined;
  setPersonModal: (arg: SetPersonModalArg) => void;
  needPersonInformation: boolean;
};

const PersonContext = createContext<PersonParams | null>(null);

export const PersonProvider = ({ children }: { children: ReactNode }) => {
  const [showPersonModal, setShowPersonModal] = useState<boolean>(false);

  const [onConfirmPersonModal, setOnConfirmPersonModal] =
    useState<(s: PersonUser) => void>();

  const { user, isAuthenticated } = useAuth();
  const organization = useCurrentOrganizationFromContext();

  const { data: userProfile } = useGetUserProfileQuery({
    skip: !isAuthenticated || !organization,
    variables: { id: user.id },
  });

  const [upsertPerson] = useUpsertPersonActionMutation();

  const person = useMemo(
    () => userProfile?.users_by_pk?.person,
    [userProfile?.users_by_pk?.person],
  );

  const handleOnConfirm = async (data: PersonUser) => {
    await upsertPerson({
      variables: {
        object: {
          full_name: data.fullName,
          email: data.email,
          department: data.department,
        },
      },
    });

    onConfirmPersonModal && onConfirmPersonModal(data);

    setShowPersonModal(false);
  };

  const setPersonModal = ({ show, onConfirm }: SetPersonModalArg) => {
    setOnConfirmPersonModal(onConfirm);
    setShowPersonModal(show);
  };

  const needPersonInformation = useMemo(
    () =>
      user.type === 'bu_member' &&
      (!person || person.email === person.full_name),
    [person, user.type],
  );

  return (
    <PersonContext.Provider
      value={{
        person: person && {
          id: person.id,
          fullName: person.full_name,
          email: person.email,
          department: person.department || '',
          ...(person.team && { team: person.team }),
        },
        setPersonModal,
        needPersonInformation,
      }}
    >
      <IdentifyPersonModal
        isOpen={showPersonModal}
        onClose={() => setShowPersonModal(false)}
        onConfirm={handleOnConfirm}
      />

      {children}
    </PersonContext.Provider>
  );
};

export const usePersonContext = () => {
  const state = useContext(PersonContext);

  if (!state) {
    return {
      person: undefined,
      setPersonModal: () => {
        return;
      },
      needPersonInformation: false,
    };
  }

  return state;
};
