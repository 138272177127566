import { useSendReactMailMutation } from 'apollo/generated/sdkInnovationManager';
import { useCreateSharedStartupListsMutation } from 'apollo/generated/sdkShared';
import { DEFAULT_LINK_TARGET_GROUP_SLUG } from 'components/OrganizationPortal/shared/usePortalAnalytics';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import { useCallback, useEffect, useMemo } from 'react';
import { Person } from '../../../../../@types/startupList';
import { StartupListForShare } from './ShareStartupList';
import { buildStartupListResultsUrl } from 'utils/url';
import { REACT_EMAIL_TYPES } from 'config';

export const useShareStartupList = ({
  currentStartupList,
  isNoViews,
}: {
  currentStartupList: StartupListForShare;
  isNoViews: boolean;
}) => {
  const { user } = useAuth();
  const [createSharedStartupList] = useCreateSharedStartupListsMutation();
  const [sendMail] = useSendReactMailMutation();
  const currentOrganization = useCurrentOrganizationFromContext();
  const shares = useMemo(
    () => currentStartupList.shared_project_links || [],
    [currentStartupList.shared_project_links],
  );

  const handleSharing = useCallback(
    async ({
      slug,
      sendToStakeholders,
      additionalMessage,
    }: {
      slug: string;
      sendToStakeholders?: Person[];
      additionalMessage?: string;
    }) => {
      console.log({ sendToStakeholders, slug });
      const { data } = await createSharedStartupList({
        variables: {
          objects: [
            {
              target_group_slug: slug,
              startup_list_id: currentStartupList.id,
            },
          ],
        },
      });

      // TODO: Send through BE Event
      if (sendToStakeholders?.length) {
        await sendMail({
          variables: {
            emailPayload: sendToStakeholders.map(s => ({
              emailType: REACT_EMAIL_TYPES.shareList,
              from: {
                name: currentOrganization.from_name,
                email: currentOrganization.from_email,
              },
              to: [s.email],
              reply_to: user.email,
              payload: {
                listId: currentStartupList.id,
                organizationId: currentOrganization.id,
                recipientId: s.id,
                additionalMessage,
                sender: {
                  name: user.displayName,
                  email: user.email,
                },
                listUrlWithSlug: buildStartupListResultsUrl({
                  startupListPublicUUID: currentStartupList.public_uuid,
                  slug:
                    data?.insert_shared_project_links?.returning[0]
                      ?.target_group_slug || '',
                }),
              },
            })),
          },
        });
      }
    },
    [
      createSharedStartupList,
      currentStartupList.id,
      currentStartupList.public_uuid,
      sendMail,
      currentOrganization.from_name,
      currentOrganization.from_email,
      currentOrganization.id,
      user.email,
      user.displayName,
    ],
  );

  useEffect(() => {
    if (
      (shares.length === 0 ||
        !shares.find(
          s => s.target_group_slug === DEFAULT_LINK_TARGET_GROUP_SLUG,
        )) &&
      isNoViews
    ) {
      handleSharing({ slug: DEFAULT_LINK_TARGET_GROUP_SLUG });
    }
  }, [handleSharing, isNoViews, shares]);

  return {
    handleSharing,
  };
};
