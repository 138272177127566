import { QrCode2, RocketLaunchOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const IconContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const RocketIconWrapper = styled(Box)(({ theme: { spacing } }) => ({
  position: 'absolute',
  top: 'auto',
  bottom: spacing(0.5 * -1),
  right: spacing(1 * -1),
}));

const StartupPortalComposedIcon = () => {
  return (
    <IconContainer>
      <QrCode2 fontSize='medium' sx={{ marginTop: 0.5 }} />
      <RocketIconWrapper>
        <RocketLaunchOutlined sx={{ fontSize: '14px' }} />
      </RocketIconWrapper>
    </IconContainer>
  );
};

export default StartupPortalComposedIcon;
