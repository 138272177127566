import { Box, Card, CardContent, Skeleton } from '@mui/material';
import { KnownStartupsField } from 'components/dashboard/startupList/shared/KnownStartupsField';
import { AdditionalInformationField } from 'components/requestFormFields/AdditionalInformationField';
import { DesiredSolutionField } from 'components/requestFormFields/DesiredSolutionField';
import { FeaturesField } from 'components/requestFormFields/FeaturesField';
import { ProblemField } from 'components/requestFormFields/ProblemField';
import { useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { RenderFooterProps } from './SourcingOrderPage';
import { ConfirmRequestFormLeave } from './confirmRequestFormLeave';
import { SourcingOrderState } from './sourcingOrderModel';
import { UploadScopeFile } from './useScopeFiles';

type FormProps = {
  state: SourcingOrderState;
  renderFooter: (props: RenderFooterProps) => ReactNode;
  shownInModal?: boolean;
};

const cardStyle = { marginY: 1 };
const cardSkeletonStyle = {
  marginTop: 2,
  marginBottom: 1,
};

const fieldSkeletonHeight = 60;
const FieldSkeleton = () => (
  <Skeleton
    variant='rectangular'
    height={fieldSkeletonHeight}
    animation='wave'
  />
);

const CardSkeleton = ({ count }: { count: number }) => (
  <Card sx={cardSkeletonStyle}>
    <CardContent>
      {Array.from(new Array(count)).map((_, index) => (
        <FieldSkeleton key={index} />
      ))}
    </CardContent>
  </Card>
);

export const RequestFieldsSkeleton = () => (
  <>
    <CardSkeleton count={1} /> {/* ProblemField */}
    <CardSkeleton count={1} /> {/* DesiredSolutionField */}
    <CardSkeleton count={2} />
    {/* FeaturesField, must-have and nice-to-have features */}
    <CardSkeleton count={1} /> {/* KnownStartupsField */}
    <CardSkeleton count={1} /> {/* AdditionalInformationField */}
  </>
);

export default function RequestFormFields(props: FormProps) {
  const { state, renderFooter, shownInModal } = props;
  const { errors, touched, values, getFieldProps, setFieldValue } =
    useFormikContext<SourcingOrderState>();

  return (
    <>
      <ConfirmRequestFormLeave isFormSaved={!!state.public_uuid} />
      {shownInModal ? (
        <>
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <ProblemField
              {...{
                getFieldProps,
                setFieldValue,
                touched,
                errors,
              }}
            />
          </Box>

          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <DesiredSolutionField
              {...{
                getFieldProps,
                setFieldValue,
                touched,
                errors,
              }}
            />
          </Box>

          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <FeaturesField
              {...{
                getFieldProps,
                must_have_features: values.must_have_features,
                nice_to_have_features: values.nice_to_have_features,
                setFieldValue,
                touched,
                errors,
              }}
            />
          </Box>

          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <KnownStartupsField
              {...{
                touched,
                errors,
                getFieldProps,
                known_startups: values.known_startups,
                handleSave: ({ fieldName, fieldValue }) =>
                  setFieldValue(fieldName, fieldValue),
              }}
            />
          </Box>

          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <AdditionalInformationField
              {...{ getFieldProps, setFieldValue, touched, errors }}
            />
          </Box>
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <UploadScopeFile
              setFieldValue={setFieldValue}
              problemScopeId={state.problemScopeId}
            />
          </Box>
        </>
      ) : (
        <>
          <Card sx={{ marginTop: 2, marginBottom: 1 }}>
            <CardContent>
              <ProblemField
                {...{
                  getFieldProps,
                  setFieldValue,
                  touched,
                  errors,
                }}
              />
            </CardContent>
          </Card>
          <Card sx={cardStyle}>
            <CardContent>
              <DesiredSolutionField
                {...{
                  getFieldProps,
                  setFieldValue,
                  touched,
                  errors,
                }}
              />
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardContent>
              <FeaturesField
                {...{
                  getFieldProps,
                  must_have_features: values.must_have_features,
                  nice_to_have_features: values.nice_to_have_features,
                  setFieldValue,
                  touched,
                  errors,
                }}
              />
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardContent>
              <KnownStartupsField
                {...{
                  touched,
                  errors,
                  getFieldProps,
                  known_startups: values.known_startups,
                  handleSave: ({ fieldName, fieldValue }) =>
                    setFieldValue(fieldName, fieldValue),
                }}
              />
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 1, marginBottom: 2 }}>
            <CardContent>
              <AdditionalInformationField
                {...{ getFieldProps, setFieldValue, touched, errors }}
              />
            </CardContent>
          </Card>
          <Card sx={{ marginTop: 1, marginBottom: 2 }}>
            <CardContent>
              <UploadScopeFile
                setFieldValue={setFieldValue}
                problemScopeId={state.problemScopeId}
              />
            </CardContent>
          </Card>
        </>
      )}

      {renderFooter({
        errorMessage:
          Object.keys(errors).length > 0
            ? // @ts-expect-error: TODO Fix this
              errors[Object.keys(errors)[0]]
            : '',
      })}
    </>
  );
}
