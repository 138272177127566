export const auth0Config = (() => {
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;

  // TODO: Remove conditional test logic from code
  if (import.meta.env.TEST) {
    return { clientId: 'test', domain: 'test' };
  }

  if (!clientId)
    throw Error(
      `Expected $VITE_AUTH0_CLIENT_ID to be present, but got: ${clientId}`,
    );
  if (!domain)
    throw Error(
      `Expected $VITE_AUTH0_DOMAIN to be present, but got: ${domain}`,
    );

  return {
    clientId,
    domain,
    audience: 'https://gd-production.hasura.app/v1/graphql',
  };
})();

const API_ROOT = process.env.VITE_API_ROOT;
if (!API_ROOT) {
  throw Error('Expected $VITE_API_ROOT to be present');
}
export const API_GRAPHQL_ENDPOINT = `${API_ROOT}/v1/graphql`;

export const slsApiRoot = import.meta.env.VITE_SLS_API_ROOT;

const DUMMY_ORGANIZATIONS_TO_NOT_TRACK = ['corpbeta', 'corpgamma'];
const PROD_ORGANIZATIONS_TO_NOT_TRACK = ['demo', 'glassies'];
export const ORGANIZATIONS_TO_NOT_TRACK = [
  ...DUMMY_ORGANIZATIONS_TO_NOT_TRACK,
  ...PROD_ORGANIZATIONS_TO_NOT_TRACK,
];

export const GLASSDOLLAR_EMAIL_DOMAIN = '@glassdollar.com';
export const GLASSDOLLAR_DEFAULT_FROM_NAME = 'GlassDollar Team';
export const GLASSDOLLAR_DEFAULT_FROM_EMAIL = `notifications${GLASSDOLLAR_EMAIL_DOMAIN}`;
export const GLASSDOLLAR_DEFAULT_REPLY_TO = `support${GLASSDOLLAR_EMAIL_DOMAIN}`;

export const SOURCING_FOR_YOUR_SUPPLIER_RECOMMENDATION_TEMPLATE_ID =
  'd-c1fa78d6b8d54f9ea7907338fe974203';
export const REQUEST_FOR_OUTREACH_EMAIL_TEMPLATE_ID =
  'd-d3972bcad7cd401080019db180788e2e';
export const REGISTERED_SCOPE_TEMPLATE_ID =
  'd-b9093a94cf604ccf933dea5457f882d3';

export const GLASSDOLLAR_BETA_FEEDBACK_NOTIFICATION_EMAIL = import.meta.env
  .VITE_GLASSDOLLAR_BETA_FEEDBACK_NOTIFICATION_EMAIL!;

export const MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK = 2;
export const B_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK = 3;
export const VW_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK = 4;
export const BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE = 10;
export const MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH = 2;
export const VW_MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH = 6;
export const EXPRESS_SOURCING_DELIVERY_DAYS = 5;

export const PROD_ENV = import.meta.env.PROD;

export const GLASSDOLLAR_SUPPORT_EMAIL = `support${GLASSDOLLAR_EMAIL_DOMAIN}`;

export const MONTHLY_ENRICHMENTS_LIMIT = import.meta.env.PROD ? 50 : 15;
export const NUVO_LICENSE_KEY = import.meta.env.VITE_NUVO_LICENSE_KEY;
export const NGROK_URL = import.meta.env.VITE_NGROK_URL;

export const LOCAL_STORAGE_KEYS = {
  LAST_SCOUTED_STARTUPS: 'last_scouted_startups_v2',
  STARTUPS_SCOUTER_FILTERS: 'startups_scouter_filters_v2',
};

export const SEARCH_PARAMS = {
  page: 'page',
  sourcingsOnly: 'sourcings_only',
  tab: 'tab',
  sourcingOrderId: 'scouting_order_id',
  listId: 'list_id',
  team_id: 'team_id',
  user_uid: 'user_uid',
  startupId: 'startup_id',
  startupTab: 'startup_tab',
  organizationName: 'organization_name',
  pathname: 'pathname',
  searchQuery: 'search_query',
  portalTitle: 'portalTitle',
  invitation: 'invitation',
  returnTo: 'returnTo',
  connection: 'connection',
  fullName: 'full_name',
  filterStage: 'filter_stage',
  isTableView: 'is_table_view',
  leadMaturityLevel: 'lead_maturity_level',
} as const;

export const SEARCH_TYPES = {
  similarTo: 'similar-to',
};

export const X_HASURA_ORGANIZATION_UUID = 'x-hasura-organization-uuid';

// TODO: Can be an enum table
export const REACT_EMAIL_TYPES = {
  shareList: 'shareList',
  deliverSourcingOrder: 'deliverSourcingOrder',
};
