import { useCallback } from 'react';
import { CustomDimensionCellJSON } from '../../@types/shared';

import { useUpdateCategorizedStartupCustomDimensionsMutation } from 'apollo/generated/sdkInnovationManager';
import { useSnackbar } from 'notistack';

export const useHandleCustomCellUpdate = () => {
  const [updateCustomFeatures, { loading }] =
    useUpdateCategorizedStartupCustomDimensionsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleCustomCellUpdate = useCallback(
    async ({
      columnName,
      categorizedStartupId,
      cellState,
    }: {
      columnName: string;
      categorizedStartupId: number;
      cellState: CustomDimensionCellJSON;
    }) => {
      try {
        const updated = {
          [columnName]: {
            value:
              typeof cellState.value === 'string'
                ? cellState.value?.trim() || ''
                : cellState?.value,
            reference: cellState.reference?.trim() || '',
          },
        };

        await updateCustomFeatures({
          variables: {
            id: categorizedStartupId,
            upsertPayload: {
              ...updated,
            },
          },
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Error updating cell', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, updateCustomFeatures],
  );

  return {
    handleCustomCellUpdate,
    loading,
  };
};
