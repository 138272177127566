import { Avatar, Box, ListItem, ListItemAvatar } from '@mui/material';
import { List, Tooltip, Typography, styled } from '@mui/material';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import React from 'react';

export type Collaborator = {
  full_name: string;
  email: string;
  updated_at: string;
};

const avatarSize = 30;
const overlapMargin = -7.5; // Negative margin for overlapping effect in pixels
const MAX_VISIBLE_AVATARS = 4;

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  margin: 0,
  alignItems: 'center',
  '& .MuiListItem-root': {
    display: 'inline-flex',
    marginRight: 0,
    marginLeft: `${overlapMargin}px`,
    padding: 0,
  },
  '& .MuiListItem-root:first-of-type': {
    marginLeft: 0, // No negative margin for the first item
  },
});

const HiddenCollaboratorsTooltip = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Tooltip
    title={
      <>
        <Typography variant='caption' color='inherit'>
          {title}
        </Typography>
        {children}
      </>
    }
    placement='bottom-start'
  >
    <Avatar
      sx={{
        width: `${avatarSize}px`,
        height: `${avatarSize}px`,
        border: '2px solid white',
        margin: 0,
        backgroundColor: '#cccccc', // A neutral color for the 'more' avatar
        fontSize: '12px',
        color: 'white',
        cursor: 'pointer',
      }}
    >
      +{React.Children.count(children)}
    </Avatar>
  </Tooltip>
);

const CollaboratorsList = ({
  collaborators,
}: {
  collaborators?: Collaborator[];
}) => {
  const sortedCollaborators = (collaborators || []).sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );
  const additionalCollaborators =
    sortedCollaborators && sortedCollaborators.length > MAX_VISIBLE_AVATARS
      ? sortedCollaborators.slice(MAX_VISIBLE_AVATARS)
      : [];

  return (
    <StyledList>
      {(sortedCollaborators || [])
        .slice(0, MAX_VISIBLE_AVATARS)
        .map((collaborator, index) => (
          <Tooltip
            key={index}
            title={`Contributor: ${collaborator.full_name || collaborator.email}`}
            placement='top'
          >
            <ListItem>
              <ListItemAvatar sx={{ marginRight: 0 }}>
                <BaseInitialsAvatar
                  full_name={collaborator.full_name || ''}
                  sx={{
                    width: `${avatarSize}px`,
                    height: `${avatarSize}px`,
                    fontSize: '12px',
                    margin: 0,
                    border: '2px solid white',
                  }}
                />
              </ListItemAvatar>
            </ListItem>
          </Tooltip>
        ))}
      {additionalCollaborators.length > 0 && (
        <ListItem>
          <ListItemAvatar sx={{ marginRight: 0 }}>
            <HiddenCollaboratorsTooltip title='Also edited by'>
              {additionalCollaborators.map((collaborator, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}
                >
                  <BaseInitialsAvatar
                    full_name={collaborator.full_name || ''}
                    sx={{
                      width: 20,
                      height: 20,
                      fontSize: '12px',
                      marginRight: 0.5,
                    }}
                  />
                  <Typography variant='caption' sx={{ lineHeight: 1 }}>
                    {collaborator.full_name}
                  </Typography>
                </Box>
              ))}
            </HiddenCollaboratorsTooltip>
          </ListItemAvatar>
        </ListItem>
      )}
    </StyledList>
  );
};

export default CollaboratorsList;
