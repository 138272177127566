import { InfoOutlined, Link } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { DEFAULT_LINK_TARGET_GROUP_SLUG } from 'components/OrganizationPortal/shared/usePortalAnalytics';
import BaseLabel from 'components/base/BaseLabel';
import { parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { memo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { fDateTime } from 'utils/datetime';
import { buildStartupListResultsUrl } from 'utils/url';
import { SharedStartupList } from '../../../../../@types/shared';

type GetValueFn = (
  sharedStartupList: SharedStartupList,
  context: { startupListPublicUUID: string; captureCopyLinkEvent: () => void },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

const Identifier = (startupList: SharedStartupList) => {
  if (startupList.target_group_slug.startsWith('Email for ')) {
    return (
      <Typography variant='body2'>
        {startupList.target_group_slug.replace('Email for ', '')}
      </Typography>
    );
  }

  return (
    <Typography variant='body2'>
      {startupList.target_group_slug}
      {startupList.target_group_slug === DEFAULT_LINK_TARGET_GROUP_SLUG
        ? ' link'
        : ''}
    </Typography>
  );
};

const MemoizedIdentifier = memo(Identifier, () => true);

const COLUMNS: { name: string; tooltip?: string; getValue: GetValueFn }[] = [
  {
    name: 'Share identifier',
    getValue: MemoizedIdentifier,
  },
  {
    name: 'Status',
    getValue: sharedStartupList =>
      sharedStartupList.visits_count ? (
        <BaseLabel color='primary'>Seen</BaseLabel>
      ) : (
        <BaseLabel color='warning'>Unseen</BaseLabel>
      ),
  },
  {
    name: 'Visits',
    getValue: sharedStartupList => sharedStartupList.visits_count,
  },
  {
    name: 'Visitors',
    getValue: sharedStartupList => sharedStartupList.unique_visits_count,
  },
  {
    name: 'Last Visited',
    getValue: sharedStartupList =>
      sharedStartupList.last_visited_at
        ? fDateTime(sharedStartupList.last_visited_at)
        : '-',
  },
  {
    name: 'Link',
    getValue: (sharedStartupList, context) => {
      return (
        <SharedListsLinkCell
          captureCopyLinkEvent={context.captureCopyLinkEvent}
          url={buildStartupListResultsUrl({
            startupListPublicUUID: context.startupListPublicUUID,
            slug: sharedStartupList.target_group_slug,
          })}
        />
      );
    },
  },
];

const SharedListsTable = ({
  shares,
  startupListPublicUUID,
  captureCopyLinkEvent,
}: {
  shares: SharedStartupList[];
  startupListPublicUUID: string;
  captureCopyLinkEvent: () => void;
}) => {
  const sortedSharedStartupLists = orderBy(
    shares,
    [share => parseISO(share.created_at)],
    ['desc'],
  );

  return (
    <Box>
      <TableContainer sx={{ mt: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              {COLUMNS.map(c => (
                <TableCell
                  key={c.name}
                  sx={{ boxShadow: 'none !important', whiteSpace: 'nowrap' }}
                >
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span>{c.name}</span>
                    {c.tooltip && (
                      <Tooltip title={c.tooltip}>
                        <InfoOutlined
                          fontSize='small'
                          sx={{ marginLeft: 0.5 }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedSharedStartupLists.map((sharedList: SharedStartupList) => (
              <TableRow key={sharedList.id}>
                {COLUMNS.map(c => (
                  <TableCell key={`${sharedList.id}-${c.name}`}>
                    {c.name === 'Share identifier' ? (
                      <MemoizedIdentifier {...sharedList} />
                    ) : (
                      c.getValue(sharedList, {
                        startupListPublicUUID,
                        captureCopyLinkEvent,
                      })
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const SharedListsLinkCell = ({
  url,
  captureCopyLinkEvent,
}: {
  url: string;
  captureCopyLinkEvent: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CopyToClipboard
      text={url}
      onCopy={() => {
        captureCopyLinkEvent();
        enqueueSnackbar('Link copied', { variant: 'success' });
      }}
    >
      <Tooltip title='Copy'>
        <IconButton>
          <Link />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
};

export { SharedListsTable };
