import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  GetProjectCategoriesForKanbanQuery,
  useGetProjectCategoriesForKanbanLazyQuery,
  useGetProjectCategoriesForKanbanQuery,
} from 'apollo/generated/sdkInnovationManager';
import { flatMap } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const parseRelatedStartups = ({
  data,
  enqueueSnackbar,
}: {
  data: GetProjectCategoriesForKanbanQuery | undefined;
  enqueueSnackbar: ReturnType<typeof useSnackbar>['enqueueSnackbar'];
}) => {
  const fallback = {
    relatedCategorizedStartups: [],
    relatedOrganizationStartups: [],
    selectedStartupForPoC: null,
    shortlistedStartups: [],
  };

  if (!data) return fallback;

  try {
    const relatedCategorizedStartups = flatMap(
      data.project_categories,
      listCategory => listCategory.categorized_suppliers,
    );

    const relatedOrganizationStartups =
      relatedCategorizedStartups.map(cs => cs.startup) || [];

    const shortlistedStartups = relatedCategorizedStartups
      .filter(catStartup => catStartup.is_shortlisted)
      .map(catStartup => catStartup.startup)
      .map(organizationStartup => ({
        id: organizationStartup?.id || 0,
        name: organizationStartup?.name || '',
        domain: organizationStartup?.domain || '',
        logo_url: organizationStartup?.logo_url || '',
      }));

    const selectedStartupForPoC =
      relatedCategorizedStartups.find(catStartup => catStartup.is_selected)
        ?.startup || null;

    return {
      relatedCategorizedStartups,
      relatedOrganizationStartups,
      selectedStartupForPoC,
      shortlistedStartups,
    };
  } catch (error) {
    enqueueSnackbar('Error loading related startups', { variant: 'error' });
    return fallback;
  }
};

export const useGetRelatedStartups = (
  projectId: number,
  fetchPolicy?: WatchQueryFetchPolicy,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useGetProjectCategoriesForKanbanQuery({
    variables: { projectId },
    ...(fetchPolicy && { fetchPolicy }),
  });

  return {
    ...parseRelatedStartups({ data, enqueueSnackbar }),
    loading,
    error,
  };
};

export const useGetRelatedStartupsLazy = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [getCategories] = useGetProjectCategoriesForKanbanLazyQuery();

  return useCallback(
    async (projectId: number) => {
      const { data, loading, error } = await getCategories({
        variables: { projectId },
      });

      return {
        ...parseRelatedStartups({ data, enqueueSnackbar }),
        loading,
        error,
      };
    },
    [enqueueSnackbar, getCategories],
  );
};
