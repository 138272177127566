import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/react';
import { useEnrichSupplierMutation } from 'apollo/generated/sdkShared';
import { FormLabelWithTooltip } from 'components/requestFormFields/ProblemField';
import { ErrorMessage, Form, FormikProvider, useFormik } from 'formik';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { FieldProps } from 'pages/request-form/types';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { KnownStartup } from '../../../../@types/startupList';
import { FALLBACK_SUPPLIER_LOGO_URL, LogoAvatar } from 'components/AvatarGroup';

const SUPPLIER_SCHEMA: Yup.Schema<KnownStartup> = Yup.object({
  domain: Yup.string().required('Domain is required'),
  name: Yup.string().required('Name is required'),
  logo: Yup.string().nullable(),
  product: Yup.string(),
  excludeWhenBenchmarking: Yup.boolean().required(),
});

export const SupplierIcon = styled('div')({
  height: '52px',
  width: '52px',
  borderRadius: '50%',
  border: '1px solid #919EAB3D',
});

const AUTOCOMPLETE_STYLES = {
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: '20px',
  // flexBasis: '100%',

  '& input': {
    display: 'none',
  },

  '& .MuiInput-root::before': {
    border: 'none !important',
  },
};

type AddSuppliersFieldSchemaValue = Pick<SourcingOrderState, 'known_startups'>;

type AddStartupsProps = Omit<
  FieldProps<Pick<AddSuppliersFieldSchemaValue, 'known_startups'>> & {
    known_startups: KnownStartup[];
    handleSave: ({
      fieldName,
      fieldValue,
    }: {
      fieldName: 'known_startups';
      fieldValue: KnownStartup[];
    }) => void;
  },
  'setFieldValue'
>;

type KnownStartupsProps = Omit<
  FieldProps<StartupsFieldFieldSchemaValue> &
    StartupsFieldFieldSchemaValue & {
      state?: SourcingOrderState;
      cardClassName?: string;
      titleSx?: SxProps;
      handleSave: ({
        fieldName,
        fieldValue,
      }: {
        fieldName: 'known_startups';
        fieldValue: KnownStartup[];
      }) => void;
    },
  'setFieldValue'
>;

function AddStartups({
  known_startups = [],
  errors,
  getFieldProps,
  handleSave,
}: AddStartupsProps) {
  const [isSuppliersPopup, setSuppliersPopup] = useState(false);
  const showSuppliersPopup = useCallback(() => setSuppliersPopup(true), []);
  const hideSuppliersPopup = useCallback(() => setSuppliersPopup(false), []);

  const addSupplier = (supplier: KnownStartup) =>
    handleSave({
      fieldName: 'known_startups',
      fieldValue: [...(known_startups || []), supplier],
    });

  return (
    <Stack direction='row'>
      <Box>
        <Button
          variant='contained'
          onClick={showSuppliersPopup}
          sx={{
            height: '35px',
            width: '9rem',
          }}
        >
          + Add a solution
        </Button>
      </Box>
      {known_startups.length > 0 && (
        <Autocomplete
          multiple
          clearIcon={null}
          sx={AUTOCOMPLETE_STYLES}
          freeSolo
          options={known_startups}
          getOptionLabel={option => option.domain}
          {...getFieldProps('known_startups')}
          onChange={(_e, value) =>
            handleSave({
              fieldName: 'known_startups',
              fieldValue: value,
            })
          }
          renderInput={params => <TextField {...params} variant='standard' />}
          renderTags={(value, getTagProps) => (
            <StartupChips knownStartups={value} getTagProps={getTagProps} />
          )}
        />
      )}
      {errors.known_startups && (
        <FormHelperText error={true}>
          <ErrorMessage name='known_startups' />
        </FormHelperText>
      )}
      {isSuppliersPopup && (
        <StartupsPopup onHide={hideSuppliersPopup} onSetValues={addSupplier} />
      )}
    </Stack>
  );
}

type GetTagProps = ({ index }: { index: number }) => {
  key: number;
  className: string;
  disabled: boolean;
  'data-tag-index': number;
  tabIndex: -1;
  onDelete: (event: React.MouseEvent) => void;
};

const StartupChips = ({
  knownStartups,
  getTagProps,
}: {
  knownStartups: KnownStartup[];
  getTagProps: GetTagProps;
}) => {
  return (
    <Stack flexWrap='wrap' direction='row'>
      {knownStartups.map((option, index: number) => (
        <Chip
          {...getTagProps({ index })}
          key={option.domain}
          label={
            <>
              <Stack
                alignItems='center'
                direction='row'
                gap={0.5}
                key={option.domain}
              >
                <LogoAvatar
                  src={option.logo || FALLBACK_SUPPLIER_LOGO_URL}
                  size={20}
                />
                <Typography variant='caption'>{option.name}</Typography>
              </Stack>
            </>
          }
        />
      ))}
    </Stack>
  );
};

type SuppliersPopupProps = {
  onHide: () => void;
  onSetValues: (supplier: KnownStartup) => void;
};

type StartupsFieldFieldSchemaValue = Pick<SourcingOrderState, 'known_startups'>;

export function KnownStartupsField({
  touched,
  errors,
  getFieldProps,
  known_startups,
  handleSave,
}: KnownStartupsProps) {
  const { isSharedPage } = useSharedPagesContext();

  return (
    <>
      <FormLabelWithTooltip
        label='Known solutions'
        tooltipText={
          isSharedPage
            ? `Are you already using a provider in this area from which you'd like
          to switch or are you simply aware of some providers that offer a
          suitable solution? Please add the solutions that you know of.`
            : `Are your stakeholders already using a provider in this area from
          which they'd like to switch or are they simply aware of some providers
          that offer a suitable solution? Please add the solutions that you know
          of.`
        }
      />
      <Box sx={{ marginTop: 2 }}>
        <AddStartups
          known_startups={known_startups}
          touched={touched}
          errors={errors}
          getFieldProps={getFieldProps}
          handleSave={handleSave}
        />
      </Box>
    </>
  );
}

const ENRICHMENT_FAILED_MESSAGE = 'Failed to enrich supplier';

function StartupsPopup(props: SuppliersPopupProps) {
  const { onSetValues, onHide: originalOnHide } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [enrichSupplier, { loading: enriching, called: enrichCalled }] =
    useEnrichSupplierMutation();

  const formik = useFormik<KnownStartup>({
    initialValues: {
      domain: '',
      name: '',
      logo: '',
      employees_count: null,
      hq: '',
      short_description: '',
      long_description: '',
      linkedin_url: '',
      founded: null,
      website: '',
      crunchbase_url: '',
      funding: null,
      excludeWhenBenchmarking: false,
    },
    validationSchema: SUPPLIER_SCHEMA,
    onSubmit: async values => {
      onSetValues(values);
      originalOnHide();
    },
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  // Cannot pass inline function here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEnrichment = useCallback(
    debounce((domain: string) => {
      // sanatize url
      let sanatizedDomain = domain;
      try {
        sanatizedDomain = new URL(sanatizedDomain).hostname.replace('www.', '');
      } catch (e) {
        // No need to send sentry error here
        sanatizedDomain = sanatizedDomain.replace(/(www\.|https?:\/\/)/, '');
        sanatizedDomain = sanatizedDomain.split('/')[0];
      }
      if (sanatizedDomain !== domain) {
        setFieldValue('domain', sanatizedDomain); // this will trigger the function again
      } else {
        const snackbarKey = enqueueSnackbar(
          `Enrichment started for: ${domain}`,
          {
            variant: 'info',
          },
        );

        enrichSupplier({ variables: { domain } })
          .then(({ data: responseData }) => {
            const { DEPRECATED_enrich_supplier } = responseData!;
            closeSnackbar(snackbarKey);

            if (!DEPRECATED_enrich_supplier.success) {
              enqueueSnackbar(ENRICHMENT_FAILED_MESSAGE, { variant: 'error' });
              return;
            }

            const fieldsToUpdate = [
              'logo',
              'name',
              'employees_count',
              'hq',
              'short_description',
              'long_description',
              'linkedin_url',
              'founded',
              'website',
              'crunchbase_url',
              'funding',
            ];

            fieldsToUpdate.forEach(field => {
              setFieldValue(field, DEPRECATED_enrich_supplier.data[field]);
            });
            enqueueSnackbar('Enrichment successful', { variant: 'success' });
          })
          .catch(e => {
            captureException(e);
            enqueueSnackbar(ENRICHMENT_FAILED_MESSAGE, { variant: 'error' });
          });
      }
    }, 1500),
    [enqueueSnackbar, closeSnackbar, setFieldValue, enrichSupplier],
  );

  useEffect(() => {
    if (values.domain.trim() === '' || errors.domain) {
      return;
    }

    handleEnrichment(values.domain);
  }, [values.domain, errors.domain, handleEnrichment]);

  return (
    <Dialog open={true} fullWidth onClose={originalOnHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add known solution(s)</DialogTitle>
          <DialogContent sx={{ marginTop: '25px', overflowY: 'hidden' }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: '5px' }}>
              <TextField
                autoFocus
                {...getFieldProps('domain')}
                error={Boolean(touched.domain && errors.domain)}
                helperText={touched.domain && errors.domain}
                fullWidth
                label='Please paste the website to ensure we review the right solution.'
              />
              {enrichCalled && !enriching && (
                <>
                  <Stack direction='row' spacing={1}>
                    <Box>
                      <SupplierIcon
                        style={{
                          background: `center / contain no-repeat url("${
                            values.logo as string
                          }")`,
                        }}
                      />
                    </Box>
                    <TextField
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      disabled={enriching}
                      fullWidth
                      label='Enter the name of the solution you know'
                    />
                  </Stack>
                  <FormControlLabel
                    label='Exclude this provider when benchmarking solutions'
                    disabled={enriching}
                    control={
                      <Checkbox {...getFieldProps('excludeWhenBenchmarking')} />
                    }
                  />
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={originalOnHide}>
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enriching}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
