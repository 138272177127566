import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  EnumTablePriorityEnum,
  EnumTableStakeholderMaturityEnum,
  EnumTableStakeholderTypeEnum,
} from 'apollo/generated/sdkInnovationManager';
import { useAddPersonModalGetPeopleQuery } from 'apollo/generated/sdkShared';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Person, PersonForInsert } from '../../../@types/stakeholder';
import {
  maturityValueOptions,
  priorityValueOptions,
  stakeholderTypeValueOptions,
} from './useGetColumns';

type AddPersonModalProps = {
  open: boolean;
  onHide: () => void;
  onSetValues: (
    f: PersonForInsert,
  ) => Promise<{ stakeholderId: number | null }>;
  setPersonValue?: (value: Person) => void;
  setFieldValue?: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
};

export const AddPersonModal = (props: AddPersonModalProps) => {
  const { open, onHide, onSetValues, setPersonValue, setFieldValue } = props;
  const { data } = useAddPersonModalGetPeopleQuery();
  const people = useMemo(() => data?.people || [], [data]);

  const peopleForAutocomplete = useMemo(
    () =>
      people.map(s => ({
        id: s.id,
        full_name: s.full_name,
        email: s.email,
        department: s.department || '',
      })) || [],
    [people],
  );

  const STAKEHOLDER_VALIDATION_SCHEMA = useMemo(
    () =>
      z.object({
        full_name: z.string({
          required_error: 'Full name is required',
        }),
        department: z
          .string({
            required_error: 'Department is required',
          })
          .optional(),
        email: z
          .string({
            required_error: 'Company email is required',
          })
          .email()
          .refine(
            email =>
              !peopleForAutocomplete
                .map(s => s.email.toLowerCase())
                .includes(email.toLowerCase()),
            {
              message: 'A person with that email already exists',
            },
          ),
        priority: z
          .enum(['high', 'medium', 'low'] as EnumTablePriorityEnum[] as [
            string,
            ...string[],
          ])
          .optional(),
        stakeholder_type: z
          .enum([
            'innovation_manager',
            'pain_point_owner',
            'supporting_function',
            'venture_partner',
          ] as EnumTableStakeholderTypeEnum[] as [string, ...string[]])
          .optional(),
        maturity: z
          .enum([
            'pocs_completed',
            'projects_started',
            'requests_submitted',
            'no_interactions',
          ] as EnumTableStakeholderMaturityEnum[] as [string, ...string[]])
          .optional(),
      }),
    [peopleForAutocomplete],
  );

  const formik = useFormik<PersonForInsert>({
    initialValues: {
      full_name: '',
      email: '',
      department: '',
    },
    validationSchema: toFormikValidationSchema(STAKEHOLDER_VALIDATION_SCHEMA),
    onSubmit: async (values, { resetForm }) => {
      // We need to omit the maturity value if it's "no_interaction_yet"
      // The option is left there if a user accidentally selects another option which does not apply to the stakeholder
      const omitNoInteraction = {
        ...values,
        maturity:
          // @ts-expect-error: TODO: FIXME!
          values.maturity === 'no_interaction_yet' ? null : values.maturity,
        email: values.email.toLowerCase(),
      };

      const { stakeholderId } = await onSetValues(omitNoInteraction);

      if (setPersonValue && setFieldValue) {
        setPersonValue({ ...omitNoInteraction, id: stakeholderId! });
        setFieldValue('stakeholder_id', stakeholderId);
      }

      captureAnalyticsEvent('Stakeholder added from stakeholders page', {
        stakeholderName: values.full_name,
        stakeholderEmail: values.email,
        stakeholderMaturity: values.maturity || '',
        stakeholderPriority: values.priority || '',
        stakeholderType: values.stakeholder_type || '',
        stakeholderId: stakeholderId,
      });

      resetForm();
      onHide();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, submitCount } = formik;
  const hasSubmitted = submitCount > 0;

  return (
    <Dialog
      open={open}
      fullWidth
      data-testid='add-person-modal'
      onClose={onHide}
    >
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add a Person</DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <TextField
                {...getFieldProps('full_name')}
                error={Boolean(touched.full_name && errors.full_name)}
                helperText={touched.full_name && errors.full_name}
                fullWidth
                label='Name *'
                data-testid='add-person-modal__name'
              />
              <TextField
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                label='Company email *'
                data-testid='add-person-modal__email'
              />
              <TextField
                {...getFieldProps('department')}
                error={Boolean(touched.department && errors.department)}
                helperText={touched.department && errors.department}
                fullWidth
                label='Department'
                data-testid='add-person-modal__department'
              />
              <TextField
                {...getFieldProps('priority')}
                select
                error={Boolean(hasSubmitted && errors.priority)}
                helperText={hasSubmitted && errors.priority}
                fullWidth
                label='Priority'
                data-testid='add-person-modal__priority'
              >
                {priorityValueOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    <Typography variant='body2' color='text.secondary'>
                      {label}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                {...getFieldProps('stakeholder_type')}
                select
                error={Boolean(hasSubmitted && errors.stakeholder_type)}
                helperText={hasSubmitted && errors.stakeholder_type}
                fullWidth
                label='User type'
                data-testid='add-person-modal__user-type'
              >
                {stakeholderTypeValueOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    <Typography variant='body2' color='text.secondary'>
                      {label}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                {...getFieldProps('maturity')}
                select
                error={Boolean(hasSubmitted && errors.maturity)}
                helperText={hasSubmitted && errors.maturity}
                fullWidth
                label='Maturity'
                data-testid='add-person-modal__maturity'
              >
                {maturityValueOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    <Typography variant='body2' color='text.secondary'>
                      {label}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              <Typography>* Required fields</Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              data-testid='add-person-modal__save'
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
