import { Close, CopyAllOutlined, Download } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GLASSDOLLAR_SUPPORT_EMAIL } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import domToImage from 'dom-to-image';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { PATH_ROOT } from 'routes/paths';
import StartupPortalComposedIcon from './StartupPortalComposedIcon';

const QRCodeStartupPitchFormButton = ({ isMobile }: { isMobile: boolean }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title='Pitch form'>
        <span>
          <IconButton
            size='small'
            onClick={handleOpen}
            sx={({ spacing }) => ({
              width: spacing(6),
              height: spacing(6),
            })}
          >
            <StartupPortalComposedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <StartupPortalSharingDialog
        open={open}
        isMobile={isMobile}
        onClose={handleClose}
      />
    </>
  );
};

const StartupPortalSharingDialog = ({
  open,
  onClose,
  isMobile,
}: {
  open: boolean;
  onClose: () => void;
  isMobile: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const qrWrapperRef = useRef<HTMLDivElement | null>(null);

  const currentOrganization = useCurrentOrganizationFromContext();
  const startupFormURL = PATH_ROOT.startupPitchFormLink(
    currentOrganization.uuid,
  );

  const downloadQRCode = async () => {
    if (!qrWrapperRef.current) {
      console.log('Failed to download QR code');
      enqueueSnackbar('Failed to download QR code', {
        variant: 'error',
      });
      return;
    }

    const dataUrl = await domToImage.toPng(qrWrapperRef.current);

    const link = document.createElement('a');
    link.download = 'startup-portal-qr-code.png';
    link.href = dataUrl;
    link.click();
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          marginBottom={4}
        >
          <Typography variant='h5'>
            Let startups pitch directly to your inbox
          </Typography>
          <IconButton size='small' onClick={onClose}>
            <Close fontSize='small' />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4} justifyContent='center'>
          <Card
            sx={{
              marginX: isMobile ? 5 : 10,
              maxWidth: 480,
            }}
          >
            <CardContent ref={qrWrapperRef}>
              <QRCode
                size={8 * 32}
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  width: '100%',
                  fontFamily: 'Arial, sans-serif',
                }}
                value={startupFormURL}
                viewBox={`0 0 256 256`}
              />
            </CardContent>
          </Card>
          <Stack direction='row' justifyContent='center'>
            <Button
              color='inherit'
              onClick={downloadQRCode}
              sx={{
                maxWidth: 160,
              }}
            >
              <Download fontSize='small' sx={{ marginRight: 0.5 }} />
              Save QR code
            </Button>
          </Stack>
          <Typography marginX={2} textAlign='center'>
            <Typography color='text.secondary'>
              Get first-party data from companies by sharing your Pitch form{' '}
            </Typography>
            <Typography color={theme => theme.palette.primary.main}>
              <Typography component='span'>startups.glassdollar.com</Typography>
              <CopyToClipboard
                text={startupFormURL}
                onCopy={() => {
                  enqueueSnackbar('Link copied', {
                    variant: 'success',
                  });
                }}
              >
                <IconButton
                  size='small'
                  sx={{ display: 'inline-block' }}
                  color='primary'
                >
                  <CopyAllOutlined fontSize='small' />
                </IconButton>
              </CopyToClipboard>
            </Typography>
          </Typography>

          {!isMobile && (
            <Typography variant='body2' marginX={2} textAlign='center'>
              Want to use fully private-labeled version? Contact us at{' '}
              <Link
                color={theme => theme.palette.text.primary}
                href={`mailto:${GLASSDOLLAR_SUPPORT_EMAIL}`}
              >
                {GLASSDOLLAR_SUPPORT_EMAIL}
              </Link>
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { QRCodeStartupPitchFormButton };
